import {
  Box,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
  Button,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFoodMenuPlan } from '../../../../customHooks/useMenuPlan';
import { labelStyles, selectStyles } from '../../styles/styles';
import { UploadFile, Edit } from '@mui/icons-material';

function PlanHeader({
  planPriceFinal,
  planName,
  setPlanName,
  planCategory,
  setPlanCategory,
  planDescription,
  setPlanDescription,
  planPrice,
  setPlanPrice,
}) {
  const {
    planCategories,
    setNewMenuPlanObj,
    newMenuPlanObj,
    initialStateNewMenuPlanObj,
    getPlanCategories,
    setPlanPriceFinal,
    imageFile,
    handleFileChange,
  } = useFoodMenuPlan();

  useEffect(() => {
    getPlanCategories();
  }, []);

  const handleChangePlanName = (event) => {
    var name = event.target.value;
    setPlanName((prevName) => name);
    setNewMenuPlanObj((prev) => ({ ...prev, name: name }));
  };

  const handleChangePlanCategory = (event) => {
    var catId = event.target.value;
    setPlanCategory(catId);
    setNewMenuPlanObj((prev) => ({ ...prev, plan_category_id: Number(catId) }));
  };
  const handleChangePlanPrice = (event) => {
    var price = event.target.value;
    setPlanPrice(price);
    setPlanPriceFinal(Number(price));
    setNewMenuPlanObj((prev) => ({ ...prev, plan_price: Number(price) }));
  };
  const handleOnChangeDescription = (event) => {
    const description = event.target.value;
    setPlanDescription((prev) => ({
      ...prev,
      description: description, // Ensure you update the `description` field in the object
    }));
    setNewMenuPlanObj((prev) => ({
      ...prev,
      description: description, // Ensure you update the `description` field in the object
    }));
  };

  const StyledTextarea = styled('textarea')(({ theme }) => ({
    ...BootstrapInput,
    width: '100%', // Adjust as needed
    resize: 'none', // Prevent resizing if desired
  }));

  useEffect(() => {
    return () => {};
  }, [planCategory]);

  const BootstrapInput = {
    'label + &': {
      marginTop: '10px',
    },
    '& .MuiInputBase-input': {
      position: 'relative',
      backgroundColor: '#fff',
      fontSize: 16,
      minWidth: '250px',
      padding: '15px',
      height: '46px',
      boxSizing: 'border-box',
      borderRadius: '10px',
      border: '1px solid #B0B0B0',
      background: '#FFF',

      '&:focus': {},
    },
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        mb: 2,
        flexWrap: 'wrap',
        rowGap: '10px',
        width: '100%',
        gap: '15px',
      }}
    >
      <Box>
        <InputLabel sx={labelStyles} htmlFor="planName">
          Plan Name
        </InputLabel>

        <TextField
          id="planName"
          placeholder={'Enter Plan Name'}
          name="planName"
          onChange={handleChangePlanName}
          value={planName}
          sx={BootstrapInput}
          variant="outlined"
          InputProps={{
            disableUnderline: true,
          }}
        />
      </Box>
      <Box>
        <InputLabel sx={labelStyles}>Plan Category</InputLabel>
        <Select
          id="planCategory"
          name="planCategory"
          value={planCategory}
          onChange={handleChangePlanCategory}
          sx={{ ...selectStyles, minWidth: '250px' }}
          variant="standard"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 248,
                width: '250px',
                minWidth: '250px',
              },
            },
          }}
        >
          {planCategories.length &&
            planCategories.map((category, index) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
        </Select>
      </Box>
      <Box>
        <InputLabel sx={labelStyles} htmlFor="planPrice">
          Description
        </InputLabel>
        <TextField
          id="planName"
          placeholder="Enter description"
          name="planDescription"
          onChange={handleOnChangeDescription}
          value={planDescription.description || ''} // Use the description field
          sx={BootstrapInput}
          variant="outlined"
          InputProps={{
            disableUnderline: true,
          }}
        />
      </Box>

      <Box>
        <InputLabel sx={labelStyles} htmlFor="planPrice">
          Final Price
        </InputLabel>
        <TextField
          id="planPrice"
          placeholder={'$000'}
          name="planPrice"
          disabled={newMenuPlanObj?.monthly_plan.length === 0}
          onChange={handleChangePlanPrice}
          value={planPrice}
          sx={BootstrapInput}
          variant="outlined"
          type="number"
          InputProps={{
            disableUnderline: true,
          }}
        />
      </Box>
      <Box
        sx={{
          paddingTop: '36px',
          marginRight: '14px',
        }}
      >
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="image-upload"
          type="file"
          onChange={(e) => handleFileChange(e)}
        />

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="filled"
            sx={{
              width: '30px',
              minWidth: '30px',
              height: '33px',
              borderRadius: '20px',
              color: 'rgba(251, 243, 246, 1)',
            }}
          >
            <label
              htmlFor="image-upload"
              style={{ height: '101%', cursor: 'pointer' }}
            >
              {imageFile === '' ? <UploadFile /> : <Edit />}
            </label>
          </Button>

          <Typography variant="body2" sx={{ ml: 1 }}>
            Image
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          borderRadius: '12px',
          border: '1px solid var(--Old-colors-Line, #F5F6F8)',
          background:
            'var(--Old-colors-Glass-Fill, linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.45) 100%))',
          boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.10)',
          backdropFilter: 'blur(20px)',

          display: 'inline-flex',
          padding: '16px 20px 14px 20px',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '8px',
          marginLeft: 'auto',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Inter',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
          }}
        >
          Estimated Plan Price
        </Typography>
        <Typography
          sx={{
            color: '#EF6951',
            fontFamily: 'Outfit',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
          }}
        >
          ${Number(planPriceFinal).toFixed(2)}
        </Typography>
      </Box>
    </Box>
  );
}

export default PlanHeader;
