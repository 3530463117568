import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CustomizationLandingPageLayout from '../serviceLandingPage/pages/CustomizationLandingPage/components/CustomizationLandingPageLayout';
import './style.css';
import { ReactComponent as HouseIcon } from './asstes/House.svg';
import { ReactComponent as ArrowRightIcon } from './asstes/arrowright.svg';
import { ReactComponent as CreditIcon } from './asstes/credit.svg';
import Checkbox from '@mui/material/Checkbox';
import Tiffin_Img from './asstes/tiffin.jpg';
import axios from 'axios';
import { TENANT_URL } from '../../../config';

import { ServiceFooter } from '../serviceLandingPage/servicefooter';
import { ServiceNavbar } from '../serviceLandingPage/serviceNarbar';

const inpuStyle = {
  width: '100%',

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#EBEBEC',
  },
  '& .MuiOutlinedInput-root': {
    borderColor: '#EBEBEC',
    fontFamily: 'Inter !important',
    fontSize: '14px',
  },
  '& .MuiOutlinedInput-input': {
    height: '1em',
  },
};

const selectStyle = {
  width: '100%',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#EBEBEC',
  },
  '& .MuiSelect-select': {
    padding: '13px 14px',
    fontSize: '14px',
  },
};

const Cart = () => {
  const [cartID, setCartID] = useState('');
  const [cartDetails, setCartDetails] = useState({});
  const [cartTaxesDetails, setCartTaxesDetails] = useState([]);

  const [additinaolItems, setAdditinaolItems] = useState('');
  const [additinaolItemsTotals, setAdditinaolItemsTotals] = useState(0);

  const [deliveryMethod, setDeliveryMethod] = useState('');

  const navigate = useNavigate();

  const getCartID = () => {
    let cartID = localStorage.getItem('cartID');
    if (!!cartID) {
      setCartID(cartID);
      setDeliveryMethod(localStorage.getItem('deliveryMethod'));
    } else {
      navigate('/');
    }
  };
  const getCartDetails = async () => {
    console.log('calling');
    try {
      // Fetch meal plan details
      const config = {
        method: 'get',
        url: `${TENANT_URL}/api/cart?cart_id=${cartID}`,
      };
      const response = await axios(config);
      console.log(response.data.cart.items.length);
      if (response.data && response.data.cart.items.length > 0) {
        setCartDetails(response.data);
        getCartTaxes();

        setAdditinaolItems(localStorage.getItem('additinoalitems') || '');
        setAdditinaolItemsTotals(
          localStorage.getItem('additinoalitemTotal') || 0
        );
      } else {
        navigate('/');
      }
    } catch (error) {
      setError('Failed to load meal plan details.');
    }
  };

  const placeOrder = () => {
    navigate('/checkout');
  };

  const getCartTaxes = async () => {
    try {
      const config = {
        method: 'get',
        url: `${TENANT_URL}/api/cart/taxes?cart_id=${cartID}&preferred_delivery_method=${deliveryMethod}`,
      };
      const response = await axios(config);
      setCartTaxesDetails(response.data.taxes);
    } catch (error) {
      setError('Failed to load meal plan details.');
    }
  };

  useEffect(() => {
    document.body.classList.add('customPage');
    return () => {
      document.body.classList.remove('customPage');
    };
  }, []);
  useEffect(() => {
    getCartID();
  }, []);

  useEffect(() => {
    getCartDetails();
  }, [cartID, deliveryMethod]);

  const paymentMethods = [
    {
      name: 'Visa',
      icon: (
        <svg
          viewBox="0 0 24 24"
          width="24"
          height="24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="24" height="24" rx="4" fill="#1A1F71" />
          <path
            d="M10.5 15.5H8.5L6.8 8.7C6.74495 8.46724 6.62831 8.25465 6.46154 8.08534C6.29477 7.91603 6.08384 7.79743 5.85 7.74V7.5H8.85C9.225 7.5 9.525 7.775 9.575 8.15L10.425 12.825L12.175 7.5H14.125L11.325 15.5H10.5Z"
            fill="white"
          />
          <path
            d="M14.575 15.5H12.675L14.125 7.5H16.025L14.575 15.5Z"
            fill="white"
          />
          <path
            d="M18.925 7.675C18.575 7.525 17.975 7.375 17.225 7.375C15.475 7.375 14.225 8.325 14.225 9.625C14.225 10.625 15.125 11.175 15.825 11.5C16.525 11.825 16.775 12.05 16.775 12.35C16.775 12.8 16.225 13.025 15.725 13.025C15.025 13.025 14.275 12.825 13.675 12.475L13.375 12.325L13.075 14.125C13.475 14.325 14.275 14.5 15.125 14.525C16.975 14.525 18.2 13.6 18.225 12.2C18.225 11.4 17.725 10.8 16.675 10.325C16.025 10 15.625 9.775 15.625 9.425C15.625 9.125 15.925 8.8 16.575 8.8C17.125 8.775 17.575 8.925 17.925 9.075L18.125 9.175L18.425 7.425L18.925 7.675Z"
            fill="white"
          />
        </svg>
      ),
    },
    {
      name: 'Mastercard',
      icon: (
        <svg
          viewBox="0 0 24 24"
          width="24"
          height="24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="24" height="24" rx="4" fill="white" />
          <circle cx="9" cy="12" r="5" fill="#EB001B" />
          <circle cx="15" cy="12" r="5" fill="#F79E1B" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 15.98C13.3889 14.8654 14.25 13.0847 14.25 11.1C14.25 9.11533 13.3889 7.33456 12 6.22C10.6111 7.33456 9.75 9.11533 9.75 11.1C9.75 13.0847 10.6111 14.8654 12 15.98Z"
            fill="#FF5F00"
          />
        </svg>
      ),
    },
    {
      name: 'Apple Pay',
      icon: (
        <svg
          viewBox="0 0 24 24"
          width="24"
          height="24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="24" height="24" rx="4" fill="black" />
          <g transform="translate(4, 4)">
            <path
              d="M11.7746 3.2146C12.4043 2.45826 12.8455 1.41 12.7478 0.37C11.8408 0.41 10.7363 0.96 10.0823 1.72C9.49023 2.4 8.95898 3.46 9.05664 4.48C10.0578 4.57 11.145 3.97 11.7746 3.2146ZM12.7234 4.62C11.2442 4.62 10.0578 5.5 9.32813 5.5C8.57422 5.5 7.51953 4.67 6.31836 4.67C4.74609 4.67 3.07422 5.85 3.07422 8.25C3.07422 9.91 3.65625 11.64 4.40039 12.77C5.02734 13.73 5.55859 14.5 6.36914 14.5C7.15527 14.5 7.47070 13.97 8.42578 13.97C9.40625 13.97 9.64648 14.5 10.4814 14.5C11.293 14.5 11.8506 13.66 12.4531 12.71C13.1072 11.64 13.3962 10.57 13.4209 10.5C13.3962 10.48 11.5576 9.77 11.5576 7.74C11.5576 5.98 12.918 5.15 13.0156 5.08C12.0352 3.67 10.5068 3.61 10.0823 3.61C8.84961 3.61 7.81934 4.35 7.20215 4.35C6.63965 4.35 5.70898 3.67 4.57422 3.67"
              fill="white"
            />
          </g>
        </svg>
      ),
    },
    {
      name: 'Google Pay',
      icon: (
        <svg
          viewBox="0 0 24 24"
          width="24"
          height="24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="24" height="24" rx="4" fill="white" />
          <path
            d="M19.2 11.2H18.4V11.1H12V12.9H16.2C15.6 14.5 14 15.6 12 15.6C9.5 15.6 7.4 13.5 7.4 11C7.4 8.5 9.5 6.4 12 6.4C13.2 6.4 14.3 6.9 15.1 7.7L16.4 6.4C15.3 5.3 13.7 4.6 12 4.6C8.5 4.6 5.6 7.5 5.6 11C5.6 14.5 8.5 17.4 12 17.4C15.5 17.4 18.4 14.5 18.4 11C18.4 11 18.4 11.2 19.2 11.2Z"
            fill="#4285F4"
          />
        </svg>
      ),
    },
    {
      name: 'AfterPay',
      icon: (
        <svg
          viewBox="0 0 24 24"
          width="24"
          height="24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="24" height="24" rx="4" fill="#B2FCE4" />
          <path
            d="M12 6L9 12H15L12 18M7.5 9L6 12L7.5 15M16.5 9L18 12L16.5 15"
            fill="#000000"
          />
        </svg>
      ),
    },
    {
      name: 'Interac',
      icon: (
        <svg
          viewBox="0 0 24 24"
          width="24"
          height="24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="24" height="24" rx="4" fill="#FDB235" />
          <path d="M4.5 8.5H8L10.5 15.5H7.5L5 8.5Z" fill="white" />
          <path
            d="M11.5 8.5H14L15.5 12.5L17 8.5H19.5L16.5 15.5H14.5L11.5 8.5Z"
            fill="white"
          />
          <circle cx="17" cy="14" r="1.5" fill="white" />
        </svg>
      ),
    },
  ];

  const [checked, setChecked] = useState(true);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <ServiceNavbar />
      <CustomizationLandingPageLayout>
        <div className="checkout-page">
          {/* BreadCrumbs */}
          <Grid
            container
            sx={{
              padding: '1em 5em',
              backgroundColor: '#F0645B',
              '@media (max-width:1199px)': {
                padding: '1em 1em',
              },
            }}
          >
            <Grid item md={12}>
              <Box
                display={'flex'}
                flex={1}
                flexDirection={'row'}
                alignItems={'center'}
                gap={1}
              >
                <HouseIcon onClick={() => navigate('/')} />
                <Typography
                  variant="h4"
                  fontFamily={'Inter !important'}
                  fontSize={15}
                  color={'#ffffff'}
                  fontWeight={'bold'}
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => navigate('/')}
                >
                  Home
                </Typography>
                <ArrowRightIcon />
                <Typography
                  variant="h4"
                  fontFamily={'Inter !important'}
                  fontSize={15}
                  color={'#ffffff'}
                  fontWeight={'bold'}
                >
                  Checkout
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              padding: '2em 5em',
              '@media (max-width:1199px)': {
                padding: '2em 1em',
              },
            }}
            marginBottom={7}
          >
            <Grid item md={12}>
              <Box marginBottom={3}>
                <Typography
                  variant="h4"
                  fontFamily={'Inter !important'}
                  fontSize={18}
                  color={'#000000'}
                >
                  Billing Information
                </Typography>
              </Box>
            </Grid>

            <Grid container spacing={2}>
              <Grid item md={8}>
                <Grid container spacing={2} marginBottom={2}>
                  <Grid item md={12} xs={12}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={'-6px'}
                    >
                      User name
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      sx={inpuStyle}
                      placeholder="First name"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      sx={inpuStyle}
                      placeholder="Last name"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginBottom={2}>
                  <Grid item md={12} xs={12}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={'-6px'}
                    >
                      Address
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      sx={inpuStyle}
                      placeholder="Enter address"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      sx={inpuStyle}
                      placeholder="Unit Number"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginBottom={2}>
                  <Grid item md={3} xs={12}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={1}
                    >
                      Address
                    </Typography>
                    <Select sx={selectStyle} displayEmpty>
                      <MenuItem value="" disabled>
                        Select...
                      </MenuItem>
                      <MenuItem value={10}>abc</MenuItem>
                      <MenuItem value={20}>abc 1</MenuItem>
                      <MenuItem value={30}>abc 1</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={1}
                    >
                      Region/State
                    </Typography>
                    <Select sx={selectStyle} displayEmpty>
                      <MenuItem value="" disabled>
                        Select...
                      </MenuItem>
                      <MenuItem value={10}>abc</MenuItem>
                      <MenuItem value={20}>abc 1</MenuItem>
                      <MenuItem value={30}>abc 1</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={1}
                    >
                      City
                    </Typography>
                    <Select sx={selectStyle} displayEmpty>
                      <MenuItem value="" disabled>
                        Select...
                      </MenuItem>
                      <MenuItem value={10}>abc</MenuItem>
                      <MenuItem value={20}>abc 1</MenuItem>
                      <MenuItem value={30}>abc 1</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={1}
                    >
                      Zip Code
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      sx={inpuStyle}
                      placeholder="Zip Code"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginBottom={2}>
                  <Grid item md={6} xs={12}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={1}
                    >
                      Email
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      sx={inpuStyle}
                      placeholder="First name"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={1}
                    >
                      Phone Number
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      sx={inpuStyle}
                      placeholder="Last name"
                    />
                  </Grid>
                </Grid>

                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  onChange={handleCheckboxChange}
                  label="Deliver into same address"
                  sx={{
                    fontFamily: 'Inter !important',
                    marginBottom: '15px',
                  }}
                />

                {/* Delivery Information */}
                {checked && (
                  <Box>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={18}
                      color={'#000000'}
                      marginBottom={2}
                    >
                      Delivery Information
                    </Typography>
                    <Grid container spacing={2} marginBottom={2}>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                          marginBottom={'-6px'}
                        >
                          User name
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          sx={inpuStyle}
                          placeholder="First name"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          sx={inpuStyle}
                          placeholder="Last name"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} marginBottom={2}>
                      <Grid item md={12} xs={12}>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                          marginBottom={'-6px'}
                        >
                          Address
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          sx={inpuStyle}
                          placeholder="Enter address"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          sx={inpuStyle}
                          placeholder="Unit Number"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} marginBottom={2}>
                      <Grid item md={3} xs={12}>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                          marginBottom={1}
                        >
                          Address
                        </Typography>
                        <Select sx={selectStyle} displayEmpty>
                          <MenuItem value="" disabled>
                            Select...
                          </MenuItem>
                          <MenuItem value={10}>abc</MenuItem>
                          <MenuItem value={20}>abc 1</MenuItem>
                          <MenuItem value={30}>abc 1</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                          marginBottom={1}
                        >
                          Region/State
                        </Typography>
                        <Select sx={selectStyle} displayEmpty>
                          <MenuItem value="" disabled>
                            Select...
                          </MenuItem>
                          <MenuItem value={10}>abc</MenuItem>
                          <MenuItem value={20}>abc 1</MenuItem>
                          <MenuItem value={30}>abc 1</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                          marginBottom={1}
                        >
                          City
                        </Typography>
                        <Select sx={selectStyle} displayEmpty>
                          <MenuItem value="" disabled>
                            Select...
                          </MenuItem>
                          <MenuItem value={10}>abc</MenuItem>
                          <MenuItem value={20}>abc 1</MenuItem>
                          <MenuItem value={30}>abc 1</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                          marginBottom={1}
                        >
                          Zip Code
                        </Typography>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          sx={inpuStyle}
                          placeholder="Zip Code"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} marginBottom={2}>
                      <Grid item md={6} xs={12}>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                          marginBottom={1}
                        >
                          Email
                        </Typography>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          sx={inpuStyle}
                          placeholder="First name"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                          marginBottom={1}
                        >
                          Phone Number
                        </Typography>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          sx={inpuStyle}
                          placeholder="Last name"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}

                <Box className="cart_left" marginBottom={2} padding={2}>
                  <Typography
                    variant="h4"
                    fontFamily={'Inter !important'}
                    fontSize={18}
                    color={'#000000'}
                    marginBottom={2}
                  >
                    Payment Option
                  </Typography>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="credit"
                    name="radio-buttons-group"
                    style={{
                      marginBottom: '15px',
                    }}
                  >
                    <FormControlLabel
                      value="credit"
                      control={<Radio />}
                      label={
                        <div>
                          <CreditIcon /> Debit/Credit Card
                        </div>
                      }
                    />
                  </RadioGroup>
                  <Box marginBottom={2}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={1}
                    >
                      Name on Card
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      sx={inpuStyle}
                    />
                  </Box>
                  <Box marginBottom={2}>
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={14}
                      color={'#000000'}
                      marginBottom={1}
                    >
                      Card Number
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      sx={inpuStyle}
                    />
                  </Box>
                  <Grid container spacing={2} marginBottom={2}>
                    <Grid item md={6}>
                      <Typography
                        variant="h4"
                        fontFamily={'Inter !important'}
                        fontSize={14}
                        color={'#000000'}
                        marginBottom={1}
                      >
                        Expire Date
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        sx={inpuStyle}
                        placeholder="DD/YY"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Typography
                        variant="h4"
                        fontFamily={'Inter !important'}
                        fontSize={14}
                        color={'#000000'}
                        marginBottom={1}
                      >
                        CVC
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        sx={inpuStyle}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Typography
                  variant="h4"
                  fontFamily={'Inter !important'}
                  fontSize={18}
                  color={'#000000'}
                  marginBottom={2}
                >
                  Additional Information
                </Typography>

                <Typography
                  variant="h4"
                  fontFamily={'Inter !important'}
                  fontSize={14}
                  color={'#000000'}
                  marginBottom={1}
                >
                  delivery driver instructions{' '}
                  <span style={{ color: '#77878F' }}>(Optional)</span>
                </Typography>

                <TextareaAutosize
                  placeholder="Notes about your order, e.g. special notes for delivery"
                  style={{
                    width: '100%', // Custom width
                    padding: '10px', // Padding for better appearance
                    fontSize: '14px', // Custom font size
                    borderRadius: '8px', // Rounded corners
                    border: '1px solid #EBEBEC', // Light gray border
                  }}
                  minRows={5} // Minimum rows for the textarea
                  maxRows={6} // Maximum rows for the textarea
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Box className="cart_left" marginBottom={2} padding={2}>
                  <Typography
                    variant="h4"
                    fontFamily={'Inter !important'}
                    fontSize={17}
                    color={'#000000'}
                    marginBottom={3}
                  >
                    Order Summary
                  </Typography>

                  {cartDetails.cart ? (
                    <>
                      {cartDetails.cart.items.map((element, index) => (
                        <Box
                          display={'flex'}
                          flexDirection={'row'}
                          alignItems={'center'}
                          gap="6px"
                          marginBottom={3}
                        >
                          <img
                            src={element.menu_plan.image_url}
                            alt={element.menu_plan.name}
                            className="tImg"
                          />
                          <Box>
                            <Typography
                              variant="h1"
                              fontSize={15}
                              color={'#000000'}
                              fontWeight={'bold'}
                              marginBottom={'2px'}
                            >
                              {element.menu_plan.name}
                            </Typography>
                            <Typography
                              variant="h1"
                              fontSize={12}
                              color={'#000000'}
                              fontWeight={'400'}
                              lineHeight={'18px'}
                            >
                              {element.menu_plan.slug}
                            </Typography>

                            <Typography
                              variant="h1"
                              fontSize={12}
                              color={'#000000'}
                              fontWeight={'400'}
                              lineHeight={'18px'}
                            >
                              Quantity : {element.quantity}
                            </Typography>
                          </Box>
                        </Box>
                      ))}

                      <Box
                        flex={1}
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        marginBottom={2}
                      >
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#5F6C72'}
                        >
                          Sub-total
                        </Typography>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                        >
                          ${cartDetails.total_price}
                        </Typography>
                      </Box>

                      {cartTaxesDetails.map((element, index) => (
                        <Box
                          key={index}
                          flex={1}
                          display={'flex'}
                          flexDirection={'row'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                          marginBottom={3}
                        >
                          <Typography
                            variant="h4"
                            fontFamily={'Inter !important'}
                            fontSize={14}
                            color={'#5F6C72'}
                          >
                            {element.name}
                          </Typography>
                          <Typography
                            variant="h4"
                            fontFamily={'Inter !important'}
                            fontSize={14}
                            color={'#000000'}
                          >
                            {element.type == 'fixed' ? (
                              <>
                                {element.value == '0' ? (
                                  'Free'
                                ) : (
                                  <>${element.value} </>
                                )}
                              </>
                            ) : (
                              <>{element.value}%</>
                            )}
                            {/* {element.type == 'percentage' ? '' : '$'}
                            {element.value}
                            {element.type == 'percentage' ? '%' : ''} */}
                          </Typography>
                        </Box>
                      ))}

                      {cartDetails.total_discount > 0 && (
                        <Box
                          flex={1}
                          display={'flex'}
                          flexDirection={'row'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                          marginBottom={2}
                        >
                          <Typography
                            variant="h4"
                            fontFamily={'Inter !important'}
                            fontSize={14}
                            color={'#5F6C72'}
                          >
                            Discount
                          </Typography>
                          <Typography
                            variant="h4"
                            fontFamily={'Inter !important'}
                            fontSize={14}
                            color={'#000000'}
                          >
                            ${cartDetails.total_discount}
                          </Typography>
                        </Box>
                      )}
                      {!!additinaolItems ? (
                        <>
                          <Box
                            flex={1}
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            marginBottom={3}
                          >
                            <Typography
                              variant="h4"
                              fontFamily={'Inter !important'}
                              fontSize={14}
                              color={'#5F6C72'}
                            >
                              Additional Items
                            </Typography>
                            <Typography
                              variant="h4"
                              fontFamily={'Inter !important'}
                              fontSize={14}
                              color={'#000000'}
                            >
                              ${additinaolItemsTotals}
                            </Typography>
                          </Box>
                        </>
                      ) : (
                        <></>
                      )}

                      {/* <Box
                        flex={1}
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        marginBottom={2}
                      >
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#5F6C72'}
                        >
                          Tax
                        </Typography>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                        >
                          ${cartDetails.total_tax}
                        </Typography>
                      </Box> */}

                      <Divider
                        sx={{
                          backgroundColor: '#E4E7E9',
                          marginBottom: '15px',
                        }}
                      />

                      <Box
                        flex={1}
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        marginBottom={2}
                      >
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={16}
                          color={'#000000'}
                        >
                          Total
                        </Typography>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={16}
                          color={'#000000'}
                          fontWeight={'bold'}
                        >
                          {/* ${(parseFloat(cartDetails.final_amount) + parseFloat(additinaolItemsTotals))} */}
                          $
                          {(
                            parseFloat(cartDetails.final_amount) +
                            parseFloat(additinaolItemsTotals)
                          ).toFixed(2)}
                        </Typography>
                      </Box>

                      <Button
                        variant="contained"
                        sx={{
                          width: '100%',
                          textTransform: 'uppercase',
                          fontWeight: 'bold',
                        }}
                        onClick={placeOrder}
                      >
                        Place order
                      </Button>
                      <div className="p-4 bg-white rounded-lg shadow-sm">
                        <p className="text-sm text-gray-600 mb-3">
                          Secure payment via:
                        </p>
                        <div className="d-flex items-center gap-4 flex-wrapsss">
                          {paymentMethods.map((method) => (
                            <div className="rounded border border-gray-200 hover:border-gray-300 p-2">
                              {method.icon}
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </CustomizationLandingPageLayout>
      <ServiceFooter />
    </>
  );
};

export default Cart;
