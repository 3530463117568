import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import FormModalInputSelectState from './inputs/formModalInputSelectState';
import FormModalInputSelectPlan from './inputs/formModalnputSelectPlan';
import FormModalInputText from './inputs/formModalInputText';
import MultiSelect from './inputs/formModalSelectMultiple';
import axios from 'axios';
import { useProfile } from '../../../../customHooks/useProfile';
import CustomButton from '../../../../components/customButton/CustomButton';
import SubscriptionData from './subscriptionData';
import SubscriptionNewPlanData from './subscriptionNewPlanData';

import Wallets from './wallets';
import { TENANT_URL } from '../../../../../config';
import FormModalInputSelectObject from './inputs/FormModalInputSelectObject';
import AdditionalMenuNew from '../../components/AdditionalMenuNew';
import CustomerModalInputText from '../../../../../customer/pages/customersectionprofile/components/CustomerModalInputs/CustomerModalInputText';
import { elements } from 'chart.js';

const InputForm = () => {
  const {
    getMenuPlanListByCategoryId,
    menuPlanList,
    setMenuPlanList,
    enableMenuPlan,
    setEnableMenuPlan,
    planCategories,
    subscriptionData,
    subsloading,
    planPrice,
    setPlanPrice,
    updatePlan,
    getMenuPlanData,
    getAdditionalMenuItems,
    getAdditionalItems,
    getNewPlanDetails,
    tempAdditionalPlan,
    setTempAdditionalPlan,
    isNewPlanStatus,
    currentCustomer,
    setNewPlanActiveStatus,
  } = useProfile();
  const defaultMenuPlan = menuPlanList.find((plan) => plan.id === 0) || {};

  const [categoryId, setCategoryId] = useState();
  const [coupanCode, setCoupanCode] = useState('');

  const [newSelectedMenuPlanId, setNewSelectedMenuPlanId] = useState();
  const [newMenuPlan, setNewMenuPlan] = useState(0);
  const [taxes, setTaxes] = useState([]);

  const [newPlanApiRes, setNewPlanApiRes] = useState({});
  const [oldPlanAmount, setOldPlanAmount] = useState(0);

  const [totalNewPlanPrice, setTotalNewPlanPrice] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [taxableAmount, setTaxableAmount] = useState(0);

  const [totalCalculatedAmount, setcalculatedAmount] = useState(0);

  const [mealMonthDetails, setMealMonthDetails] = useState({
    totalDaysInMonth: 30,
    totalMondayInMonth: 5,
    totalTuesdayInMonth: 4,
    totalWednesdayInMonth: 4,
    totalThursdayInMonth: 4,
    totalFridayInMonth: 4,
    totalSaturdayInMonth: 4,
    totalSundayInMonth: 5,
  });
  const [editAdditionalItemsModalOpen, setEditAdditionalItemsModalOpen] =
    useState(false);
  const handleClickAddNewAdditionalItems = () => {
    setEditAdditionalItemsModalOpen(true);
  };
  const initialMenuPlanData = {
    total: 0,
    delivered: 0,
    remaining: 0,
  };
  const [newMenuPlanData, setNewMenuPlanData] = useState(initialMenuPlanData);
  const [currentMenuPlanData, setCurrentMenuPlanData] =
    useState(initialMenuPlanData);

  useEffect(() => {
    if (newSelectedMenuPlanId) {
      getNewPlanDetails(newSelectedMenuPlanId).then(({ data }) => {
        setNewPlanApiRes(data);
      });

      getMenuPlanData(newSelectedMenuPlanId).then(({ data }) => {
        setNewMenuPlanData({
          total: data.new_total,
          delivered: data.new_delivered,
          remaining: data.new_remaining,
        });
      });
    }
    recalculateValues();
  }, [newSelectedMenuPlanId]);

  useEffect(() => {
    let total =
      (newPlanApiRes?.plan_price || 0).toFixed(2) +
      (tempAdditionalPlan?.total_price || 0).toFixed(2);
    setTotalNewPlanPrice(total);
    recalculateValues();
  }, [newPlanApiRes?.plan_price, tempAdditionalPlan?.total_price]);

  useEffect(() => {
    getMenuPlanData().then(({ data }) => {
      setCurrentMenuPlanData({
        total: data.current_total,
        delivered: data.current_delivered,
        remaining: data.current_remaining,
      });
    });
  }, []);
  const SPGridHeaderButton = ({ children, onClick, sx }) => {
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

    return (
      <Button
        onClick={onClick}
        variant="filled"
        sx={{
          width: 'inherit',
          borderRadius: '25px',
          textTransform: 'capitalize',

          py: 1.4,
          fontWeight: 400,
          color: 'white',

          boxShadow: 'none',
          marginLeft: '10px',
          ...sx,
        }}
        type="submit"
      >
        {children}
      </Button>
    );
  };

  const SPGridHeaderButtonTypeButton = ({ children, onClick, sx }) => {
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

    return (
      <Button
        onClick={onClick}
        variant="filled"
        sx={{
          width: 'inherit',
          borderRadius: '25px',
          textTransform: 'capitalize',

          py: 1.4,
          fontWeight: 400,
          color: 'white',

          boxShadow: 'none',
          marginLeft: '10px',
          ...sx,
        }}
        type="button"
      >
        {children}
      </Button>
    );
  };

  let planSchema = {
    planCategory: Yup.string().required('*'),
    menuPlan: Yup.object().required('*'),
    subscriptionPlan: Yup.string().required('*'),
    deliverySlot: Yup.string().required('*'),
    payment: Yup.string(),
  };

  const validationSchema = Yup.object().shape(planSchema);

  useEffect(() => {
    if (categoryId) {
      getMenuPlanListByCategoryId(categoryId);
      setNewMenuPlanData(initialMenuPlanData);
    }
    recalculateValues();
  }, [categoryId]);

  const diliverySlotList = [
    { id: 'Breakfast', name: 'Breakfast' },
    { id: 'Lunch', name: 'Lunch' },
    { id: 'Dinner', name: 'Dinner' },
  ];

  const subsList = [{ id: 'monthly', name: 'Monthly' }];
  const [currentMenuItemsData, setCurrentMenuItemsData] = useState({});
  const [customMenu, setCustomMenu] = useState({
    everyday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  });
  const transformCustomMenu = (menu) => {
    const result = {};

    // Predefined order of days
    const days = [
      'everyday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ];

    // Iterate through the days array to maintain the correct order
    days.forEach((day) => {
      // If the day exists in the menu, use its items; otherwise, set it to an empty array
      if (menu[day] && menu[day].items) {
        result[day] = menu[day].items;
      } else {
        result[day] = [];
      }
    });

    return result;
  };
  useEffect(() => {
    if (subscriptionData) {
      // setPlanPrice(subscriptionData.plan_amount);
      setPlanPrice(subscriptionData.final_plan_amount);
      if (!oldPlanAmount) {
        // setOldPlanAmount(subscriptionData.plan_amount);
        setOldPlanAmount(subscriptionData.final_plan_amount);
      }

      setMealMonthDetails(subscriptionData.MealMonthDetails);
      // setNewPlanCategoryName(subscriptionData.plan_category_name);
      // setCategoryId( subscriptionData.plan_category_id)
      console.log('subscriptionData:', subscriptionData);
      // setNewMenuPlan(subscriptionData.menu_plan_name);
      // setNewSelectedMenuPlanId(subscriptionData.menu_plan_id);
    }
  }, [subscriptionData]);

  useEffect(() => {
    getPlanTaxes();
    getAdditionalMenuItems().then(({ data }) => {
      setCurrentMenuItemsData(data.data);
    });
    getAdditionalItems().then(({ data }) => {
      const result = transformCustomMenu(data);
      setCustomMenu(result);
    });
  }, []);
  const dayCount = (day) => {
    switch (day) {
      case 'everyday':
        return mealMonthDetails.totalDaysInMonth;
        break;
      case 'monday':
        return mealMonthDetails.totalMondayInMonth;
        break;
      case 'tuesday':
        return mealMonthDetails.totalTuesdayInMonth;
        break;
      case 'wednesday':
        return mealMonthDetails.totalWednesdayInMonth;
        break;
      case 'thursday':
        return mealMonthDetails.totalThursdayInMonth;
        break;
      case 'friday':
        return mealMonthDetails.totalFridayInMonth;
        break;
      case 'saturday':
        return mealMonthDetails.totalSaturdayInMonth;
        break;
      case 'sunday':
        return mealMonthDetails.totalSundayInMonth;
        break;
      default:
        return 1;
    }
  };

  // Function to update price from the customMenu
  const updatePrice = (price) => {
    const selectedIds = [];
    Object.keys(customMenu).forEach((day) => {
      const days = dayCount(day);
      for (let i = 0; i < days; i++) {
        selectedIds.push(...customMenu[day]);
      }
    });
    const totalPrice = selectedIds.reduce((sum, item) => {
      const price = currentMenuItemsData.find((i) => i.id == item).price || 0;
      return sum + (price || 0); // Add the price of each selected item
    }, 0);
    setPlanPrice(Number(totalPrice.toFixed(2)) + Number(price));
    return;
  };
  const handleSubmit = (values, actions) => {
    updatePlan(
      values.planCategory,
      values.deliverySlot,
      values.subscriptionPlan,
      values.menuPlan.id,
      values.payment
    );
  };

  const handleClose = () => {
    setEditAdditionalItemsModalOpen(!open);
  };

  const BootstrapInput = {
    'label + &': {
      marginTop: '10px',
    },
    '& .MuiInputBase-input': {
      position: 'relative',
      backgroundColor: '#fff',
      fontSize: 16,
      padding: '15px',
      height: '46px',
      boxSizing: 'border-box',
      borderRadius: '10px',
      border: '1px solid #B0B0B0',
      background: '#FFF',
      width: '100%',
      '&:focus': {},
    },
  };

  const applyCoupanCode = async () => {
    let totalAmount =
      (newPlanApiRes?.plan_price || 0) + (tempAdditionalPlan?.total_price || 0);
    if (totalAmount > 0) {
      try {
        const token = JSON.parse(localStorage.getItem('tenantdata')).token;
        var mealConfig = {
          method: 'post',
          url: `${TENANT_URL}/customer/${currentCustomer}/voucher/apply`,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          data: {
            voucher_code: coupanCode,
            order_total:
              (newPlanApiRes?.plan_price || 0) +
              (tempAdditionalPlan?.total_price || 0),
          },
        };
        const response = await axios({ ...mealConfig });
        if (!!response.data.discount) {
          console.warn('discount - success');
          console.log('discount amount: ', response.data.discount);
          setDiscountAmount(response.data.discount);
        }
      } catch (error) {
        console.warn('discount - 1');
        setDiscountAmount(0);
      }
    } else {
      console.warn('discount - 2');
      setDiscountAmount(0);
    }
    recalculateValues();
  };
  const getPlanTaxes = async () => {
    let total =
      (newPlanApiRes?.plan_price || 0) + (tempAdditionalPlan?.total_price || 0);
    setTotalNewPlanPrice(total);
    console.log('total:', total);
    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var mealConfig = {
        method: 'get',
        url: `${TENANT_URL}/customer/${currentCustomer}/plan/taxes`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await axios({ ...mealConfig });
      setTaxes(response.data.taxes);
      recalculateValues();
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    recalculateValues();
  }, [discountAmount]);

  const recalculateValues = () => {
    let total =
      (newPlanApiRes?.plan_price || 0) + (tempAdditionalPlan?.total_price || 0);

    let totalAmountCal = parseFloat(total).toFixed(2);
    let discountAmountCal = parseFloat(discountAmount).toFixed(2);
    let accountBalanceCal = parseFloat(subscriptionData?.payment).toFixed(2);

    let finalAmount = totalAmountCal - discountAmountCal;
    finalAmount = finalAmount.toFixed(2);

    let taxAmount = 0;
    taxes.map((elements) => {
      if (elements.type == 'percentage') {
        taxAmount =
          taxAmount +
          (parseFloat(finalAmount) * parseFloat(elements.value)) / 100;
      }
      if (elements.type == 'fixed') {
        taxAmount = taxAmount + elements.value;
      }
      console.warn(elements.value, '---taxAmount---:', taxAmount);
    });

    let totalAmount =
      parseFloat(totalAmountCal) -
      parseFloat(discountAmountCal) +
      parseFloat(taxAmount) -
      parseFloat(accountBalanceCal);

    console.warn('totalAmountCal:', totalAmountCal);
    console.warn('discountAmountCal:', discountAmountCal);
    console.warn('texableAmountCal:', taxAmount);
    console.warn('accountBalanceCal:', accountBalanceCal);

    console.warn('totalAmount:', totalAmount);

    setcalculatedAmount(totalAmount.toFixed(2));
    return totalAmount.toFixed(2);
  };

  return (
    <>
      <AdditionalMenuNew
        open={editAdditionalItemsModalOpen}
        setOpen={setEditAdditionalItemsModalOpen}
        handleClose={handleClose}
      />
      {subsloading ? (
        <></>
      ) : (
        <Box>
          <Formik
            initialValues={{
              deliverySlot: subscriptionData
                ? subscriptionData.delivery_time_slot
                : '',
              subscriptionPlan: subscriptionData
                ? subscriptionData.subscription_plan
                : '',
              planCategory: subscriptionData
                ? subscriptionData.plan_category_id
                : '',
              menuPlan: subscriptionData ? subscriptionData.menu_plan_name : '',
              payment: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnMount
          >
            {(props, index) => {
              const {
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
                values,
                errors,
              } = props;

              return (
                <Form onSubmit={handleSubmit}>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item lg={12} xl={5} xs={12}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            mb: '18px',
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: 'Outfit',
                              fontSize: '22px',
                              fontWeight: '500',
                              lineHeight: '28px',
                              letterSpacing: '0.3px',
                              textAlign: 'left',
                            }}
                          >
                            Upgrade Subscription
                          </Typography>
                          <AutorenewIcon
                            sx={{
                              backgroundColor: '#63BE5D',
                              color: 'white',
                              borderRadius: '50%',
                              width: '47px',
                              height: '47px',
                            }}
                          />
                        </Box>
                        <Grid container spacing={'20px'}>
                          <Grid item xs={12} sm={12}>
                            <Field
                              name="planCategory"
                              list={planCategories}
                              label="Plan Category"
                              onChange={(e) => {
                                const selectedValue = e.target.value;
                                const selectedItem = planCategories.find(
                                  (item) => item.id === selectedValue
                                );
                                const selectedName = selectedItem
                                  ? selectedItem.name
                                  : '';

                                setNewPlanActiveStatus(true);
                                props.setFieldValue(
                                  'planCategory',
                                  e.target.value
                                );
                                setCategoryId(e.target.value);
                                setNewSelectedMenuPlanId('');
                                setNewMenuPlan(0);
                                props.setFieldValue('menuPlan', '');
                                if (
                                  props.values.menuPlan &&
                                  props.values.menuPlan.planCategory ==
                                    e.target.value
                                ) {
                                  updatePrice(props.values.menuPlan.plan_price);
                                  setNewMenuPlan(props.values.menuPlan.id);
                                  props.setFieldValue(
                                    'menuPlan',
                                    props.values.menuPlan.name
                                  );
                                } else {
                                  setNewSelectedMenuPlanId('');
                                  setNewMenuPlan(0);
                                  props.setFieldValue('menuPlan', '');
                                }

                                setEnableMenuPlan(true);
                                setNewPlanApiRes({});
                                setTempAdditionalPlan({});
                              }}
                              onBlur={handleBlur}
                              component={FormModalInputSelectState}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <label
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: '5px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'Outfit',
                                  fontWeight: 500, // The last declared font-weight is 500
                                  fontSize: '1rem',
                                  lineHeight: 1.5,
                                  letterSpacing: '0.00938em',
                                  color: '#3F2F5B',
                                  margin: '0',
                                }}
                              >
                                Menu Plan
                              </p>
                              <a
                                style={{
                                  textDecoration: 'underline',
                                  color: '#FF6B00',
                                  cursor: 'pointer',
                                  fontFamily: 'Outfit',
                                }}
                                onClick={handleClickAddNewAdditionalItems}
                              >
                                Additional items
                              </a>
                            </label>

                            <Field
                              disabled={!enableMenuPlan}
                              name="menuPlan"
                              list={menuPlanList}
                              onChange={(e) => {
                                const selectedPlan = menuPlanList.find(
                                  (plan) => plan.name === e.target.value.name
                                ); // Find the full object
                                if (selectedPlan) {
                                  props.setFieldValue('menuPlan', selectedPlan); // Set the full object
                                  updatePrice(selectedPlan.plan_price); // Update price
                                  setNewMenuPlan(selectedPlan.id); // Update local state with the full object

                                  if (
                                    selectedPlan.id ==
                                    subscriptionData.menu_plan_id
                                  ) {
                                    setNewPlanApiRes({});
                                    setTempAdditionalPlan({});
                                    setNewPlanActiveStatus(false);
                                    setNewSelectedMenuPlanId(0); // Update selected plan ID
                                  } else {
                                    setNewSelectedMenuPlanId(selectedPlan.id); // Update selected plan ID
                                    setNewPlanActiveStatus(true);
                                  }
                                }
                              }}
                              onBlur={handleBlur}
                              subscriptionData={subscriptionData}
                              value={
                                newMenuPlan || subscriptionData.menu_plan_name
                              } // Ensure the full object is passed to Formik, not just the name
                              component={FormModalInputSelectObject}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Field
                              name="deliverySlot"
                              list={diliverySlotList}
                              label="Delivery Slot"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              component={FormModalInputSelectState}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Field
                              name="subscriptionPlan"
                              list={subsList}
                              label="Subscription Plan"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              component={FormModalInputSelectState}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Typography
                              sx={{
                                mb: '5px',
                                marginTop: '0',
                                fontWeight: '500',
                              }}
                            >
                              Coupon Code
                            </Typography>
                            <Box
                              sx={{
                                position: 'relative',
                              }}
                            >
                              <TextField
                                id="couponcode"
                                placeholder={'Enter Coupon Code'}
                                name="couponcode"
                                value={coupanCode}
                                sx={BootstrapInput}
                                variant="outlined"
                                onChange={(e) => setCoupanCode(e.target.value)}
                                InputProps={{
                                  disableUnderline: true,
                                }}
                                style={{
                                  width: '100%',
                                }}
                              />
                              <SPGridHeaderButtonTypeButton
                                variant="filled"
                                text={'Apply'}
                                sx={{
                                  borderRadius: '8px',
                                  minWidth: '60px',
                                  position: 'absolute',
                                  right: 0,
                                  height: '46px',
                                }}
                                type="button"
                                onClick={applyCoupanCode}
                              >
                                Apply
                              </SPGridHeaderButtonTypeButton>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={12} xs={12} xl={7}>
                        <Grid container spacing={'10px'}>
                          <Grid item xs={12}>
                            <Wallets
                              wallet={subscriptionData.payment}
                              due={
                                planPrice > subscriptionData.payment
                                  ? (
                                      planPrice - subscriptionData.payment
                                    ).toFixed(2)
                                  : 0
                              }
                            />
                          </Grid>
                          <Grid item lg={12} xl={6}>
                            <SubscriptionData
                              planCategoryName={
                                subscriptionData.plan_category_name
                              }
                              type={'Running Plan'}
                              menuPlan={subscriptionData.menu_plan_name}
                              planData={currentMenuPlanData}
                              planItems={
                                subscriptionData.active_plan.food_items || ''
                              }
                              planPrice={
                                subscriptionData.active_plan.plan_price || 0
                              }
                              addons={
                                subscriptionData.active_plan.addons_names || ''
                              }
                              additionalItems={
                                subscriptionData.active_plan
                                  .additional_items_details.items || ''
                              }
                              additionalItemsPrice={
                                subscriptionData.active_plan
                                  .additional_items_details.total_price || 0
                              }
                            />
                          </Grid>
                          <Grid item lg={12} xl={6}>
                            <SubscriptionNewPlanData
                              planCategoryName={''}
                              type={'New Plan'}
                              menuPlan={newMenuPlan}
                              planData={newMenuPlanData}
                              planItems={newPlanApiRes.food_items || ''}
                              planPrice={newPlanApiRes.plan_price || 0}
                              addons={newPlanApiRes.addons || ''}
                              additionalItems={tempAdditionalPlan.items || ''}
                              additionalItemsPrice={
                                tempAdditionalPlan.total_price || 0
                              }
                            />
                          </Grid>
                          <Grid item xs={12} lg={12}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '3px',
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  color: '#3F2F5B',
                                  opacity: 0.6,
                                }}
                              >
                                New Plan
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: '600',
                                  color: '#000000',
                                }}
                              >
                                ${(newPlanApiRes.plan_price || 0).toFixed(2)}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '3px',
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  color: '#3F2F5B',
                                  opacity: 0.6,
                                }}
                              >
                                Additional Items
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: '600',
                                  color: '#000000',
                                }}
                              >
                                $
                                {(tempAdditionalPlan.total_price || 0).toFixed(
                                  2
                                )}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '3px',
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  color: '#3F2F5B',
                                  opacity: 0.6,
                                }}
                              >
                                Coupon
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: '600',
                                  color: '#000000',
                                }}
                              >
                                ${discountAmount}
                              </Typography>
                            </Box>

                            {taxes.map((element) => {
                              return (
                                <>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      marginBottom: '3px',
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        color: '#3F2F5B',
                                        opacity: 0.6,
                                      }}
                                    >
                                      {element.name}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        color: '#000000',
                                      }}
                                    >
                                      {element.type == 'percentage' ? (
                                        element.value + '%'
                                      ) : (
                                        <>
                                          {element.value == '0'
                                            ? 'Free'
                                            : '$' + element.value}
                                        </>
                                      )}
                                    </Typography>
                                  </Box>
                                </>
                              );
                            })}

                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '3px',
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  color: '#3F2F5B',
                                  opacity: 0.6,
                                }}
                              >
                                Wallet Amount
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: '600',
                                  color: '#000000',
                                }}
                              >
                                - ${subscriptionData.payment || 0}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginTop: '3px',
                                borderTop: '1px solid rgba(0, 0, 0,0.2)',
                                paddingTop: '10px',
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  color: '#3F2F5B',
                                  opacity: 0.6,
                                }}
                              >
                                Total Amount Payment
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: '600',
                                  color: '#000000',
                                }}
                              >
                                $
                                {totalCalculatedAmount >= 0
                                  ? totalCalculatedAmount
                                  : '0'}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={7}>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: 'Outfit',
                                  fontSize: '16px',
                                  fontWeight: '500',
                                  lineHeight: '23px',
                                  letterSpacing: '0px',
                                  textAlign: 'left',
                                  flexShrink: 1,
                                }}
                              >
                                Payment Amount To Upgraded
                              </Typography>
                              <TextField
                                id="couponcode"
                                name="payment"
                                placeholder="100$"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                sx={BootstrapInput}
                                variant="outlined"
                                InputProps={{
                                  disableUnderline: true,
                                }}
                                style={{
                                  width: '75px',
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            lg={5}
                            sx={{
                              display: 'flex',
                              alignItems: 'end',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <SPGridHeaderButton
                              variant="filled"
                              text={'Update'}
                              type={'submit'}
                            >
                              Update
                            </SPGridHeaderButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      )}{' '}
    </>
  );
};

export default InputForm;
