import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import Rectangle_soil from './images/Rectangle-soil.svg';
import header_mask from './images/header-mask.svg';
import pink_mask from './images/pink-mask.svg';
import mainLogo from './images/main-logo.svg';
import pink_location from './images/pink-location.svg';
import hero_three_banner from './images/hero-three-banner.svg';
import './Header.css';

function Header() {
  const [activeCategory, setActiveCategory] = useState('All');

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };
  const dishTitleStyle = {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '29px',
    textAlign: 'center',
    color: '#455149',
  };
  const dishSubTitleStyle = {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '29px',
    textAlign: 'center',
    color: '#858585',
  };

  return (
    <>
      {/* Header Mask Wrapper */}
      <div className="header-mask-wrap position-relative">
        <div className="header-mask">
          <img src={header_mask} alt="mask" />
        </div>
        <header>
          <div className="left">
            <img src={mainLogo} alt="Logo" className="logo" />
          </div>

          <nav>
            <ul className="nav-list m-0">
              <li>
                <a className="active" href="#">
                  Home
                </a>
              </li>
              <li>
                <a href="#">How it works</a>
              </li>
              <li>
                <a href="#">Our Work</a>
              </li>
              <li>
                <a href="#">Pricing</a>
              </li>
              <li>
                <a href="#">Contact Us</a>
              </li>
            </ul>
          </nav>

          <div className="right_btns">
            <a href={`/customer/profile`}>Login</a>
            <button>Create Account</button>
          </div>
        </header>
      </div>

      {/* Hero Main Section */}
      <section className="hero_main_section mt-5 overflow-hidden">
        <div>
          <div className="row overflow-hidden">
            {/* Left Column */}
            <div className="col-md-6 position-relative p-0">
              <div className="Rectangle-soil">
                <img src={Rectangle_soil} alt="mask icon" />
              </div>
              <div className="footer_pink_mask">
                <img src={pink_mask} alt="pink mask" />
              </div>
              <div className="hero_sec_left" style={{ marginTop: '20vh' }}>
                <h6>Discover Heart of</h6>
                <h1>Culinary Excellence</h1>
                <p>
                  Step into a world where flavors come alive and culinary dreams
                  are realized. We blend tradition with innovation to bring you
                  an unforgettable dining experience.
                </p>
                <div className="form_find_food">
                  <div className="input_wrap">
                    <img src={pink_location} alt="location icon" />
                    <input type="text" placeholder="Enter Your Address" />
                  </div>
                  <button>
                    <img src="" alt="" /> Find Food
                  </button>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="col-md-6 p-0 position-relative">
              <div className="right_banner">
                <img
                  className="w-100"
                  src={hero_three_banner}
                  alt="banner here"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Header;
