import moment from 'moment';

const tspColumns = [
  {
    field: 'order_id',
    headerName: 'Order Id',
    minWidth: 80,
    flex: 1,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  },
  {
    field: 'menu_plan',
    headerName: 'Menu Plan',
    minWidth: 120,
    flex: 2,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  },
  {
    field: 'menu_items',
    headerName: 'Items',
    minWidth: 120,
    flex: 2,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  },
    {
    field: 'menu_addons',
    headerName: 'Addons',
    minWidth: 120,
    flex: 2,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  },
  {
    field: 'delivery_date',
    headerName: 'Order Date',
    minWidth: 100,
    flex: 1,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <>
        <p>{moment(params.row.delivery_date).utc().format('DD-MM-YYYY')}</p>
      </>
    ),
  },
  {
    field: 'order_status',
    headerName: 'Status',
    minWidth: 90,
    flex: 1,
    headerClassName: 'action-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <>
        <div
          style={{
            backgroundColor:
              params.row.order_status === 'Completed'
                ? 'green'
                : params.row.order_status === 'Cancelled'
                ? 'red'
                : 'gray',
            width: '10px',
            height: '10px',
            borderRadius: '50%',
          }}
        />
        <p style={{ marginLeft: '5px' }}>{params.row.order_status}</p>
      </>
    ),
  },
];

export default tspColumns;
