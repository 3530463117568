import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import CustomButton from '../../../../admin/components/customButton/CustomButton';
import { useRoute } from '../../../customHooks/useRoute';
import PaymentModalInputTextArea from '../../customers/components/modals/paymentModalIInputs/paymentModalInputDescription';
import WebInputSelectCountry from '../webListDisplay/components/WebInputSelectCountry';
import axios from 'axios';
import { TENANT_URL } from '../../../../config';
import { useSnackbar } from '../../../../components/utils/snackbarProvider/SnackbarContextProvider';
import { validateDateTime } from '@mui/x-date-pickers/internals';
import AutocompleteAddressInput from '../../../../components/utils/autocompleteAddressInput/autocompleteAddressInput';
import ModalInputText from './ModalInputText';
import CustomerModalInputText from '../../customers/components/modals/CustomerModalInputs/CustomerModalInputText';
import { ReactComponent as TaxIcon } from '../../../assets/tax.svg';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const RouteAddForm = ({ userName, setUserName }) => {
  const { show } = useSnackbar();
  const [currentCurrency, setCurrentCurrency] = useState();
  const [webSetting, setWebSetting] = useState(null);
  const [currencyList, setCurrencyList] = useState([]);
  const [taxesList, setTaxesList] = useState([]);

  const [logoFile, setLogoFile] = useState(null);
  const [standardCharge, setStandardCharge] = useState(''); // State for standard route charges

  console.log('taxesList:', taxesList);
  const handleSubmit = (values) => {
    var model = {
      title: values.title,
      currency: values.currencyD,
      timezone: '1234567890',
      country: values.country,
      state: values.province,
      area: values.city,
      zip_code: values.zip,
      address: values.address,
      large_logo: '3',
      small_logo: '3',
      favicon: '3',
      longitude: values.longitude,
      latitude: values.latitude,
    };

    let data = JSON.stringify(model);

    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${TENANT_URL}/web/setting/${userName}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        show('Data Saved');
      })
      .catch((error) => {
        console.log(error);
        show('Not Data Saved', 'error');
      });
  };

  const schema = Yup.object().shape({
    title: Yup.string().min(2, 'Too Short').max(30, 'Too Long').required('*'),
    userName: Yup.string()
      .min(2, 'Too Short')
      .max(30, 'Too Long')
      .required('*'),
    address: Yup.string()
      .min(2, 'Too short')
      .max(150, 'Too long')
      .required('*'),
    unit: Yup.string()
      .max(10, 'Too long')
      .matches(/^[a-zA-Z0-9\s]*$/, 'Only alphanumeric '),
    province: Yup.string()
      .matches(/^[A-Za-z\s]+$/, 'Only alphabetic characters ')
      .max(30, 'Too long')
      .required('*'),
    city: Yup.string().max(30, 'Too long').required('*'),
    zip: Yup.string()
      .test('zip-validation', function (value) {
        const { country } = this.parent;

        const patterns = {
          canada: /^[A-Z]\d[A-Z]\s?\d[A-Z]\d$/i,
          usa: /^\d{5}$/,
          'united states': /^\d{5}$/,
          india: /^\d{6}$/,
          uk: /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i,
          'united kingdom': /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i,
          australia: /^\d{4}$/,
          'new zealand': /^\d{4}$/,
          pakistan: /^\d{5}$/,
        };
        const errorMessage = `Invalid ${country} zip format`;

        if (country) {
          const patternCountry = patterns[country.toLowerCase()];
          if (patternCountry) {
            const isValid = patternCountry.test(value);
            if (!isValid) {
              return this.createError({ message: errorMessage });
            }
            return true;
          }
        }

        const defaultPattern =
          /^(?:(?:[A-Z0-9]{6})|(?:[A-Z0-9]{3}\s?[A-Z0-9]{3})|(?:\d{5})|(?:\d{6})|(?:[A-Z]{2}\d{1,2}\s?\d[A-Z]{2})|(?:\d{4}))$/i;
        const isValidDefault = defaultPattern.test(value);
        if (!isValidDefault) {
          return this.createError({ message: 'Invalid zip format' });
        }
        return true;
      })
      .required('*'),
  });

  const fetchCurrencies = () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BACKEND_URL}/get/currency`,
    };

    axios
      .request(config)
      .then((response) => {
        setCurrencyList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchTaxes = () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${TENANT_URL}/taxes`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setTaxesList(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchUserName = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var fetchonfig = {
      method: 'get',
      url: `${TENANT_URL}/tenant/id`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(fetchonfig);
      setUserName(response.data);
      const websetting = {
        method: 'get',
        url: `${TENANT_URL}/web/setting/${response.data}`,
        headers: { Authorization: `Bearer ${token}` },
      };
      const { data: res } = await axios(websetting);
      setWebSetting(res.data);
      setCurrentCurrency(res.data.currency);
       // Set the standard route charges
    if (res.data?.standard_route_charges) {
      setStandardCharge(res.data.standard_route_charges);
    }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCurrencies();
    fetchUserName();
    fetchTaxes();
  }, []);
  useEffect(() => {
    fetchCurrencies();
    fetchUserName();
    fetchTaxes();
  }, []);

  const addNewTaxes = () => {
    setTaxesList((taxesList) => [...taxesList, { name: '', value: '' }]);
  };

  const saveLogo = async () => {
    if (!logoFile) {
      console.log('No logo file selected.');
      return;
    }
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;

    const formData = new FormData();
    formData.append('large_logo', logoFile); // The 'large_logo' parameter name as required

    try {
      // Replace `{{fork-demo}}` with your actual URL
      const response = await axios.post(
        `${TENANT_URL}/restaurant/logo`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data', // Required for file uploads
            Authorization: `Bearer ${token}`,
          },
        }
      );
      localStorage.setItem('app-logo', response.data.data.url);
      window.location.reload();
    } catch (error) {
      console.error('Error uploading logo:', error);
    }

    console.log('Logo uploaded:', logoFile);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the first file selected
    if (file) {
      setLogoFile(file);
    }
  };

  const saveTaxes = () => {
    const model = {
      taxes: taxesList.map((tax) => ({
        name: tax.name,
        value: tax.value,
      })),
    };

    let data = JSON.stringify(model);

    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${TENANT_URL}/taxes`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        show('Data Saved');
      })
      .catch((error) => {
        console.log(error);
        show('Not Data Saved', 'error');
      });
  };

  const handleInputChange = (index, field, value) => {
    setTaxesList((prevList) =>
      prevList.map((tax, i) => (i === index ? { ...tax, [field]: value } : tax))
    );
  };

  const ModalInputText = ({ value, onChange, ...props }) => (
    <input value={value} onChange={onChange} {...props} />
  );

  const handleRemoveTax = (indexToRemove) => {
    setTaxesList((prevTaxesList) =>
      prevTaxesList.filter((_, index) => index !== indexToRemove)
    );
  };


  const saveStandardCharge = async () => {
    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      const data = JSON.stringify({ charges: standardCharge });

      const config = {
        method: 'put',
        url: `${TENANT_URL}/restaurant/standard-route-charges`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data,
      };

      const response = await axios(config);
      show(response.data.message || 'Standard route charge updated successfully');
    } catch (error) {
      console.error('Failed to save standard route charges:', error);
      show('Failed to update standard route charge', 'error');
    }
  };

  const handleStandardChargeChange = (event) => {
    setStandardCharge(event.target.value);
  };


  return (
    <>
      <Box
        sx={{
          flex: 1,
          width: '100%',
          height: 'auto',
          padding: '24px',
          backgroundColor: 'rgba(255, 255, 255, 0.3)',
          borderRadius: '15px',
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
        }}
      >
        {webSetting && (
          <Box>
            <Formik
              initialValues={{
                title: webSetting ? webSetting.title : '',
                userName: webSetting ? webSetting.tenant_id : '',
                address: webSetting ? webSetting.address : '',

                currencyD: webSetting ? webSetting.currency : '',
                province: webSetting ? webSetting.state : '',
                city: webSetting ? webSetting.area : '',
                zip: webSetting ? webSetting.zip_code : '',
              }}
              validationSchema={schema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {(props) => {
                const {
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                  resetForm,
                  values,
                } = props;

                console.log(values);
                return (
                  <Form
                    onSubmit={handleSubmit}
                    style={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      gap: '24px',
                    }}
                  >
                    <Box
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        gap: '24px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          gap: '24px',
                        }}
                      >
                        <Typography variant="h5" fontWeight="500">
                          Service Provider Settings
                        </Typography>
                        <Box
                        // sx={{
                        //   overflowY: 'auto',
                        //   height: 'calc(100vh - 475px)',
                        // }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Field
                                name="title"
                                label="Title"
                                InputProps={{
                                  style: {
                                    backgroundColor: 'white',
                                    borderRadius: '10px',
                                    height: '46px',
                                  },
                                }}
                                placeholder="Title"
                                onChange={handleChange}
                                // component={ModalInputText}
                                component={CustomerModalInputText}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Field
                                name="userName"
                                label="UserName"
                                InputProps={{
                                  style: {
                                    backgroundColor: 'white',
                                    borderRadius: '10px',
                                    height: '46px',
                                  },
                                }}
                                disabled
                                placeholder="UserName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                // component={ModalInputText}
                                component={CustomerModalInputText}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Field
                                name="address"
                                label="Address"
                                InputProps={{
                                  style: {
                                    backgroundColor: 'white',
                                    borderRadius: '10px',
                                    height: '46px',
                                  },
                                }}
                                placeholder="Enter Address"
                                component={AutocompleteAddressInput}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Field
                                component={WebInputSelectCountry}
                                list={currencyList}
                                currentVal={currentCurrency}
                                setCurrentCountry={setCurrentCurrency}
                                label={'Currency'}
                                name="currencyD"
                                styleCustom={{
                                  height: '46px',
                                }}
                              />
                            </Grid>

                            <Grid xs={12} sm={6} item>
                              <Field
                                InputProps={{
                                  style: {
                                    backgroundColor: 'white',
                                    borderRadius: '10px',
                                    height: '46px',
                                  },
                                }}
                                name="province"
                                label="Province"
                                placeholder="Province"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                component={CustomerModalInputText}
                              />
                            </Grid>
                            <Grid xs={12} sm={6} item>
                              <Field
                                InputProps={{
                                  style: {
                                    backgroundColor: 'white',
                                    borderRadius: '10px',
                                    height: '46px',
                                  },
                                }}
                                name="city"
                                label="City"
                                placeholder="City"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                component={CustomerModalInputText}
                              />
                            </Grid>

                            <Grid xs={12} sm={6} item>
                              <Field
                                InputProps={{
                                  style: {
                                    backgroundColor: 'white',
                                    borderRadius: '10px',
                                    height: '46px',
                                  },
                                }}
                                name="zip"
                                label="Zip"
                                placeholder="A1A 1A1"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                component={CustomerModalInputText}
                              />
                            </Grid>

                            <Grid xs={12} sm={12} item>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  alignItems: 'center',
                                  gap: '20px',
                                }}
                              >
                                <Button
                                  variant="outline"
                                  onClick={resetForm}
                                  sx={{
                                    color: '#AC9EC3',
                                    textDecoration: 'underline',
                                    fontWeight: 500,
                                    minWidth: 60,
                                  }}
                                >
                                  Reset
                                </Button>

                                <Button
                                  variant="filled"
                                  type="button"
                                  onClick={() => props.submitForm()}
                                >
                                  Save
                                </Button>
                              </Box>
                            </Grid>

                            <Grid xs={12} sm={12} item>
                              <Box
                                sx={{
                                  height: '1px',
                                  width: '100%',
                                  backgroundColor: '#F4F4F4',
                                  marginTop: 1,
                                  marginBottom: 2,
                                }}
                              ></Box>
                            </Grid>

                            {taxesList.map((taxes, index) => (
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  gap: '20px',
                                  marginBottom: '5px',
                                  width: '98%',
                                  marginLeft: '15px',
                                }}
                                key={`tax-item-${index}`} // Add key here for unique identification
                              >
                                <Grid
                                  xs={12}
                                  sm={6}
                                  item
                                >
                                  <input
                                    InputProps={{
                                      style: {
                                        backgroundColor: 'white',
                                        borderRadius: '10px',
                                        height: '46px',
                                      },
                                    }}
                                    name={`taxname-${index}`}
                                    label="Zip"
                                    value={taxes.name}
                                    type="Tax Name"
                                    className="form-control"
                                    placeholder="Enter Tax Name"
                                    onChange={(e) =>
                                      handleInputChange(index, 'name', e.target.value)
                                    }
                                    component={CustomerModalInputText}
                                  />
                                </Grid>

                                <Grid
                                  xs={12}
                                  sm={6}
                                  item
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '10px',
                                  }}
                                >
                                  <input
                                    InputProps={{
                                      style: {
                                        backgroundColor: 'white',
                                        borderRadius: '10px',
                                        height: '46px',
                                      },
                                    }}
                                    className="form-control"
                                    name={`taxvalue-${index}`}
                                    label="Tax Value"
                                    placeholder="Enter Tax Value"
                                    value={taxes.value}
                                    onChange={(e) =>
                                      handleInputChange(index, 'value', e.target.value)
                                    }
                                    component={CustomerModalInputText}
                                  />

                                  <IconButton
                                    sx={{
                                      width: '50px',
                                    }}
                                    onClick={() => handleRemoveTax(index)} // Call the handler with the index
                                  >
                                    <HighlightOffIcon />
                                  </IconButton>
                                </Grid>
                              </Box>
                            ))}

                            <Grid xs={12} sm={12} item>
                              <Link
                                href="javascript:void(0)"
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  gap: '6px',
                                  alignItems: 'center',
                                  color: '#AC9EC3',
                                  fontWeight: '500',
                                  transition: 'all ease 0.3s',

                                  '&:hover': {
                                    color: '#f1645b',
                                  },
                                }}
                                onClick={addNewTaxes}
                              >
                                <TaxIcon />
                                Add New Tax Field
                              </Link>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          gap: '20px',
                        }}
                      >
                        <Button
                          variant="filled"
                          type="button"
                          onClick={saveTaxes}
                        >
                          Save
                        </Button>
                      </Box>
                      <Grid xs={12} sm={12} item>
                        <Box
                          sx={{
                            height: '1px',
                            width: '100%',
                            backgroundColor: '#F4F4F4',
                            marginTop: 1,
                            marginBottom: 2,
                          }}
                        ></Box>
                      </Grid>

                      <Typography variant="h5" fontWeight="500">
                        Upload Logo
                      </Typography>

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          gap: '20px',
                          marginTop: '-10px',
                        }}
                      >
                        {/* <img
                          src="https://static.vecteezy.com/system/resources/previews/018/250/342/non_2x/sharing-is-caring-text-illustration-letter-expression-for-logo-poster-or-graphic-design-element-format-png.png"
                          alt="Logo"
                          width="70"
                          height="70"
                          style={{
                            objectFit: 'contain',
                          }}
                        /> */}

                        <input
                          type="file"
                          name="Logo"
                          className="form-control"
                          accept="image/*" // Only accept image files
                          onChange={handleFileChange}
                        />

                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            gap: '20px',
                          }}
                        >
                          <Button
                            variant="filled"
                            type="button"
                            onClick={saveLogo}
                          >
                            Upload Logo
                          </Button>
                        </Box>
                      </Box>


                      <Grid xs={12} sm={12} item>
                        <Box
                          sx={{
                            height: '1px',
                            width: '100%',
                            backgroundColor: '#F4F4F4',
                            marginTop: 1,
                            marginBottom: 2,
                          }}
                        ></Box>
                      </Grid>

                      <Typography variant="h5" fontWeight="500">
                       Standard Route Charges
                      </Typography>

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          gap: '20px',
                          marginTop: '-10px',
                        }}
                      >

                        <input
                          type="number"
                          name="charge"
                          className="form-control"
                          value={standardCharge}
                          onChange={handleStandardChargeChange}
                          />

                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            gap: '20px',
                          }}
                        >
                          <Button
                            variant="filled"
                            type="button"
                            onClick={saveStandardCharge}
                          >
                            Save
                          </Button>
                        </Box>
                      </Box>

                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        )}
      </Box>
    </>
  );
};

export default RouteAddForm;
