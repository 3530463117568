import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Modal,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import CustomizationLandingPageLayout from '../serviceLandingPage/pages/CustomizationLandingPage/components/CustomizationLandingPageLayout';
import './style.css';
import { ReactComponent as HouseIcon } from './assets/House.svg';
import { ReactComponent as ArrowRightIcon } from './assets/arrowright.svg';
import { ReactComponent as CrossIcon } from './assets/cross.svg';
import Tiffin_Img from './assets/tiffin.jpg';
import CartIcon from './assets/emptycart.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { TENANT_URL } from '../../../config';

import AdditionalMenuModal from './additionalitems';

import { useParams, useNavigate } from 'react-router-dom';

import { ServiceFooter } from '../serviceLandingPage/servicefooter';
import { ServiceNavbar } from '../serviceLandingPage/serviceNarbar';
import { CarrotIcon } from 'lucide-react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CalendarIcon from '../../../admin/pages/reports/components/calenderIcon/calendarIcon';

const Checkout = () => {
  const [selectedValue, setSelectedValue] = useState('Jan 01, 2024'); // Default value
  const [cartID, setCartID] = useState('');
  const [deliveryMethod, setDeliveryMethod] = useState('');
  const [coupan, setCoupan] = useState('');
  const [alreadySetCoupan, setAlreadySetCoupan] = useState('');
  const [coupanError, setCoupanError] = useState('');
  const [cartTaxesDetails, setCartTaxesDetails] = useState([]);
  const [termsCondition, setTermsCondition] = useState(false);
  const [termsConditionError, setTermsConditionError] = useState(false);

  const [restaurantTiming, setRestaurantTiming] = useState([]);

  const [cartDetails, setCartDetails] = useState({});

  const [openTermsModal, setOpenTermsModal] = useState(false);
  const handleTermsModalOpen = () => setOpenTermsModal(true);
  const handleTermsModalClose = () => setOpenTermsModal(false);
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD')); // Set default date as current date

  const [additinaolItems, setAdditinaolItems] = useState('');
  const [additinaolItemsTotals, setAdditinaolItemsTotals] = useState(0);

  console.log('date:', date);

  const [additionalOpen, setAdditionalOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Single loader for the page

  const handleAdditionalStatus = () => {
    getCartDetails();
    setAdditionalOpen(!additionalOpen);
  };
  const navigate = useNavigate();

  const getCartID = async () => {
    let cartID = localStorage.getItem('cartID');
    if (!!cartID) {
      setCartID(cartID);
      setDeliveryMethod(localStorage.getItem('deliveryMethod'));
    } else {
      try {
        // Fetch meal plan details
        const config = {
          method: 'post',
          url: `${TENANT_URL}/api/cart/create`,
        };
        const response = await axios(config);
        setCartID(response.data.cart_id);
        localStorage.setItem('cartID', response.data.cart_id);
        localStorage.setItem('deliveryMethod', 'home_delivery');
      } catch (error) {
        setError('Failed to load meal plan details.');
      }
    }
  };
  const getCartDetails = async () => {
    setLoading(true); // Show loader
    try {
      console.log('CartID:', cartID);
      // Fetch meal plan details
      const config = {
        method: 'get',
        url: `${TENANT_URL}/api/cart?cart_id=${cartID}&preferred_delivery_method=${deliveryMethod}`,
      };
      const response = await axios(config);
      setAlreadySetCoupan(response.data.cart.voucher_code);
      setCartDetails(response.data);
      getCartTaxes();

      setAdditinaolItems(localStorage.getItem('additinoalitems') || '');
      setAdditinaolItemsTotals(
        localStorage.getItem('additinoalitemTotal') || 0
      );
    } catch (error) {
      setError('Failed to load meal plan details.');
    } finally {
      setLoading(false); // Hide loader
    }
  };

  const getCartTaxes = async () => {
    try {
      const config = {
        method: 'get',
        url: `${TENANT_URL}/api/cart/taxes?cart_id=${cartID}&preferred_delivery_method=${deliveryMethod}`,
      };
      const response = await axios(config);
      setCartTaxesDetails(response.data.taxes);
    } catch (error) {
      setError('Failed to load meal plan details.');
    }
  };

  const getRestaurantTiming = async () => {
    try {
      const config = {
        method: 'get',
        url: `${TENANT_URL}/api/restaurant-timing`,
      };
      const response = await axios(config);
      setRestaurantTiming(response.data.data);
    } catch (error) {
      setError('Failed to load meal plan details.');
    }
  };

  const processToCheckout = () => {
    if (termsCondition) {
      setTermsConditionError(false);
      navigate('/checkout');
    } else {
      setTermsConditionError(true);
    }
  };
  useEffect(() => {
    document.body.classList.add('customPage');
    return () => {
      document.body.classList.remove('customPage');
    };
  }, []);
  useEffect(() => {
    getCartID();
    getRestaurantTiming();
  }, []);

  useEffect(() => {
    getCartDetails();
  }, [cartID, deliveryMethod]);

  const options = [
    { value: 'Jan 01, 2024', label: 'January 1, 2024' },
    { value: 'Feb 01, 2024', label: 'February 1, 2024' },
    { value: 'Mar 01, 2024', label: 'March 1, 2024' },
  ];

  const handleChange = (event) => {
    setSelectedValue(event.target.value); // Update state on change
  };

  const handleCoupan = async () => {
    setLoading(true);
    setCoupanError('');
    if (!coupan) {
      setCoupanError('Enter coupan code');
      setLoading(false);
    }
    if (!!coupan) {
      try {
        var data = JSON.stringify({
          cart_id: cartID,
          voucher_code: coupan,
        });
        var addConfig = {
          method: 'post',
          url: `${TENANT_URL}/api/cart/apply-voucher`,
          headers: {
            'Content-Type': 'application/json',
          },
        };

        const addResponse = await axios({ ...addConfig, data: data });
        setCoupan('');
        getCartDetails();
      } catch (error) {
        setCoupanError('The selected voucher code is invalid.');
      } finally {
        setLoading(false); // Hide loader
      }
    }
  };

  const removeCart = async () => {
    try {
      var addConfig = {
        method: 'delete',
        url: `${TENANT_URL}/api/cart/delete/${cartID}`,
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const addResponse = await axios({ ...addConfig });
      window.location.reload();
    } catch (error) {
      setCoupanError('The selected voucher code is invalid.');
    }
  };
  const removeCoupan = async () => {
    setCoupanError('');
    try {
      var data = JSON.stringify({
        cart_id: cartID,
      });
      var addConfig = {
        method: 'post',
        url: `${TENANT_URL}/api/cart/remove-voucher`,
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const addResponse = await axios({ ...addConfig, data: data });
      setCoupan('');
      getCartDetails();
    } catch (error) {
      setCoupanError('The selected voucher code is invalid.');
    }
  };

  // const handleDeliveryChange = (event) => {
  //   localStorage.setItem('deliveryMethod', event.target.value);
  //   setDeliveryMethod(event.target.value);
  //   // console.log('Selected Value:', event.target.value); // Handle the value here
  // };

  const handleDeliveryChange = async (event) => {
    setLoading(true); // Show loader
    const selectedMethod = event.target.value;
    localStorage.setItem('deliveryMethod', selectedMethod);
    setDeliveryMethod(selectedMethod);

    try {
      await getCartDetails(); // Refresh cart details after delivery method change
    } catch (error) {
      console.error('Failed to update delivery method:', error);
    } finally {
      setLoading(false); // Hide loader
    }
  };

  console.log('cartTaxesDetails:', restaurantTiming);
  return (
    <>
      {loading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      <ServiceNavbar />
      <CustomizationLandingPageLayout>
        <div className="checkout-page">
          {/* BreadCrumbs */}
          <Grid
            container
            sx={{
              padding: '1em 5em',
              backgroundColor: '#F0645B',
              '@media (max-width:1199px)': {
                padding: '1em 1em',
              },
            }}
          >
            <Grid item md={12}>
              <Box
                display={'flex'}
                flex={1}
                flexDirection={'row'}
                alignItems={'center'}
                gap={1}
              >
                <HouseIcon onClick={() => navigate('/')} />
                <Typography
                  variant="h4"
                  fontFamily={'Inter !important'}
                  fontSize={15}
                  color={'#ffffff'}
                  fontWeight={'bold'}
                  onClick={() => navigate('/')}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  Home
                </Typography>
                <ArrowRightIcon />
                <Typography
                  variant="h4"
                  fontFamily={'Inter !important'}
                  fontSize={15}
                  color={'#ffffff'}
                  fontWeight={'bold'}
                >
                  Shopping Cart
                </Typography>
              </Box>
            </Grid>
          </Grid>

          {!!cartDetails?.items?.length && cartDetails.items.length > 0 ? (
            <Grid
              container
              sx={{
                padding: '2em 5em',
                '@media (max-width:1199px)': {
                  padding: '2em 1em',
                },
              }}
              marginBottom={7}
            >
              <Grid item md={12}>
                <Box marginBottom={4}>
                  <Typography
                    variant="h4"
                    fontFamily={'Inter !important'}
                    fontSize={25}
                    color={'#000000'}
                  >
                    Review Your Order (1 Items)
                  </Typography>
                </Box>
              </Grid>

              <Grid container spacing={2}>
                <Grid item md={8} xs={12}>
                  <Box className="cart_left">
                    <Box
                      display={'flex'}
                      flex={1}
                      flexDirection={'row'}
                      alignItems={'center'}
                      gap={1}
                      padding={2}
                    >
                      <Typography
                        variant="h4"
                        fontFamily={'Inter !important'}
                        fontSize={18}
                        color={'#000000'}
                      >
                        Shopping Cart
                      </Typography>
                    </Box>
                    <TableContainer
                      component={Paper}
                      sx={{
                        boxShadow: 'none',
                      }}
                    >
                      <Table
                        sx={{
                          '@media (max-width:767px)': {
                            minWidth: 700,
                            overflowX: 'auto',
                          },
                        }}
                      >
                        <TableHead
                          sx={{
                            backgroundColor: '#F2F4F5',
                            textTransform: 'uppercase',
                          }}
                        >
                          <TableRow>
                            <TableCell
                              sx={{
                                color: '#475156',
                                fontSize: '12px',
                              }}
                            >
                              Items
                            </TableCell>
                            <TableCell
                              sx={{
                                color: '#475156',
                                fontSize: '12px',
                              }}
                            >
                              Addon
                            </TableCell>
                            <TableCell
                              sx={{
                                color: '#475156',
                                fontSize: '12px',
                              }}
                            >
                              Price
                            </TableCell>
                            <TableCell
                              sx={{
                                color: '#475156',
                                fontSize: '12px',
                              }}
                            >
                              Sub-Total
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        {cartDetails.cart ? (
                          <>
                            {cartDetails.cart.items.map((element, index) => (
                              <TableBody
                                sx={{
                                  verticalAlign: 'top',
                                }}
                              >
                                <TableCell style={{ width: '450px' }}>
                                  <Box
                                    display={'flex'}
                                    flexDirection={'row'}
                                    alignItems={'flex-start'}
                                    gap="6px"
                                  >
                                    <IconButton
                                      sx={{
                                        width: '40px',
                                        height: '40px',
                                        top: '18px',
                                        position: 'relative',
                                      }}
                                    >
                                      <CrossIcon onClick={removeCart} />
                                    </IconButton>
                                    <Box>
                                      <Box
                                        display={'flex'}
                                        flexDirection={'row'}
                                        alignItems={'center'}
                                        gap="6px"
                                        marginBottom={3}
                                      >
                                        <img
                                          src={element.menu_plan.image_url}
                                          alt={element.menu_plan.name}
                                          className="tImg"
                                        />
                                        <Box>
                                          <Typography
                                            variant="h1"
                                            fontSize={15}
                                            color={'#000000'}
                                            fontWeight={'bold'}
                                            marginBottom={'2px'}
                                          >
                                            {element.menu_plan.name}
                                          </Typography>
                                          <Typography
                                            variant="h1"
                                            fontSize={12}
                                            color={'#000000'}
                                            fontWeight={'400'}
                                            lineHeight={'18px'}
                                          >
                                            {element.menu_plan.description}
                                          </Typography>
                                        </Box>
                                      </Box>
                                      {!!additinaolItems && (
                                        <Box
                                          display={'flex'}
                                          flexDirection={'row'}
                                          alignItems={'center'}
                                          gap="6px"
                                          marginBottom={3}
                                        >
                                          <Box>
                                            <Typography
                                              variant="h1"
                                              fontSize={15}
                                              color={'#000000'}
                                              fontWeight={'bold'}
                                              marginBottom={'2px'}
                                            >
                                              Additional Items
                                            </Typography>
                                            <Typography
                                              variant="h1"
                                              fontSize={12}
                                              color={'#000000'}
                                              fontWeight={'400'}
                                              lineHeight={'18px'}
                                            >
                                              {additinaolItems}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      )}
                                      <Box
                                        style={{
                                          width: '100%',
                                          marginBottom: '10px',
                                        }}
                                      >
                                        <Button
                                          variant="outlined"
                                          sx={{
                                            textTransform: 'uppercase',
                                            fontWeight: 'bold',
                                          }}
                                          onClick={handleAdditionalStatus}
                                        >
                                          Add Additional Items
                                        </Button>
                                      </Box>

                                      <Box
                                        display={'flex'}
                                        flexDirection={'row'}
                                        alignItems={'center'}
                                        gap="6px"
                                      >
                                        <Typography
                                          variant="h1"
                                          fontSize={11}
                                          color={'#191C1F'}
                                          fontWeight={'400'}
                                        >
                                          Subscribed For:
                                        </Typography>
                                        <Box
                                          className="daysborder"
                                          display={'flex'}
                                          flexDirection={'row'}
                                          alignItems={'center'}
                                          justifyContent={'space-between'}
                                          gap="6px"
                                          flex={1}
                                        >
                                          <Typography
                                            variant="h1"
                                            fontSize={12}
                                            color={'#0D192F'}
                                            fontWeight={'600'}
                                            fontFamily={'Inter !important'}
                                            style={{
                                              padding: '8px',
                                              color: restaurantTiming[0]?.monday
                                                ? 'green'
                                                : '',
                                            }}
                                          >
                                            M
                                          </Typography>
                                          <Typography
                                            variant="h1"
                                            fontSize={12}
                                            color={'#0D192F'}
                                            fontWeight={'600'}
                                            fontFamily={'Inter !important'}
                                            style={{
                                              padding: '8px',
                                              color: restaurantTiming[0]
                                                ?.tuesday
                                                ? 'green'
                                                : '',
                                            }}
                                          >
                                            T
                                          </Typography>
                                          <Typography
                                            variant="h1"
                                            fontSize={12}
                                            color={'#0D192F'}
                                            fontWeight={'600'}
                                            fontFamily={'Inter !important'}
                                            style={{
                                              padding: '8px',
                                              color: restaurantTiming[0]
                                                ?.wednesday
                                                ? 'green'
                                                : '',
                                            }}
                                          >
                                            W
                                          </Typography>
                                          <Typography
                                            variant="h1"
                                            fontSize={12}
                                            color={'#0D192F'}
                                            fontWeight={'600'}
                                            fontFamily={'Inter !important'}
                                            style={{
                                              padding: '8px',
                                              color: restaurantTiming[0]
                                                ?.thursday
                                                ? 'green'
                                                : '',
                                            }}
                                          >
                                            T
                                          </Typography>
                                          <Typography
                                            variant="h1"
                                            fontSize={12}
                                            color={'#0D192F'}
                                            fontWeight={'600'}
                                            fontFamily={'Inter !important'}
                                            style={{
                                              padding: '8px',
                                              color: restaurantTiming[0]?.friday
                                                ? 'green'
                                                : '',
                                            }}
                                          >
                                            F
                                          </Typography>
                                          <Typography
                                            variant="h1"
                                            fontSize={12}
                                            color={'#0D192F'}
                                            fontWeight={'600'}
                                            fontFamily={'Inter !important'}
                                            style={{
                                              padding: '8px',
                                              color: restaurantTiming[0]
                                                ?.saturday
                                                ? 'green'
                                                : '',
                                            }}
                                          >
                                            S
                                          </Typography>
                                          <Typography
                                            variant="h1"
                                            fontSize={12}
                                            color={'#0D192F'}
                                            fontWeight={'600'}
                                            fontFamily={'Inter !important'}
                                            style={{
                                              padding: '8px',
                                              color: restaurantTiming[0]?.sunday
                                                ? 'green'
                                                : '',
                                            }}
                                          >
                                            S
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display={'flex'}
                                        flexDirection={'row'}
                                        alignItems={'center'}
                                        gap="6px"
                                      >
                                        <Typography
                                          variant="h1"
                                          fontSize={11}
                                          color={'#191C1F'}
                                          fontWeight={'400'}
                                        >
                                          Order for:
                                        </Typography>
                                        <RadioGroup
                                          row
                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                          name="row-radio-buttons-group"
                                        >
                                          <FormControlLabel
                                            value="female"
                                            control={<Radio />}
                                            label="Breakfast"
                                            color="#2DB224"
                                            style={{
                                              fontFamily: 'Inter !important',
                                              fontSize: '0.8rem',
                                            }}
                                            sx={{
                                              '& .Mui-checked': {
                                                color: '#2DB224 !important',
                                                '&:hover': {
                                                  color: '#2DB224 !important',
                                                },
                                              },
                                            }}
                                          />
                                          <FormControlLabel
                                            value="male"
                                            control={<Radio />}
                                            label="Lunch"
                                            style={{
                                              fontFamily: 'Inter !important',
                                              fontSize: '0.8rem',
                                            }}
                                            color="#2DB224"
                                            sx={{
                                              '& .Mui-checked': {
                                                color: '#2DB224 !important',
                                                '&:hover': {
                                                  color: '#2DB224 !important',
                                                },
                                              },
                                            }}
                                          />
                                          <FormControlLabel
                                            value="dinner"
                                            control={<Radio />}
                                            label="Dinner"
                                            style={{
                                              fontFamily: 'Inter !important',
                                              fontSize: '0.8rem',
                                            }}
                                            color="#2DB224"
                                            sx={{
                                              '& .Mui-checked': {
                                                color: '#2DB224 !important',
                                                '&:hover': {
                                                  color: '#2DB224 !important',
                                                },
                                              },
                                            }}
                                          />
                                        </RadioGroup>
                                      </Box>
                                      <Box
                                        display={'flex'}
                                        flexDirection={'row'}
                                        alignItems={'center'}
                                        gap="6px"
                                        sx={{
                                          marginY: '12px',
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            minWidth: '53px',
                                          }}
                                        >
                                          <Typography
                                            variant="h1"
                                            fontSize={11}
                                            color={'#191C1F'}
                                            fontWeight={'400'}
                                          >
                                            Start Date:
                                          </Typography>
                                        </Box>
                                        <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                        >
                                          <DemoContainer
                                            components={['DatePicker']}
                                            sx={{ marginTop: '0px' }}
                                          >
                                            <DatePicker
                                              disablePast={true}
                                              value={dayjs(date)} // Convert string back to dayjs object
                                              slotProps={{
                                                textField: { size: 'small' },
                                              }}
                                              // onChange={(e)=>setDate(e.target.value)}
                                              onChange={(newDate) => {
                                                if (newDate) {
                                                  const formattedDate =
                                                    newDate.format(
                                                      'YYYY-MM-DD'
                                                    ); // Format selected date
                                                  setDate(formattedDate); // Update state
                                                  console.log(formattedDate); // Log formatted date
                                                }
                                              }}
                                              sx={{
                                                background: '#fff',
                                                borderRadius: '10px',
                                                paddingTop: 0,
                                                marginTop: 0,
                                                width: '100%',

                                                '& .MuiOutlinedInput-root': {
                                                  borderRadius: '10px',
                                                  background: '#fff',
                                                  marginTop: 0,
                                                  width: '100%',
                                                  paddingTop: 0,
                                                },
                                                '& .MuiStack-root': {
                                                  marginTop: 0,
                                                  paddingTop: 0,
                                                },
                                              }}
                                              slots={{
                                                openPickerIcon: CalendarIcon,
                                              }}
                                            />
                                          </DemoContainer>
                                        </LocalizationProvider>
                                      </Box>
                                      <Box
                                        display={'flex'}
                                        flexDirection={'row'}
                                        alignItems={'center'}
                                        gap="6px"
                                      >
                                        <Typography
                                          variant="h1"
                                          fontSize={11}
                                          color={'#191C1F'}
                                          fontWeight={'400'}
                                        >
                                          Meal for:
                                        </Typography>
                                        <RadioGroup
                                          row
                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                          name="row-radio-buttons-group"
                                          value={deliveryMethod} // Controlled value
                                          onChange={handleDeliveryChange} // onChange handler
                                        >
                                          <FormControlLabel
                                            value="home_delivery"
                                            control={<Radio />}
                                            label="Delivery"
                                            color="#2DB224"
                                            style={{
                                              fontFamily: 'Inter !important',
                                              fontSize: '0.8rem',
                                            }}
                                            sx={{
                                              '& .Mui-checked': {
                                                color: '#2DB224 !important',
                                                '&:hover': {
                                                  color: '#2DB224 !important',
                                                },
                                              },
                                            }}
                                          />
                                          <FormControlLabel
                                            value="self_pickup"
                                            control={<Radio />}
                                            label="Pickup"
                                            style={{
                                              fontFamily: 'Inter !important',
                                              fontSize: '0.8rem',
                                            }}
                                            color="#2DB224"
                                            sx={{
                                              '& .Mui-checked': {
                                                color: '#2DB224 !important',
                                                '&:hover': {
                                                  color: '#2DB224 !important',
                                                },
                                              },
                                            }}
                                          />
                                        </RadioGroup>
                                      </Box>
                                    </Box>
                                  </Box>
                                </TableCell>
                                <TableCell color="#4F4D55">
                                  {element.menu_plan.addons_string_format}
                                </TableCell>
                                <TableCell>
                                  {/* <span className="cutText">$99</span>{' '} */}
                                  {element.menu_plan.plan_price}
                                </TableCell>
                                <TableCell>
                                  ${element.menu_plan.plan_price}
                                </TableCell>
                              </TableBody>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                      </Table>
                    </TableContainer>
                    <Box padding={2}>
                      <Button variant="outlined" onClick={() => navigate('/')}>
                        Return To Shopping
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box
                    className="cart_left cart_left1"
                    marginBottom={2}
                    padding={2}
                  >
                    <Typography
                      variant="h4"
                      fontFamily={'Inter !important'}
                      fontSize={17}
                      color={'#000000'}
                      marginBottom={3}
                    >
                      Cart Totals
                    </Typography>

                    <Box
                      flex={1}
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      marginBottom={3}
                    >
                      <Typography
                        variant="h4"
                        fontFamily={'Inter !important'}
                        fontSize={14}
                        color={'#5F6C72'}
                      >
                        Sub-total
                      </Typography>
                      <Typography
                        variant="h4"
                        fontFamily={'Inter !important'}
                        fontSize={14}
                        color={'#000000'}
                      >
                        ${cartDetails.total_price}
                      </Typography>
                    </Box>

                    {cartTaxesDetails.map((element, index) => (
                      <Box
                        key={index}
                        flex={1}
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        marginBottom={3}
                      >
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#5F6C72'}
                        >
                          {element.name}
                        </Typography>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                        >
                          {element.type == 'fixed' ? (
                            <>
                              {element.value == '0' ? (
                                'Free'
                              ) : (
                                <>${element.value} </>
                              )}
                            </>
                          ) : (
                            <>{element.value}%</>
                          )}
                          {/* {element.type == "percentage" ? "" : "$"}{element.value}{element.type == "percentage" ? "%" : ""} */}
                        </Typography>
                      </Box>
                    ))}
                    {cartDetails.total_discount > 0 && (
                      <Box
                        flex={1}
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        marginBottom={3}
                      >
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#5F6C72'}
                        >
                          Discount
                        </Typography>
                        <Typography
                          variant="h4"
                          fontFamily={'Inter !important'}
                          fontSize={14}
                          color={'#000000'}
                        >
                          ${cartDetails.total_discount}
                        </Typography>
                      </Box>
                    )}
                    {!!additinaolItems ? (
                      <>
                        <Box
                          flex={1}
                          display={'flex'}
                          flexDirection={'row'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                          marginBottom={3}
                        >
                          <Typography
                            variant="h4"
                            fontFamily={'Inter !important'}
                            fontSize={14}
                            color={'#5F6C72'}
                          >
                            Additional Items
                          </Typography>
                          <Typography
                            variant="h4"
                            fontFamily={'Inter !important'}
                            fontSize={14}
                            color={'#000000'}
                          >
                            ${additinaolItemsTotals}
                          </Typography>
                        </Box>
                      </>
                    ) : (
                      <></>
                    )}

                    <Divider
                      sx={{
                        backgroundColor: '#E4E7E9',
                        marginBottom: '15px',
                      }}
                    />

                    <Box
                      flex={1}
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      marginBottom={3}
                    >
                      <Typography
                        variant="h4"
                        fontFamily={'Inter !important'}
                        fontSize={16}
                        color={'#000000'}
                      >
                        Total
                      </Typography>
                      <Typography
                        variant="h4"
                        fontFamily={'Inter !important'}
                        fontSize={16}
                        color={'#000000'}
                        fontWeight={'bold'}
                      >
                        {/* ${(parseFloat(cartDetails.final_amount) + parseFloat(additinaolItemsTotals))} */}
                        $
                        {(
                          parseFloat(cartDetails.final_amount) +
                          parseFloat(additinaolItemsTotals)
                        ).toFixed(2)}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', marginBottom: '10px' }}>
                      <input
                        type="checkbox"
                        name="agree"
                        checked={termsCondition}
                        onChange={() => setTermsCondition(!termsCondition)}
                      />
                      <p style={{ marginLeft: '10px' }}>
                        I agree{' '}
                        <a
                          style={{
                            cursor: 'pointer',
                            fontWeight: 'bolder',
                            textDecoration: 'underline',
                          }}
                          onClick={handleTermsModalOpen}
                        >
                          terms & conditions.
                        </a>
                      </p>
                      {/* <p
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={() => setTermsCondition(!termsCondition)}
                      >
                        I agree terms & conditions.
                      </p> */}
                    </Box>
                    {termsConditionError ? (
                      <Box sx={{ display: 'flex', marginBottom: '10px' }}>
                        <p
                          style={{
                            marginLeft: '10px',
                            fontSize: '12px',
                            color: 'red',
                          }}
                        >
                          Please agree terms and condition.
                        </p>
                      </Box>
                    ) : (
                      <></>
                    )}

                    <Button
                      variant="contained"
                      sx={{
                        width: '100%',
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                      }}
                      onClick={processToCheckout}
                    >
                      Proceed to Checkout
                    </Button>
                  </Box>

                  <Box className="cart_left">
                    <Box
                      padding={2}
                      sx={{
                        borderBottom: '1px solid #E4E7E9',
                      }}
                    >
                      <Typography
                        variant="h4"
                        fontFamily={'Inter !important'}
                        fontSize={17}
                        color={'#000000'}
                      >
                        Coupon Code
                      </Typography>
                    </Box>
                    <Box padding={2}>
                      <Box
                        sx={{
                          width: '100%',
                          display: 'block',
                        }}
                      >
                        <TextField
                          id="outlined-basic"
                          label="Enter Code"
                          variant="outlined"
                          value={coupan}
                          disabled={!!alreadySetCoupan ? true : false}
                          onChange={(e) => setCoupan(e.target.value)}
                          sx={{
                            width: '100%',
                            display: 'block',
                            marginBottom: '13px',
                            '& .MuiInputBase-formControl': {
                              width: '100%',
                            },
                          }}
                        />
                      </Box>
                      {!!alreadySetCoupan ? (
                        <Box
                          sx={{
                            backgroundColor: '#FF7676',
                            textTransform: 'uppercase',
                            fontWeight: '700',
                            width: '180px',
                            display: 'flex',
                            padding: '5px',
                            marginBottom: '5px',
                            borderRadius: '30px',
                            paddingLeft: '15px',
                            color: 'white',
                          }}
                        >
                          {alreadySetCoupan}{' '}
                          <CrossIcon
                            style={{
                              color: 'white',
                              marginLeft: '15px',
                              cursor: 'pointer',
                            }}
                            onClick={removeCoupan}
                          />
                        </Box>
                      ) : (
                        <></>
                      )}
                      <p style={{ color: 'red' }}>{coupanError}</p>
                      <Button
                        variant="outlined"
                        sx={{
                          textTransform: 'uppercase',
                          fontWeight: 'bold',
                        }}
                        onClick={handleCoupan}
                      >
                        Apply Coupon
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              sx={{
                padding: '6em 5em',
                textAlign: 'center',
              }}
              marginBottom={7}
            >
              {/* Cart Empty */}
              <Grid container spacing={2} justifyContent={'center'}>
                <Grid item textAlign={'center'}>
                  <Box marginBottom={2}>
                    <img
                      src={CartIcon}
                      style={{
                        width: 60,
                        height: 60,
                        objectFit: 'contain',
                      }}
                      alt=""
                    />
                  </Box>
                  <Typography
                    variant="h4"
                    fontFamily={'Inter !important'}
                    fontSize={18}
                    color={'#000000'}
                    fontWeight={'700'}
                    marginBottom={3}
                  >
                    Your cart is empty.
                  </Typography>
                  <Button variant="contained" onClick={() => navigate('/')}>
                    Continue Shopping
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </div>
        {/* Terms and Conditions Modal */}
        <Modal open={openTermsModal} onClose={handleTermsModalClose}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 600,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              maxHeight: '80vh', // Limit the height of the modal
              overflow: 'auto', // Enable scroll if content overflows
            }}
          >
            <IconButton
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
              }}
              onClick={handleTermsModalClose}
            >
              <CloseIcon />
            </IconButton>

            <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
              Terms and Conditions
            </Typography>

            <div style={{ maxHeight: 'calc(80vh - 60px)', overflowY: 'auto' }}>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>1. Introduction</strong>
                <br />
                Welcome to Mom’s Masala Rasoi Food Service LTD. We provide a
                meal prep service delivering fresh, home-style Indian dishes to
                students, families, and individuals in within British Columbia
                and Alberta, Canada. By accessing or using our website, you
                agree to be bound by these Terms and Conditions. If you disagree
                with any part of the terms, then you may not access the website.
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>2. Use of the Website</strong>
                <br />
                <ul>
                  <li>
                    <strong>a. Content:</strong> All website content, including
                    text, graphics, images, logos, and software, is the property
                    of Mom’s Masala Rasoi Food Service LTD and protected by
                    Canadian copyright laws.
                  </li>
                  <li>
                    <strong>b. Ordering:</strong> You can use our website to
                    browse our menu, select meal packs, and place orders. By
                    placing an order, you agree to provide accurate and complete
                    information and to pay the specified fees.
                  </li>
                  <li>
                    <strong>c. Account:</strong> You may need to create an
                    account to access certain features. You are responsible for
                    maintaining the confidentiality of your account information
                    and for all activities that occur under your account.
                  </li>
                  <li>
                    <strong>d. Prohibited Activities:</strong> You agree not to
                    use the website for any unlawful purpose or in any way that
                    could damage, disable, overburden, or impair the website or
                    interfere with any other party's use of the website.
                  </li>
                </ul>
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>3. Meal Prep Service</strong>
                <br />
                <ul>
                  <li>
                    <strong>a. Menu:</strong> We offer a variety of Indian
                    dishes, including rice items, rotis, and other
                    accompaniments. Our menu is subject to change without
                    notice.
                  </li>
                  <li>
                    <strong>b. Freshness:</strong> We prepare all meals fresh
                    daily using high-quality ingredients and adhering to strict
                    food safety standards in compliance with relevant health
                    regulations in British Columbia and Alberta.
                  </li>
                  <li>
                    <strong>c. Allergies and Dietary Restrictions:</strong>{' '}
                    While we take precautions to prevent cross-contamination, we
                    cannot guarantee that our meals are free of allergens.
                  </li>
                </ul>
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>4. Disclaimer</strong>
                <br />
                <ul>
                  <li>
                    <strong>a. Website Accuracy:</strong> We strive to provide
                    accurate information on our website, but we do not warrant
                    that the content is error-free, complete, or current.
                  </li>
                  <li>
                    <strong>b. Service Availability:</strong> We reserve the
                    right to modify or discontinue our meal prep service at any
                    time without notice.
                  </li>
                  <li>
                    <strong>c. Liability:</strong> Mom’s Masala Rasoi Food
                    Service LTD shall not be liable for any damages arising from
                    the use of our website or our meal prep service.
                  </li>
                </ul>
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>5. Changes to Terms and Conditions</strong>
                <br />
                We reserve the right to modify these Terms and Conditions at any
                time. Any changes will be effective immediately upon posting on
                the website.
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>6. Governing Law</strong>
                <br />
                These Terms and Conditions shall be governed by and construed in
                accordance with the laws of British Columbia and Alberta,
                Canada.
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>7. Dispute Resolution</strong>
                <br />
                Any dispute arising out of or relating to these Terms and
                Conditions shall be subject to the exclusive jurisdiction of the
                courts of British Columbia or Alberta, depending on the user's
                province of residence.
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>8. Contact Us</strong>
                <br />
                If you have any questions about these Terms and Conditions,
                please contact us.
              </Typography>
            </div>
          </Box>
        </Modal>

        <AdditionalMenuModal
          open={additionalOpen}
          handleClose={handleAdditionalStatus}
          isUpdated={false}
          date={date}
        />
      </CustomizationLandingPageLayout>
      <ServiceFooter />
    </>
  );
};

export default Checkout;
