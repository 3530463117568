import React, { useEffect, useState } from 'react';
import { Button, Typography, Grid, Container, Box } from '@mui/material';
import axios from 'axios';
import { TENANT_URL } from '../../../../../config';

const ContactUsForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name.trim()) errors.name = 'Full Name is required';
    if (!formData.email.trim()) {
      errors.email = 'Email Address is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email Address is invalid';
    }
    if (!formData.subject.trim()) errors.subject = 'Subject is required';
    if (!formData.message.trim()) errors.message = 'Query is required';

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const config = {
      method: 'post',
      url: `${TENANT_URL}/api/contact-us`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: formData,
    };

    try {
      const response = await axios(config);
      setSuccessMessage(
        response.data.message || 'Your query has been submitted successfully!'
      );
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: '',
      });
      setValidationErrors({});
    } catch (error) {
      console.error('Error submitting form:', error);
      setSuccessMessage('Something went wrong. Please try again later.');
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <Typography
          variant="h6"
          style={{
            fontSize: '19px',
            fontWeight: 700,
            letterSpacing: 2,
            textAlign: 'center',
            color: '#F96221',
            textTransform: 'uppercase',
          }}
          fontFamily={'Inter !important'}
          gutterBottom
        >
          Contact Us
        </Typography>

        <Typography
          variant="body1"
          fontFamily={'Inter !important'}
          style={{
            fontSize: '35px',
            fontWeight: 'bold',
            textAlign: 'center',
            color: '#000000',
          }}
          marginBottom={4}
          paragraph
        >
          Get in Touch With Us
        </Typography>

        <Box
          sx={{
            backgroundColor: '#ffffff',
            padding: '40px 5em',
            borderRadius: '30px',
            boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.06)',
            px: { sm: 4, md: 5, lg: 5, xs: 3 },
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <input
                className="form-control"
                name="name"
                value={formData.name}
                placeholder="Full Name"
                style={{
                  border: '1px solid #4A4A4A',
                  padding: '10px',
                  width: '100%',
                  borderRadius: '4px',
                  backgroundColor: '#ffffff',
                }}
                onChange={handleChange}
              />
              {validationErrors.name && (
                <Typography color="error" variant="body2">
                  {validationErrors.name}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <input
                className="form-control"
                name="email"
                value={formData.email}
                placeholder="Email Address"
                style={{
                  border: '1px solid #4A4A4A',
                  padding: '10px',
                  width: '100%',
                  borderRadius: '4px',
                  backgroundColor: '#ffffff',
                }}
                onChange={handleChange}
              />
              {validationErrors.email && (
                <Typography color="error" variant="body2">
                  {validationErrors.email}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <input
                className="form-control"
                name="subject"
                value={formData.subject}
                placeholder="Subject"
                style={{
                  border: '1px solid #4A4A4A',
                  padding: '10px',
                  width: '100%',
                  borderRadius: '4px',
                  backgroundColor: '#ffffff',
                }}
                onChange={handleChange}
              />
              {validationErrors.subject && (
                <Typography color="error" variant="body2">
                  {validationErrors.subject}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <textarea
                rows={5}
                className="form-control"
                name="message"
                value={formData.message}
                placeholder="Query"
                style={{
                  border: '1px solid #4A4A4A',
                  padding: '10px',
                  width: '100%',
                  borderRadius: '4px',
                  backgroundColor: '#ffffff',
                }}
                onChange={handleChange}
              />
              {validationErrors.message && (
                <Typography color="error" variant="body2">
                  {validationErrors.message}
                </Typography>
              )}
            </Grid>
          </Grid>
          {successMessage && (
            <Typography color="success" variant="body2" align="center" mt={2}>
              {successMessage}
            </Typography>
          )}
          <Box textAlign={'center'} mt={2}>
            <Button
              type="submit"
              variant="contained"
              sx={{
                maxWidth: '170px',
                height: '48px',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '22px',
                borderRadius: '100px',
                color: '#ffffff',
                bgcolor: '#F96221',
                textTransform: 'capitalize',
                ':hover': {
                  bgcolor: 'rgba(228, 186, 103, 1)',
                },
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </form>
    </Container>
  );
};

export default ContactUsForm;
