import React, { useEffect, useRef, useState } from 'react';
// import { UtilityProvider } from '../../useUtility';
import {
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import PlanHeader from './header';
import SmallCard from '../SmallCard';
import ExistingDateCard from '../DateCard/ExistingDateCard';
// import arrowLeft from './svg/arrow-left.svg';
// import arrowRight from './svg/arrow-right.svg';
import ListIcon from './ListIcon';
import editPencil from '../DateCard/svg/pencil-light.svg';
import { UploadFile, Edit } from '@mui/icons-material';
// import { debounce } from 'lodash';
import { EditCalendar, RestartAlt, BorderColor } from '@mui/icons-material';

import {
  calenderHeaderStyles,
  calenderWeekStyles,
  isDateInCurrentMonth,
  dayHeaderStyles,
  typographyStyles,
  saveButtonStyles,
  innerBoxStyles,
  planInfoStyles,
  getDatesForWeek,
  isDayOff,
  getDayData,
  start,
  getFormattedDate,
  labelStyles,
  selectStyles,
  listButtonStyles,
  cancelButtonStyles,
} from '../../styles/styles';
import { useFoodMenuPlan } from '../../../../customHooks/useMenuPlan';
import { Link, json } from 'react-router-dom';
import GridIcon from './GridIcon';
import ListView from './ListView/listView';
import Tooltip from '@mui/material/Tooltip';
// import lodash from 'lodash';
import styled from '@emotion/styled';
import Spinner from '../../../../../components/utils/spinner';
import MonthYearPicker from '../MonthYearPicker';
import dayjs from 'dayjs';
import ConfirmationModal from '../modals/confirmationModal/confirmationModal';
import FinalPriceModal from '../modals/finalPriceModal/finalPriceModal';
import { set } from 'date-fns';

function menuPlanList() {
  const {
    planList,
    setPlanList,
    restaurantDayOff,
    plan,
    setPlan,

    menuPlanObj,
    setMenuPlanObj,

    mealItems,
    adonsItem,
    UpdateMenuPlan,
    newMenuPlanObj,
    updateMenuPlanObj,
    setUpdateMenuPlanObj,
    initialUpdateMenuPlanObjs,
    setInitialUpdateMenuPlanObjs,
    getPlanList,
    meta,
    loading,
    selectedDate,
    setSelectedDate,
    planDropdownList,
    allMealItems,
    setAllMealItems,
    imageFile,
    handleFileChange,
    setUplatedPlanName,
    setUplatedPlanDescription,
  } = useFoodMenuPlan();

  const [planPriceFinal, setplanPriceFinal] = useState(null);
  const [originalprice, setOriginalprice] = useState(null);
  const [finalPrice, setfinalPrice] = useState(null);
  const [menuPlanId, setMenuPlanId] = useState(0);
  const [planName, setPlanName] = useState('');

  const [description, setDescription] = useState('');

  const [totalPlanCount, settotalPlanCount] = useState();
  const [listView, setListView] = useState(false);
  const [disabledUpdateBtn, setDisabledUpdateBtn] = useState(true);
  const [disabledResetBtn, setDisabledResetBtn] = useState(true);
  const [editMenuPlanName, setEditMenuPlanName] = useState(false);
  const [selectedPlanItem, setSelectedPlanItem] = useState({});
  const [selectedMonth, setSelectedMonth] = useState();
  const [displayMonth, setDisplayMonth] = useState(dayjs().format('MMM'));
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isUpdateMenuPlan, setIsUpdateMenuPlan] = useState(false);

  const [planNameNew, setPlanNameNew] = useState('');
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [isPreviousDisabled, setIsPreviousDisabled] = useState(true);
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const permissionsData = JSON.parse(
    localStorage.getItem('tenantdata')
  ).tenant_nav_bar_permission;
  const { tenant_permissions, genral_permissions, user_permission } =
    permissionsData;
  function getPermissionByModuleName(module_name) {
    return (
      user_permission.find(
        (permission) => permission.module_name === module_name
      ) || null
    );
  }
  const permissions = getPermissionByModuleName('is_menu_plan_tab');

  const MenuPlanNameTextField = styled(TextField)({
    '& .MuiInputBase-root': {
      background: 'transparent',
    },

    '& input': {
      // ...typographyStyles,
      fontSize: '18px',
      fontWeight: '500',
      maxWidth: '170px',
      height: '46px',
      boxSizing: 'border-box',
      padding: '12px 5px',
    },
    '& fieldset': {
      borderRadius: '10px',
      border: '1px solid #B0B0B0',
    },
  });
  const toggleView = () => {
    setListView(!listView);
  };
  useEffect(() => {
    if (editMenuPlanName && planName) {
      // document.getElementById('MenuPlanNameTextField').value = planName;
      // setPlanNameNew(planName);
    }
  }, [editMenuPlanName]);
  useEffect(() => {
    if (
      planList &&
      planList.length > 0 &&
      !planList.filter((a) => a.id == plan).length
    ) {
      const firstPlan = planList[0];
      setPlan(firstPlan.id);
      setMenuPlanId(firstPlan.id);
      setPlanName(firstPlan.name);
      settotalPlanCount(planList.length);
      setSelectedPlanItem(firstPlan);
      setIsNextDisabled(
        !planList ||
          planList.findIndex((item) => item.id === plan) === planList.length - 1
      );
      setIsPreviousDisabled(
        !planList || planList.findIndex((item) => item.id === plan) === 0
      );
    } else if (
      planList &&
      planList.length &&
      planList.filter((a) => a.id == plan).length
    ) {
      const selectedPlan = planList.find((p) => p.id == plan);

      setMenuPlanId(selectedPlan.id);
      setPlanName(selectedPlan.name);
      setDescription(selectedPlan.description);

      setUplatedPlanName(selectedPlan.name),
        setUplatedPlanDescription(selectedPlan.description),
        setPlanName(selectedPlan.name);
      setDescription(selectedPlan.description);

      settotalPlanCount(planList.length);
      // setEditMenuPlanName(false);
      setSelectedPlanItem(selectedPlan);
      setIsNextDisabled(
        !planList ||
          planList.findIndex((item) => item.id === plan) === planList.length - 1
      );
      setIsPreviousDisabled(
        !planList || planList.findIndex((item) => item.id === plan) === 0
      );
    } else {
      setPlan(0);
      setMenuPlanId(0);
      setPlanName('');
      settotalPlanCount(0);
      setSelectedPlanItem(null);
      setplanPriceFinal(0);
      setfinalPrice(null);
      setOriginalprice(null);
      setDisabledResetBtn(true);
      setDisabledUpdateBtn(true);
      setIsNextDisabled(true);
      setIsPreviousDisabled(true);
    }
  }, [planList, plan]);

  // console.log(planList);
  function objectsAreEqual(obj1, obj2) {
    if (obj1 && obj2) {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) {
        return false;
      }

      for (let key of keys1) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }

      return true;
    }
  }
  useEffect(() => {
    if (
      plan &&
      menuPlanObj &&
      menuPlanObj.length &&
      initialUpdateMenuPlanObjs &&
      initialUpdateMenuPlanObjs.length
    ) {
      var initiallyLoadedObj = initialUpdateMenuPlanObjs.find(
        (mPlan) => mPlan.id == plan
      );
      var menuPlanSelectedObj = menuPlanObj.find((mPlan) => mPlan.id == plan);

      var initialObjStrigified = JSON.stringify(initiallyLoadedObj);
      var menuObjStrigified = JSON.stringify(menuPlanSelectedObj);

      var areObjSame = objectsAreEqual(initialObjStrigified, menuObjStrigified);
      var isSameObj = areObjSame ? 'Yes' : 'No';

      if (!areObjSame && areObjSame != undefined) {
        setDisabledUpdateBtn(false);
        setDisabledResetBtn(false);
      } else {
        setDisabledUpdateBtn(true);
        setDisabledResetBtn(true);
      }
    }
  }, [initialUpdateMenuPlanObjs, updateMenuPlanObj, menuPlanId, menuPlanObj]);

  useEffect(() => {
    if (menuPlanObj && plan) {
      // debugger;
      const selectedPlan = menuPlanObj.find((p) => p.id === plan);
      if (selectedPlan) {
        let totalPrice = 0;
        let totalPriceWithoutAddons = 0;
        selectedPlan.monthly_plan.forEach((day) => {
          const mealItem = day.meal_id
            ? allMealItems?.find((mi) => mi.id === day.meal_id)
            : null;
          const mealPrice = mealItem ? mealItem.daily_price : 0;
          const addonsPrice = day.addons_ids.reduce((acc, addonId) => {
            const addon = adonsItem?.find((ai) => ai.id === addonId);
            return acc + (addon ? addon.price : 0);
          }, 0);
          totalPrice += mealPrice + addonsPrice;
          totalPriceWithoutAddons += mealPrice;
        });
        setOriginalprice(selectedPlan.plan_price);
        setplanPriceFinal(totalPrice);
        setUpdateMenuPlanObj(selectedPlan);
        setfinalPrice(null);
        setMenuPlanId(plan);
      }
    }
  }, [menuPlanObj, plan]);

  const handleChangePlan = (e) => {
    var selectedPlan = planList.filter((item) => item.id === e.target.value)[0]
      .name;

    setPlan(e.target.value);
    setPlanName(selectedPlan);
  };

  const handleChangePlanPrice = (e) => {
    setfinalPrice(e.target.value);
  };

  const handleUpdatePlan = () => {
    var menuPlanSelectedObj = menuPlanObj.find((mPlan) => mPlan.id == plan);
    console.log('pricingCheck', planPriceFinal, Number(finalPrice));
    let price = Number(finalPrice) === 0 ? planPriceFinal : Number(finalPrice);
    menuPlanSelectedObj.plan_price = price;
    UpdateMenuPlan(menuPlanSelectedObj);
  };

  const handleResetPlan = () => {
    setMenuPlanObj([]);
    setPlanList([]);
    setInitialUpdateMenuPlanObjs([]);
    var month = dayjs(selectedDate).month() + 1;
    var year = dayjs(selectedDate).year();
    if (selectedDate) getPlanList(month, year);
    else window.location.reload();
  };
  // const handleConfirmationModal=()=>{
  //   setIsConfirmationModalOpen(true)

  // }

  // var planPriceFinal = planPrice.reduce((acc, item) => {
  //   return acc + item.price;
  // }, 0);
  const renderWeek = (weekNumber, dates, planItem) => (
    <Box
      key={`week-${weekNumber}`}
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '20px',
        flex: '1',
      }}
    >
      <SmallCard sx={calenderWeekStyles}>
        <span>Week</span> <span>{weekNumber}</span>
      </SmallCard>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'space-evenly',
          gap: '8px',
        }}
      >
        {dates.map((date) => (
          <ExistingDateCard
            key={`week-${weekNumber}-date-${date.getDate()}`}
            date={date.getDate()}
            disabled={!isDateInCurrentMonth(date, selectedDate)}
            isDayOff={isDayOff(date, restaurantDayOff)}
            fullDate={date}
            dayData={getDayData(date, planItem && planItem.monthly_plan)}
            setPlan={setPlan}
            plan={plan}
          />
        ))}
      </Box>
    </Box>
  );
  const glassyBackgroud = {
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    border: '1px solid var(--Old-colors-Line, #F5F6F8)',
    background:
      'var(--Old-colors-Glass-Fill, linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.45) 100%))',
  };
  // const leftRightButtonIconStyle = {
  //   height: '18.33px',
  //   width: '11px',
  //   backgroundRepeat: 'no-repeat',
  // };
  // const handlePlanNameChange = debounce((e) => {
  //   if (e) {
  //     setPlanName(e.target.value);
  //   }
  // }, 300);
  // const handleNextPrevious = (direction) => {
  //   if (!planList || planList.length === 0) {
  //     return; // Exit if planList is null or empty
  //   }

  //   const currentIndex = planList.findIndex((item) => item.id === plan);
  //   let newIndex;

  //   if (direction === 'next') {
  //     newIndex =
  //       currentIndex < planList.length - 1 ? currentIndex + 1 : currentIndex;
  //   } else if (direction === 'previous') {
  //     newIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
  //   }

  //   if (newIndex !== currentIndex && newIndex >= 0) {
  //     const newPlan = planList[newIndex];
  //     setPlan(newPlan.id);
  //     setPlanName(newPlan.name);
  //   }
  // };

  console.log('planName:', planName);

  const BootstrapInput = {
    'label + &': {
      marginTop: '10px',
    },
    '& .MuiInputBase-input': {
      position: 'relative',
      backgroundColor: '#fff',
      fontSize: 16,
      minWidth: '180px',
      padding: '15px',
      height: '46px',
      boxSizing: 'border-box',
      borderRadius: '10px',
      border: '1px solid #B0B0B0',
      background: '#FFF',
      '&:focus': {},
    },
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </Box>
      ) : (
        <>
          {isConfirmationModalOpen && (
            <ConfirmationModal
              isOpen={isConfirmationModalOpen}
              setIsOpen={setIsConfirmationModalOpen}
              title={'Are you sure?'}
              text={`Are you sure you want to reset it? This action cannot be undone. Resetting this will permanently remove it. Please confirm if you wish to proceed.`}
              successColor={'error'}
              cancelButtonText={'Cancel'}
              successButtonText={'Reset'}
              confirmationFunction={() => handleResetPlan()}
            />
          )}
          {isUpdateMenuPlan && (
            <FinalPriceModal
              isOpen={isUpdateMenuPlan}
              setIsOpen={setIsUpdateMenuPlan}
              title={'Are you sure?'}
              text={`Are you sure you want to update it? Please confirm if you wish to proceed.`}
              previousPrice={originalprice}
              planPrice={planPriceFinal}
              handleChangePlanPrice={handleChangePlanPrice}
              finalPrice={finalPrice}
              cancelButtonText={'Cancel'}
              successButtonText={'Confirm'}
              confirmationFunction={() => handleUpdatePlan()}
            />
          )}
          {listView && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-end',
              }}
            >
              <PlanHeader totalPlanCount={meta?.total} />
              <Box
                sx={{
                  display: 'flex',
                  gap: '24px',
                }}
              >
                <Tooltip
                  title={listView ? 'Calender View' : 'List View'}
                  placement="top"
                  arrow
                >
                  <Button sx={listButtonStyles} onClick={toggleView}>
                    {listView ? (
                      <GridIcon backgroundColor="#3F2F5B" />
                    ) : (
                      <ListIcon backgroundColor="#3F2F5B" />
                    )}
                  </Button>
                </Tooltip>

                <Button
                  disabled={!permissions.add}
                  variant="filled"
                  component={Link}
                  to="/admin/newmenuplan"
                  sx={saveButtonStyles}
                >
                  + New Plan
                </Button>
              </Box>
            </Box>
          )}
          {listView ? (
            <Box
              id={'ListView'}
              sx={{
                ...innerBoxStyles,
                flex: '1',
                overflowY: 'auto',
              }}
            >
              <ListView list={planList} setListView={setListView} />
            </Box>
          ) : (
            <Box
              id={'GridView'}
              style={{
                height: '100%',
                flex: '1',
                overflow: 'auto',
              }}
            >
              <Box sx={{ ...innerBoxStyles }}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      ...planInfoStyles,
                      gap: '18px',
                      p: '10px 10px',
                      flex: 1,
                      ...glassyBackgroud,
                      flexWrap: 'wrap',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignContent: 'center',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        flex: '1',
                        gap: '8px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                          alignItems: 'center',
                          flex: 1,
                        }}
                      >
                        <Select
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          name="plan"
                          value={plan}
                          onChange={handleChangePlan}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 248,
                                maxWidth: '290px !important',
                              },
                            },
                          }}
                          sx={{
                            ...selectStyles,
                            width: '290px',
                            background: 'transparent',
                            m: 0,
                            '& .MuiSelect-standard:focus': {
                              '&:focus': {
                                backgroundColor: 'transparent',
                              },
                            },
                            '&:hover': {
                              border: '1px solid #3F2F5B',
                              borderColor: '#3F2F5B !important',
                            },
                            '&.Mui-focused': {
                              border: '1px solid #3F2F5B',
                              borderColor: '#3F2F5B !important',
                            },
                          }}
                          variant="standard"
                        >
                          {planList &&
                            planList.map((planItem, index) => (
                              <MenuItem key={planItem.id} value={planItem.id}>
                                {planItem.name}
                              </MenuItem>
                            ))}
                        </Select>

                        {editMenuPlanName ? (
                          <>
                            <Box sx={{ position: 'relative' }}>
                              <TextField
                                onChange={(e) => {
                                  setDisabledUpdateBtn(false);
                                  setDisabledResetBtn(false);
                                  setPlanName(e.target.value);
                                  setUplatedPlanName(e.target.value);
                                }}
                                value={planName || ''} // Fallback to an empty string if planName2 is null or undefined
                                sx={BootstrapInput}
                                variant="outlined"
                                name="plan_name"
                                InputProps={{
                                  disableUnderline: true,
                                }}
                              />
                            </Box>

                            <Box sx={{ position: 'relative' }}>
                              <TextField
                                placeholder={'Enter description'}
                                onChange={(e) => {
                                  setDisabledUpdateBtn(false);
                                  setDisabledResetBtn(false);
                                  setDescription(e.target.value);
                                  setUplatedPlanDescription(e.target.value);
                                }}
                                value={description}
                                sx={BootstrapInput}
                                variant="outlined"
                                InputProps={{
                                  disableUnderline: true,
                                }}
                              />
                            </Box>

                            <Box>
                              {/* Hidden file input */}
                              <input
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="image-upload"
                                type="file"
                                onChange={(e) => {
                                  setDisabledUpdateBtn(false);
                                  setDisabledResetBtn(false);
                                  handleFileChange(e);
                                }}
                              />

                              <Box
                                sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                {/* Button with a label for triggering file input */}
                                <Button
                                  variant="filled"
                                  component="label"
                                  htmlFor="image-upload" // This connects the label to the hidden input
                                  sx={{
                                    width: '30px',
                                    minWidth: '30px',
                                    height: '33px',
                                    borderRadius: '20px',
                                    color: 'rgba(251, 243, 246, 1)',
                                    cursor: 'pointer',
                                  }}
                                >
                                  {/* Content of the button */}
                                  {imageFile === '' ? <UploadFile /> : <Edit />}
                                </Button>

                                {/* Label text */}
                                <Typography variant="body2" sx={{ ml: 1 }}>
                                  Image
                                </Typography>
                              </Box>
                            </Box>

                            <Link
                              variant="span"
                              style={{
                                ...typographyStyles,
                                fontSize: '16px',
                                fontWeight: '400',
                                padding: '0 13px',
                                background: '#FF7676',
                                borderRadius: '30px',
                                color: 'white',
                                textDecoration: 'none',
                                height: '38px',
                                lineHeight: '38px',
                                width: '95px',
                                textAlign: 'center',
                              }}
                              onClick={() => setEditMenuPlanName(false)}
                            >
                              Done
                            </Link>
                          </>
                        ) : (
                          <>
                            <Tooltip title={planName} placement="top" arrow>
                              <Box
                                sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                {planList.length ? (
                                  <Box
                                    onClick={() => setEditMenuPlanName(true)}
                                    sx={{
                                      width: '22px',
                                      height: '22px',
                                      backgroundImage: `url(${editPencil})`,
                                      cursor: 'pointer',
                                      pointerEvents: 'unset',
                                    }}
                                  ></Box>
                                ) : (
                                  ''
                                )}
                              </Box>
                            </Tooltip>
                          </>
                        )}
                      </Box>
                    </Box>

                    <Box sx={{ display: 'flex', gap: '10px' }}>
                      <Typography sx={typographyStyles}>Plan Price:</Typography>
                      <Typography sx={{ ...typographyStyles }}>
                        $
                        {Number(
                          disabledUpdateBtn ? originalprice : planPriceFinal
                        ).toFixed(2)}
                      </Typography>
                    </Box>
                    <Box>
                      <Tooltip title={'Reset plan'} placement="top" arrow>
                        <Button
                          sx={{
                            border: 0,
                            borderColor: 'transparent',
                            minWidth: 'fit-content',
                            cursor: disabledResetBtn ? '' : 'pointer',
                            opacity: disabledResetBtn ? '.2' : '1',
                            py: 0,
                            '&:hover': {
                              opacity: 0.8,
                              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
                            },
                          }}
                          disabled={disabledResetBtn}
                          onClick={() =>
                            disabledResetBtn ? null : (
                              <>
                                disabledUpdateBtn(true)
                                setIsConfirmationModalOpen(true)
                              </>
                            )
                          }
                        >
                          <RestartAlt
                            sx={{
                              height: '100%',
                              width: '35px',
                              color: disabledResetBtn ? '' : 'primary.main',
                            }}
                          />
                        </Button>
                      </Tooltip>
                    </Box>
                    <Box>
                      <Tooltip title={'Update plan'} placement="top" arrow>
                        <Button
                          sx={{
                            border: 0,
                            borderColor: 'transparent',
                            minWidth: 'fit-content',
                            cursor: disabledUpdateBtn ? '' : 'pointer',
                            opacity: disabledUpdateBtn ? '.2' : '1',
                            '&:hover': {
                              opacity: 0.8,
                              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
                            },
                          }}
                          disabled={disabledUpdateBtn}
                          onClick={() =>
                            disabledUpdateBtn ? null : setIsUpdateMenuPlan(true)
                          }
                        >
                          <EditCalendar
                            sx={{
                              height: '100%',
                              width: '31px',
                              color: disabledResetBtn ? '' : 'primary.main',
                            }}
                          />
                        </Button>
                      </Tooltip>
                    </Box>
                    <Tooltip
                      title={listView ? 'Calender View' : 'List View'}
                      placement="top"
                      arrow
                    >
                      <Button sx={listButtonStyles} onClick={toggleView}>
                        {listView ? (
                          <GridIcon backgroundColor="#3F2F5B" />
                        ) : (
                          <ListIcon backgroundColor="#3F2F5B" />
                        )}
                      </Button>
                    </Tooltip>
                    <Button
                      disabled={!permissions.add}
                      variant="filled"
                      component={Link}
                      to="/admin/newmenuplan"
                      sx={saveButtonStyles}
                    >
                      + New Plan
                    </Button>
                    {/* <Button
                    id={'previous'}
                    onClick={() => handleNextPrevious('previous')}
                    disabled={isPreviousDisabled}
                    sx={{
                      // ...glassyBackgroud,
                      width: '50px',
                      minWidth: '50px',
                      height: '57px',
                      opacity: isPreviousDisabled ? '.5' : '1',
                      width: '50px',
                    }}
                  >
                    <i
                      style={{
                        background: `url(${arrowLeft})`,
                        ...leftRightButtonIconStyle,
                      }}
                    ></i>
                  </Button>

                  <Button
                    id={'next'}
                    onClick={() => handleNextPrevious('next')}
                    disabled={isNextDisabled}
                    sx={{
                      // ...glassyBackgroud,
                      width: '50px',
                      minWidth: '50px',
                      height: '57px',
                      opacity: isNextDisabled ? '.5' : '1',
                      width: '50px',
                    }}
                  >
                    <i
                      style={{
                        background: `url(${arrowRight})`,
                        ...leftRightButtonIconStyle,
                      }}
                    ></i>
                  </Button> */}
                  </Box>
                </Box>

                {selectedPlanItem ? (
                  <Box
                    key={selectedPlanItem.id}
                    id={'plan_' + selectedPlanItem.id}
                    name={'plan_' + plan}
                    sx={{
                      flexDirection: 'column',
                      width: '100%',
                      mt: '15px',
                      gap: '8px',
                      display: selectedPlanItem.id === plan ? 'flex' : 'none',
                      height: '100%',
                      overflow: 'auto',
                      paddingBottom: '3px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: '20px',
                        height: '42px',
                        flexBasis: '0',
                        flexShrink: '0',
                        flexGrow: '0',
                      }}
                    >
                      <Box position="relative">
                        <Box onClick={() => setIsPickerOpen(!isPickerOpen)}>
                          <SmallCard
                            sx={{
                              ...calenderHeaderStyles,
                              padding: 0,
                              fontWeight: 700,
                              cursor: 'pointer',
                              border: '3px solid #FF7676',
                            }}
                          >
                            {displayMonth}
                          </SmallCard>
                          {isPickerOpen && (
                            <Box position="absolute" top="100%" left="0">
                              <MonthYearPicker
                                setDisplayMonth={setDisplayMonth}
                                setSelectedDate={setSelectedDate}
                                selectedDate={selectedDate}
                                isPickerOpen={isPickerOpen}
                                setIsPickerOpen={setIsPickerOpen}
                              />
                            </Box>
                          )}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'space-evenly',
                          gap: '8px',
                        }}
                      >
                        <SmallCard
                          sx={{ ...calenderHeaderStyles, ...dayHeaderStyles }}
                        >
                          Sunday
                        </SmallCard>
                        <SmallCard
                          sx={{ ...calenderHeaderStyles, ...dayHeaderStyles }}
                        >
                          Monday
                        </SmallCard>
                        <SmallCard
                          sx={{ ...calenderHeaderStyles, ...dayHeaderStyles }}
                        >
                          Tuesday
                        </SmallCard>
                        <SmallCard
                          sx={{ ...calenderHeaderStyles, ...dayHeaderStyles }}
                        >
                          Wednesday
                        </SmallCard>
                        <SmallCard
                          sx={{ ...calenderHeaderStyles, ...dayHeaderStyles }}
                        >
                          Thursday
                        </SmallCard>
                        <SmallCard
                          sx={{ ...calenderHeaderStyles, ...dayHeaderStyles }}
                        >
                          Friday
                        </SmallCard>
                        <SmallCard
                          sx={{ ...calenderHeaderStyles, ...dayHeaderStyles }}
                        >
                          Saturday
                        </SmallCard>
                      </Box>
                    </Box>
                    {/* Render weeks in a list */}
                    {[1, 2, 3, 4, 5, 6].map((weekNumber) =>
                      renderWeek(
                        weekNumber,
                        getDatesForWeek(weekNumber, selectedDate),
                        selectedPlanItem
                      )
                    )}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: '100%',
                      mt: '15px',
                      display: 'flex',
                      height: '100%',
                      padding: '15px',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography>
                      No menu plan available for this selected month.
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
}

export default menuPlanList;
