  import { Box, Button, Modal, Typography } from '@mui/material';
  import React, { useEffect, useState } from 'react';
  import moment from 'moment/moment';

  import EditProfile from './assets/editprofile.svg';
  import Clock from './assets/clock.svg';
  import Star from './assets/star.svg';
  import ArrowRight from './assets/arrow-right.svg';
  import ArrowLeft from './assets/arrow-left.svg';
  import Cross from './assets/cross.svg';
  import MealCross from './assets/mealscross.svg';

  import '@fontsource/poppins';
  import '@fontsource/poppins/500.css'; // Weight 500
  import '@fontsource/poppins/600.css'; // Weight 600

  // import { Grid, Button, Typography } from '@mui/material';
  import { TENANT_URL } from '../../../config';
  import axios from 'axios';
  import { useProfile } from '../../../tenant/customHooks/useProfile';
  import ProfileMealPlusModal from '../../../tenant/pages/profile/components/ProfileMealPlusModal';


  const ProfileCard = ({ setOpen }) => {
    const {
      profileData,
      loading,
      getCustomerById,
      getCustomerDetails,
      currentCustomer,
      updateCustomerActivation,
      currentCustomerData,
    } = useProfile();

    const [checked, setChecked] = useState(profileData?.is_active);
    const [checkedSub, setCheckedSub] = useState(
      profileData?.subscription_status
    );
    const days = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];

    const [profilesData, setProfilesData] = useState({});
    const [subscriptionStatus, setSubscriptionStatus] = useState(true);
    const [mealPlusSubscription, setMealPlusSubscription] = useState([]);

    const [openModal, setOpenModal] = useState(false);

    const [date, setDate] = useState('');
    useEffect(() => {
      if (profileData) {
        // debugger;
        setDate(moment(profileData?.joining_date).utc().format('MMM DD, YYYY'));
        setChecked(profileData?.is_active);
        setCheckedSub(profileData?.subscription_status);
      }
    }, [date]);
    const handleStatus = (v, e) => {
      // debugger;
      if (e === 'activPause') {
        setChecked(v);
        updateCustomerActivation(v);
      } else {
        setCheckedSub(v);
        updateCustomerSubscription(v);
      }
    };

    const fetchCardsData = async () => {
      const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
      const config = {
        method: 'get',
        url: `${TENANT_URL}/customer/profile`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      };

      try {
        const response = await axios(config);
        if (response.data) {
          setProfilesData(response.data.data);
          setSubscriptionStatus(response.data.data.subscription_status);
          console.log('profileData:');
        }
      } catch (error) {
        console.error('Error fetching card data:', error);
      }
    };

    const fetchMealPlusAvailabilityDates = async () => {
      const currentMonthName = new Date()
        .toLocaleString('en-US', { month: 'long' })
        .toLowerCase();
      const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
      const config = {
        method: 'get',
        url: `${TENANT_URL}/customer/app/meal-plus/weeks-availabilities?month=${currentMonthName}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      };

      try {
        const response = await axios(config);
        if (response.data) {
          const weeksArray = convertWeeksToArray(response.data.data);
          // console.log('weeksArray',weeksArray)
          // setMealPlusSubscription(response.data.data);
          setMealPlusSubscription(weeksArray);
        }
      } catch (error) {
        console.error('Error fetching card data:', error);
      }
    };

    const convertWeeksToArray = (data) => {
      if (data && data.available_weeks) {
        return Object.entries(data.available_weeks).map(([key, value]) => ({
          id: key,
          label: value,
        }));
      }
      return [];
    };
    
    useEffect(() => {
      fetchCardsData();
      fetchMealPlusAvailabilityDates();
    }, []);

    // const handleOpenModal = () => {
    //   setOpenModal(true);
    // };
    const [mealPlusSelection, setMealplusSelection] = useState([]);

    const fetchMealPlusData = async (startDate, endDate) => {
      const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
      const config = {
        method: 'get',
        url: `${TENANT_URL}/customer/app/meal-plus?from=${startDate}&to=${endDate}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      };
  
      try {
        const response = await axios(config);
        setMealplusSelection(response.data.data);
      } catch (error) {
        console.error('Error fetching card data:', error);
      }
    };

    // const handleOpenModal = async (week) => {
    //   console.log('week',week)
    //   const currentDate = new Date();
    //   const startDate = new Date(currentDate);
    //   const endDate = new Date(currentDate);
    //   endDate.setDate(endDate.getDate() + 6);
      
    //   // const formattedStartDate = startDate.toISOString().split('T')[0];
    //   // const formattedEndDate = endDate.toISOString().split('T')[0];
    //   const formattedStartDate = '2025-01-01';
    //   const formattedEndDate = '2025-01-04';
      
    //   await fetchMealPlusData(formattedStartDate, formattedEndDate);
    //   setOpenModal(true);
    // };

    const handleOpenModal = async (week) => {
      console.log('Selected Week:', week);

      const currentDate = new Date(); // Current date
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth(); // 0-based index

      const startOfMonth = new Date(year, month, 1); // 1st day of the month
      const firstDayOfWeek = startOfMonth.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

      // Calculate the week index (e.g., "week_1" → 1, "week_2" → 2)
      const weekIndex = parseInt(week.split('_')[1], 10); 

      let startDate, endDate;

      if (weekIndex === 1) {
          // Week 1 starts on the 1st of the month
          startDate = new Date(year, month, 1);

          // Week 1 ends on the first Saturday of the month
          const firstSaturday = firstDayOfWeek === 6 ? 1 : 7 - firstDayOfWeek;
          endDate = new Date(year, month, firstSaturday);
      } else {
          // Calculate the start date for subsequent weeks (Sunday of that week)
          startDate = new Date(year, month, 1 + (weekIndex - 2) * 7 + (7 - firstDayOfWeek));

          // End date is always 6 days after start date
          endDate = new Date(startDate);
          endDate.setDate(startDate.getDate() + 6);
      }

      // ** Add 1 day to both startDate and endDate **
      startDate.setDate(startDate.getDate() + 1);
      endDate.setDate(endDate.getDate() + 1);

      // Ensure end date does not exceed the last day of the month
      const lastDayOfMonth = new Date(year, month + 1, 0);
      if (endDate > lastDayOfMonth) {
          endDate = lastDayOfMonth;
      }

      // Format dates as YYYY-MM-DD
      const formattedStartDate = startDate.toISOString().split('T')[0];
      const formattedEndDate = endDate.toISOString().split('T')[0];

      console.log('Start Date:', formattedStartDate, 'End Date:', formattedEndDate);

      // Fetch meal plus data for the calculated dates
      await fetchMealPlusData(formattedStartDate, formattedEndDate);

      // Open the modal
      setOpenModal(true);


    };

    const handleCloseModal = () => {
      setOpenModal(false);
    };

    const updateCustomerSubscription = async () => {
      // var data = JSON.stringify({ is_active: isActive });
      try {
        const token = JSON.parse(localStorage.getItem('tenantdata')).token;
        var addConfig = {
          method: 'put',
          url: `${TENANT_URL}/customer/subscription/status`,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        };
        const response = await axios({ ...addConfig });
        // await getProfileData();
        setSubscriptionStatus(response.data.data.is_active);
        // console.log("response:",response.data.data.is_active);
        // show('Subsrciption Updated Successfully');
      } catch (error) {
        show(error.response.data.message, 'error');
        console.log(error, error.response.data.message);
      }
    };
    const handleUpdateSubscription = () => {
      updateCustomerSubscription();
    };
    return (
      <>
        <Box
          className=""
          sx={{
            border: '1px solid #EBEBEC',
            borderRadius: '10px',
            padding: '20px',
            // maxWidth: '480px',
            // minWidth: '424px',
          }}
        >
          {loading ? (
            <></>
          ) : (
            <Box
              sx={{
                display: 'flex',
                p: { xs: '10px', sm: '15px' },
                background:
                  'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
                borderRadius: '10px',
                overflow: 'hidden',
                // minWidth: { xs: '280px', sm: '380px' },
                // maxWidth: { xs: '320px', sm: '420px' },
                border: '1px solid #EBEBEC',
                margin: '0 auto 30px',
              }}
            >
              <Box
                sx={{
                  mx: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    overflow: 'hidden',
                    width: { xs: '120px', sm: '150px' },
                    height: { xs: '90px', sm: '119px' },
                    position: 'relative',
                  }}
                >
                  <Button
                    sx={{
                      minWidth: '23px',
                      width: '23px',
                      padding: '2px',
                      position: 'absolute',
                      right: '0',
                      boxShadow: 'none',
                      '&:hover': {
                        boxShadow: 'none',
                        backgroundColor: 'transparent',
                      },
                    }}
                    onClick={() => {
                      getCustomerDetails();
                    }}
                  >
                    <img src={EditProfile} />
                  </Button>
                  <Box
                    sx={{
                      overflow: 'hidden',
                      width: { xs: '90px', sm: '119px' },
                      height: { xs: '90px', sm: '119px' },
                      borderRadius: '100%',
                      objectFit: 'cover',
                    }}
                  >
                    <img src={require('./assets/1.png')} width={'100%'} />
                  </Box>
                </Box>
                <Typography
                  sx={{
                    fontSize: { xs: '14px', sm: '16px', xl: '17px' },
                    fontWeight: '600',
                    lineHeight: '21px',
                    letterSpacing: '0px',
                    fontFamily: 'Poppins',
                    textAlign: 'center',
                    marginTop: '10px',
                  }}
                >
                  {profilesData.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: '10px', sm: '13px' },
                    fontWeight: '500',
                    lineHeight: '21px',
                    letterSpacing: '0px',
                    fontFamily: 'Poppins',
                    textAlign: 'center',
                  }}
                >
                  {profilesData.email}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: '10px', sm: '13px' },
                    fontWeight: '500',
                    lineHeight: '21px',
                    letterSpacing: '0px',
                    fontFamily: 'Poppins',
                    textAlign: 'center',
                  }}
                >
                  {profilesData.phone}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: '10px', sm: '13px' },
                    fontWeight: '500',
                    lineHeight: '21px',
                    letterSpacing: '0px',
                    fontFamily: 'Poppins',
                    textAlign: 'center',
                    width: { xs: '100%', sm: '208px' },
                  }}
                >
                  {profilesData.address}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      textAlign: 'center',
                      gap: '12px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: '12px', sm: '14px' },
                        fontWeight: '400',
                      }}
                    >
                      {profileData?.email ? profileData?.email : ''}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: '12px', sm: '14px' },
                        fontWeight: '400',
                      }}
                    >
                      {profileData?.phone ? profileData?.phone : ''}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {/* subscribe details */}
          <Box
            sx={{
              p: '15px',
              background:
                'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
              borderRadius: '10px',
              overflow: 'hidden',
              border: '1px solid #EBEBEC',
              margin: '0 auto 30px',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: '#3F2F5B',
                fontWeight: 600,
                fontFamily: 'Poppins',
                marginBottom: '15px',
                textTransform: 'uppercase',
                fontSize: { xs: '16px' }, // Adjusts font size for different breakpoints
              }}
            >
              Subscription Detail
            </Typography>

            <Box
              sx={{
                backgroundColor: '#F4F4F4',
                padding: 2,
                borderRadius: 2,
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                marginBottom: '15px',
                flexDirection: 'row', // Stacks items vertically on small screens
              }}
            >
              <div>
                <img src={Clock} />
              </div>
              <div style={{ textAlign: 'left' }}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: { xs: '10px', md: '11px' }, // Adjusts font size for different breakpoints
                    fontWeight: 400,
                    color: '#3F2F5B',
                  }}
                >
                  Join Date:
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: { xs: '11px', md: '12px' }, // Adjusts font size for different breakpoints
                    fontWeight: 500,
                    color: '#3F2F5B',
                  }}
                >
                  {profilesData?.subscription_details?.start_date || 'N/A'}
                </Typography>
              </div>
            </Box>

            <Box
              sx={{
                backgroundColor: '#F4F4F4',
                padding: 2,
                borderRadius: 2,
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                marginBottom: '15px',
                flexDirection: 'row', // Stacks items vertically on small screens
              }}
            >
              <div>
                <img src={Clock} />
              </div>
              <div style={{ textAlign: 'left' }}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: { xs: '10px', md: '11px' }, // Adjusts font size for different breakpoints
                    fontWeight: 400,
                    color: '#3F2F5B',
                  }}
                >
                  Renewal Date:
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: { xs: '11px', md: '12px' }, // Adjusts font size for different breakpoints
                    fontWeight: 500,
                    color: '#3F2F5B',
                  }}
                >
                  {profilesData?.subscription_details?.renewal_date || 'N/A'}
                </Typography>
              </div>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                gap: 1,
              }}
            >
              {/* <Button
                variant="contained"
                sx={{
                  backgroundColor: '#F0645B',
                  color: '#fff',
                  borderRadius: 100,
                  padding: '10px 16px',
                  boxShadow: 'none',
                  width: '100%',
                  mb: { xs: 1, sm: 0 },
                  height: '41px',
                  '&:hover': {
                    backgroundColor: '#F0645B',
                  },
                  '@media (min-width: 1200px) and (max-width: 1400px)': {
                    padding: '10px 12px',
                    fontSize: '11px',
                  },
                  '@media (min-width: 1535px) and (max-width: 1600px)': {
                    padding: '10px 12px',
                    fontSize: '12px',
                  },
                }}
              >
                Cancel Subscription
              </Button> */}
              <Button
                variant="outlined"
                sx={{
                  color: '#d32f2f',
                  borderColor: '#d32f2f',
                  borderRadius: 100,
                  padding: '10px 16px',
                  boxShadow: 'none',
                  width: '100%',
                  height: '41px',
                  '&:hover': {
                    backgroundColor: '#F0645B',
                    borderColor: '#F0645B',
                    color: '#fff',
                  },
                  '@media (min-width: 1200px) and (max-width: 1400px)': {
                    padding: '10px 12px',
                    fontSize: '11px',
                  },
                  '@media (min-width: 1535px) and (max-width: 1600px)': {
                    padding: '10px 12px',
                    fontSize: '12px',
                  },
                }}
                onClick={handleUpdateSubscription}
              >
                {!!subscriptionStatus
                  ? 'Pause Subscription'
                  : 'Restart Subscription'}
              </Button>
            </Box>
          </Box>
          {/* meal  */}
          <Box
            sx={{
              p: '15px',
              background:
                'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
              borderRadius: '10px',
              overflow: 'hidden',
              // minWidth: { xs: '100%', sm: '410px' },
              // maxWidth: { xs: '100%', sm: '420px' },
              border: '1px solid #EBEBEC',
              margin: '0 auto',
            }}
          >
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div className="d-flex align-items-center gap-2">
                <Typography
                  variant="h6"
                  sx={{
                    color: '#3F2F5B',
                    fontWeight: 600,
                    fontFamily: 'Poppins',
                    marginBottom: '0',
                    fontSize: { xs: '16px', md: '20px' }, // Adjusts font size for different breakpoints
                  }}
                >
                  MEAL +
                </Typography>
              </div>
              <div className="">
                <img src={Star} />
              </div>
            </div>

            {mealPlusSubscription.map((element, index) => (
              <Box
                sx={{
                  backgroundColor: '#F4F4F4',
                  padding: 2,
                  borderRadius: 2,
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  marginBottom: '15px',
                  flexDirection: { xs: 'column', sm: 'row' }, // Stacks items vertically on small screens
                  position: 'relative',
                }}
              >
                <div>
                  <img src={Clock} />
                </div>
                <Box
                  sx={{
                    textAlign: { xs: 'center', sm: 'left' },
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: { xs: '10px', md: '11px' }, // Adjusts font size for different breakpoints
                      fontWeight: 400,
                      color: '#3F2F5B',
                    }}
                  >
                    Available For:
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: { xs: '11px', md: '12px' }, // Adjusts font size for different breakpoints
                      fontWeight: 500,
                      color: '#3F2F5B',
                    }}
                  >
                    {element.label}
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: { xs: '9px', md: '12px' }, // Adjusts font size for different breakpoints
                    fontWeight: 400,
                    color: '#3F2F5B',
                    position: 'absolute',
                    right: '7px',
                    top: '15px',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleOpenModal(element.id)}
                >
                  Customize
                </Typography>
              </Box>
            ))}
            
          </Box>
        </Box>

        <ProfileMealPlusModal 
          open={openModal}
          onClose={handleCloseModal}
          weekData={mealPlusSelection}
        />
      </>
    );
  };

  export default ProfileCard;
