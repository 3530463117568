import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useSnackbar } from '../../components/utils/snackbarProvider/SnackbarContextProvider';
import { TENANT_URL } from '../../config';
import dayjs from 'dayjs';

const DeliveryGroupContext = createContext();

export function useDeliveryGroup() {
  return useContext(DeliveryGroupContext);
}

export const DeliveryGroupProvider = ({ children }) => {
  const [deliveryGroupList, setDeliveryGroupList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagedList, setPagedList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [links, setLinks] = useState(null);
  const [meta, setMeta] = useState([]);
  const { show } = useSnackbar();
  const [routes, setRoutes] = useState([]);
  const [cardData, setCardData] = useState({});
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [keyword, setKeyword] = useState('');
  const [route, setRoute] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('');

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (currentPage !== 0) getDeliveryGroupList(currentPage);
  }, [currentPage]);
  function formatDate(date) {
    return dayjs(date).format('YYYY-MM-DD');
  }
  // useEffect(() => {
  //   if (dateRange) {
  //     getDeliveryGroupList(1);
  //   }
  // }, [dateRange]);

  const getData = () => {
    setLoading(true);
    getDeliveryGroupCard();
    getDeliveryGroupList(1);
    getRoutes();
    setLoading(false);
  };

  useEffect(() => {
    getDeliveryGroupLists(1);
  }, [selectedFilter]);

  const getDeliveryGroupList = async (pageNumber) => {
      const data = {
        page: pageNumber,
        start_date: formatDate(dateRange[0]),
        end_date: formatDate(dateRange[1]),
      };
      const queryParams = new URLSearchParams({
        ...data,
        delivery_method: 'home_delivery',
      }).toString();
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      const getConfig = {
        method: 'get',
        url: `${TENANT_URL}/daily/delivery?${selectedFilter}&${queryParams}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios({ ...getConfig, data: data });
        console.log("responseresponse:",response);
        setDeliveryGroupList(response.data.data);
        setPagedList(response.data.data);
        setLinks(response.data.links);
        setMeta(response.data.meta);
        setCurrentPage(response.data.meta.current_page || 1);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
  };
  const getDeliveryGroupLists = async (pageNumber) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/daily/delivery?${selectedFilter}&delivery_method=home_delivery`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        name: keyword,
        is_active: true,
        start_date: formatDate(dateRange[0]),
        end_date: formatDate(dateRange[1]),
        route_name: route==="All"?"":route,
      },
    };

    try {
      const response = await axios(getConfig);
      setDeliveryGroupList(response.data.data);
      setPagedList(response.data.data);
      setLinks(response.data.links);
      setMeta(response.data.meta);
      setCurrentPage(response.data.meta.current_page);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (route || dateRange || keyword.length > 2) {
      getDeliveryGroupLists();
    }
  }, [route, dateRange, keyword]);
  const getDeliveryGroupCard = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/card/daily/delivery?delivery_method=home_delivery`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(getConfig);
      setCardData(response.data);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getRoutes = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/all/route`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(getConfig);
      let routes = response.data.data || []
      routes.unshift({ created_at: "25 Feb 2024", deleted_at: null, description: "All", id: 2, is_active: true, route_area: "All", route_name: "All", updated_at: "2024-02-26T13:38:03.000000Z" })
      setRoutes(routes);
    } catch (error) {
      console.log(error);
    }
  };

  const values = {
    deliveryGroupList,
    loading,
    pagedList,
    currentPage,
    setCurrentPage,
    links,
    meta,
    cardData,
    routes,
    setDateRange,
    dateRange,
    setKeyword,
    keyword,
    setRoute,
    route,
    selectedFilter,
    setSelectedFilter,
  };

  return (
    <DeliveryGroupContext.Provider value={values}>
      {children}
    </DeliveryGroupContext.Provider>
  );
};
