import { Box, Typography, Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import FB from './icons/facebook.svg';
import Twitter from './icons/twitter.svg';
import MainLogo from './icons/main-logo.svg';
import YT from './icons/youtube.svg';
import Insta from './icons/instagram.svg';
import './Footer6.css';
import axios from 'axios';
import { TENANT_URL } from '../../../../../../config';

function Footer6() {
  console.log('footer 6');
  const [logo, setLogo] = useState('');
  const [open, setOpen] = useState(false); // Modal visibility state
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const [openPrivacyModal, setOpenPrivacyModal] = useState(false); // Privacy Policy modal state
  const [openRefundModal, setOpenRefundModal] = useState(false); // Privacy Policy modal state

  const getLogo = async () => {
    const config = {
      method: 'get',
      url: `${TENANT_URL}/api/restaurant/logo`,
    };

    try {
      const response = await axios(config);
      const logo = response.data.data.logo;
      console.log('sadasdasdasd:', logo);
      setLogo(logo);
      localStorage.setItem('app-logo', logo);
      return logo;
    } catch (error) {
      console.error('Error fetching logo:', error);
    }
  };

  useEffect(() => {
    getLogo();
  }, []);

  const handleOpen = () => setOpen(true); // Open modal
  const handleClose = () => setOpen(false); // Close modal

  const handleTermsModalOpen = () => setOpenTermsModal(true);
  const handleTermsModalClose = () => setOpenTermsModal(false);

  const handlePrivacyModalOpen = () => setOpenPrivacyModal(true); // Open Privacy Policy modal
  const handlePrivacyModalClose = () => setOpenPrivacyModal(false); // Close Privacy Policy modal

  const handleRefundModalOpen = () => setOpenRefundModal(true); // Open Privacy Policy modal
  const handleRefundModalClose = () => setOpenRefundModal(false); // Close Privacy Policy modal

  return (
    <>
      <div className="footer_a">
        <div className="container">
          <footer>
            <div className="row">
              <div className="col-md-12">
                <div className="useful_main_content">
                  <div className="row">
                    <div className="col-12 col-md-6 mb-4 col-lg-3 mb-md-0">
                      <div className="main_logo_social">
                        <img
                          src={logo}
                          alt="main-logo"
                          className="img-fluid mb-3"
                        />
                        <p>
                          Step into a world where flavors come alive and
                          culinary dreams are realized.
                        </p>
                        <div className="social_icons">
                          <img src={FB} alt="facebook icon" />
                          <img src={Insta} alt="instagram icon" />
                          <img src={Twitter} alt="twitter icon" />
                          <img src={YT} alt="yt icon" />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-4 col-lg-3 mb-md-0">
                      <h6>Useful Links</h6>
                      <ul className="list-unstyled">
                        <li>About us</li>
                        <li>How it works</li>
                        <li>Our work</li>
                        <li>Contact us</li>
                      </ul>
                    </div>
                    <div className="col-12 col-md-6 mb-4 col-lg-3 mb-md-0">
                      <h6>Legals</h6>
                      <ul className="list-unstyled">
                        <li onClick={handleOpen}>Product Service</li>
                        <li onClick={handleTermsModalOpen}>
                          Terms and Condition
                        </li>
                        <li onClick={handlePrivacyModalOpen}>Privacy Policy</li>{' '}
                        {/* Privacy Policy link */}
                        <li onClick={handleRefundModalOpen}>
                          Refund Policy
                        </li>{' '}
                        {/* Privacy Policy link */}
                      </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <h6>Contact Us</h6>
                      <p>
                        We're here to help! If you have any questions, concerns,
                        or feedback about our meal prep service or website,
                        please don't hesitate to contact us.
                      </p>

                      <h6 className="mt-3">Customer Service</h6>
                      <ul className="list-unstyled">
                        <li className="mb-2">
                          <a
                            style={{ color: '#555', textDecoration: 'none' }}
                            href="mailto:tiffin@momsmasalaraoi.com"
                          >
                            tiffin@momsmasalaraoi.com
                          </a>
                        </li>
                        <li className="mb-2">
                          <a
                            style={{ color: '#555', textDecoration: 'none' }}
                            href="tel:+16043781828"
                          >
                            604-378-1828
                          </a>
                          ,{' '}
                          <a
                            style={{ color: '#555', textDecoration: 'none' }}
                            href="tel:+13064506006"
                          >
                            306-450-6006
                          </a>
                        </li>
                      </ul>
                      <h6 className="mt-3 mb-4">Our Location</h6>
                      <p
                        style={{
                          color: '#555',
                          textDecoration: 'none',
                          fontSize: '22px',
                          fontWeight: '500',
                        }}
                      >
                        10727 135a St unit 4<br></br>
                        Surrey, BC V3T 4E3
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
      {/* Modal */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: '80vh', // Limit the height to 80% of the viewport height
            overflowY: 'auto', // Enable vertical scrolling when content overflows
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          {/* Add the Product/Service Description heading */}
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Product/Service Description
          </Typography>

          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
            Nourish Your Day, One Meal at a Time
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Discover the convenience of home-style, healthy meals delivered
            right to your doorstep. Our daily tiffin service offers a wide array
            of delicious Indian dishes, from flavorful rice items to hearty
            rotis.
          </Typography>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
            Why Choose Us?
          </Typography>
          <ul>
            <li>
              Fresh Daily: We prepare fresh meals every day, ensuring optimal
              taste and quality.
            </li>
            <li>
              Diverse Menu: Our extensive menu caters to various dietary
              preferences and offers a delightful range of options.
            </li>
            <li>
              Prioritized Safety: We strictly adhere to food safety standards to
              guarantee the highest quality and hygiene.
            </li>
            <li>
              Tailored Meal Packs: Select from our flexible meal packs to suit
              your specific needs and budget.
            </li>
            <li>
              Hassle-Free Delivery: Enjoy the convenience of doorstep delivery,
              saving you time and effort.
            </li>
          </ul>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Whether you're a busy student, a working professional, or a family
            seeking nutritious meals, our tiffin service is your ideal solution.
            Experience the joy of wholesome, delicious food without the hassle
            of cooking.
          </Typography>
        </Box>
      </Modal>

      {/* Terms and Conditions Modal */}
      <Modal open={openTermsModal} onClose={handleTermsModalClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            maxHeight: '80vh', // Limit the height of the modal
            overflow: 'auto', // Enable scroll if content overflows
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
            }}
            onClick={handleTermsModalClose}
          >
            <CloseIcon />
          </IconButton>

          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Terms and Conditions
          </Typography>

          <div style={{ maxHeight: 'calc(80vh - 60px)', overflowY: 'auto' }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>1. Introduction</strong>
              <br />
              Welcome to Mom’s Masala Rasoi Food Service LTD. We provide a meal
              prep service delivering fresh, home-style Indian dishes to
              students, families, and individuals in within British Columbia and
              Alberta, Canada. By accessing or using our website, you agree to
              be bound by these Terms and Conditions. If you disagree with any
              part of the terms, then you may not access the website.
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>2. Use of the Website</strong>
              <br />
              <ul>
                <li>
                  <strong>a. Content:</strong> All website content, including
                  text, graphics, images, logos, and software, is the property
                  of Mom’s Masala Rasoi Food Service LTD and protected by
                  Canadian copyright laws.
                </li>
                <li>
                  <strong>b. Ordering:</strong> You can use our website to
                  browse our menu, select meal packs, and place orders. By
                  placing an order, you agree to provide accurate and complete
                  information and to pay the specified fees.
                </li>
                <li>
                  <strong>c. Account:</strong> You may need to create an account
                  to access certain features. You are responsible for
                  maintaining the confidentiality of your account information
                  and for all activities that occur under your account.
                </li>
                <li>
                  <strong>d. Prohibited Activities:</strong> You agree not to
                  use the website for any unlawful purpose or in any way that
                  could damage, disable, overburden, or impair the website or
                  interfere with any other party's use of the website.
                </li>
              </ul>
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>3. Meal Prep Service</strong>
              <br />
              <ul>
                <li>
                  <strong>a. Menu:</strong> We offer a variety of Indian dishes,
                  including rice items, rotis, and other accompaniments. Our
                  menu is subject to change without notice.
                </li>
                <li>
                  <strong>b. Freshness:</strong> We prepare all meals fresh
                  daily using high-quality ingredients and adhering to strict
                  food safety standards in compliance with relevant health
                  regulations in British Columbia and Alberta.
                </li>
                <li>
                  <strong>c. Allergies and Dietary Restrictions:</strong> While
                  we take precautions to prevent cross-contamination, we cannot
                  guarantee that our meals are free of allergens.
                </li>
              </ul>
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>4. Disclaimer</strong>
              <br />
              <ul>
                <li>
                  <strong>a. Website Accuracy:</strong> We strive to provide
                  accurate information on our website, but we do not warrant
                  that the content is error-free, complete, or current.
                </li>
                <li>
                  <strong>b. Service Availability:</strong> We reserve the right
                  to modify or discontinue our meal prep service at any time
                  without notice.
                </li>
                <li>
                  <strong>c. Liability:</strong> Mom’s Masala Rasoi Food Service
                  LTD shall not be liable for any damages arising from the use
                  of our website or our meal prep service.
                </li>
              </ul>
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>5. Changes to Terms and Conditions</strong>
              <br />
              We reserve the right to modify these Terms and Conditions at any
              time. Any changes will be effective immediately upon posting on
              the website.
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>6. Governing Law</strong>
              <br />
              These Terms and Conditions shall be governed by and construed in
              accordance with the laws of British Columbia and Alberta, Canada.
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>7. Dispute Resolution</strong>
              <br />
              Any dispute arising out of or relating to these Terms and
              Conditions shall be subject to the exclusive jurisdiction of the
              courts of British Columbia or Alberta, depending on the user's
              province of residence.
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>8. Contact Us</strong>
              <br />
              If you have any questions about these Terms and Conditions, please
              contact us.
            </Typography>
          </div>
        </Box>
      </Modal>

      {/* Privacy Policy Modal */}
      <Modal open={openPrivacyModal} onClose={handlePrivacyModalClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            maxHeight: '80vh', // Adjust the max height to fit the screen
            overflowY: 'auto', // Enable vertical scrolling
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
            }}
            onClick={handlePrivacyModalClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Privacy Policy
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>1. Introduction</strong>
            <br />
            Mom’s Masala Rasoi Food Service LTD, is committed to protecting the
            privacy of our website visitors and customers. This Privacy Policy
            outlines how we collect, use, disclose, and safeguard your personal
            information when you use our website, momsmasalaraoi.com, and our
            meal prep service. This policy complies with the Personal
            Information Protection Act (PIPA) of British Columbia and the
            Personal Information Protection Act (PIPA) of Alberta.
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>2. Information We Collect</strong>
            <br />
            We may collect the following types of information:
            <ul>
              <li>
                <strong>Personal Information:</strong> This includes your name,
                email address, phone number, delivery address, billing address,
                and any other information you provide when creating an account,
                placing an order, or contacting us.
              </li>
              <li>
                <strong>Order Information:</strong> This includes details about
                the meal packs you order, your delivery preferences, and your
                order history.
              </li>
              <li>
                <strong>Payment Information:</strong> We use third-party payment
                processors to handle payments. We do not store your full credit
                card information.
              </li>
              <li>
                <strong>Website Usage Data:</strong> This includes your IP
                address, browser type, operating system, referring URLs, pages
                visited, and other information about how you interact with our
                website. This data is collected through cookies and similar
                technologies.
              </li>
            </ul>
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>3. How We Use Your Information</strong>
            <br />
            We use your information for the following purposes:
            <ul>
              <li>
                <strong>Providing and Improving Our Service:</strong> To process
                your orders, deliver your meals, communicate with you about your
                orders, and improve our website and service.
              </li>
              <li>
                <strong>Marketing:</strong> With your consent, we may send you
                emails or other communications about new menu items, promotions,
                and other updates.
              </li>
              <li>
                <strong>Legal Compliance:</strong> To comply with applicable
                laws and regulations.
              </li>
            </ul>
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>4. Sharing Your Information</strong>
            <br />
            We may share your information with the following third parties:
            <ul>
              <li>
                <strong>Delivery Partners:</strong> To deliver your meals to
                your doorstep.
              </li>
              <li>
                <strong>Payment Processors:</strong> To process your payments
                securely.
              </li>
              <li>
                <strong>Service Providers:</strong> To assist us with website
                hosting, data analytics, and other essential functions.
              </li>
              <li>
                <strong>Legal Authorities:</strong> When required by law or
                legal process.
              </li>
            </ul>
            We will never sell your personal information to third parties.
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>5. Cookies and Similar Technologies</strong>
            <br />
            We use cookies and similar technologies to collect website usage
            data, personalize your experience, and track the effectiveness of
            our marketing campaigns. You can manage your cookie preferences
            through your browser settings.
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>6. Your Privacy Choices</strong>
            <br />
            <ul>
              <li>
                <strong>Marketing Emails:</strong> You can opt out of receiving
                marketing emails by clicking the "unsubscribe" link in any email
                or by contacting us directly.
              </li>
              <li>
                <strong>Account Information:</strong> You can access and update
                your account information by logging into your account.
              </li>
              <li>
                <strong>Data Retention:</strong> We will retain your personal
                information for as long as necessary to fulfill the purposes
                outlined in this Privacy Policy, unless a longer retention
                period is required or permitted by law.
              </li>
            </ul>
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>7. Security</strong>
            <br />
            We take reasonable measures to protect your personal information
            from unauthorized access, use, or disclosure. However, no method of
            transmission over the internet or method of electronic storage is
            completely secure.
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>8. Children's Privacy</strong>
            <br />
            Our website and service are not intended for children under the age
            of 13. We do not knowingly collect personal information from
            children under 13.
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>9. Changes to this Privacy Policy</strong>
            <br />
            We may update this Privacy Policy from time to time. Any changes
            will be effective immediately upon posting on the website. Your
            continued use of the website following the posting of changes
            constitutes your acceptance of the revised policy.
          </Typography>
        </Box>
      </Modal>

      <Modal open={openRefundModal} onClose={handleRefundModalClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
            }}
            onClick={handleRefundModalClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Refund Policy
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>1. Overview</strong>
            <br />
            At Mom’s Masala Rasoi Food Service LTD, we are committed to
            providing you with fresh, high-quality meals and excellent service.
            This Refund Policy outlines the circumstances under which refunds or
            credits may be issued for our meal prep service. This policy applies
            to all orders placed through our website, momsmasalaraoi.com, for
            delivery in British Columbia and Alberta, Canada.
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>2. Limited Refund Policy</strong>
            <br />
            Please note that due to the perishable nature of our products and to
            maintain the highest standards of food safety, we generally do not
            offer refunds or exchanges for delivered meal packs.
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>3. Refunds in Genuine Cases</strong>
            <br />
            While refunds are not typically provided, we understand that unique
            situations may occur. In genuine cases and upon your request, we may
            consider issuing a refund or credit. However, please be aware that
            refunds are not guaranteed and are subject to our review and
            discretion.
          </Typography>
        </Box>
      </Modal>
    </>
  );
}

export default Footer6;
