import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  Button,
  Modal,
  Chip,
  Grid,
} from '@mui/material';
import { ChevronLeft, ChevronRight, X } from 'lucide-react';
import { TENANT_URL } from '../../../../config';
import RightTick from './../assets/righttick.svg';
import axios from 'axios';
import InActiveCalIcon from '../../../../icons/inactivecal.svg';
import Cross from '../../../../tenant/pages/profile/assets/cross.svg';
import { useProfile } from '../../../customHooks/useProfile';

const MealCalendarAdmin = () => {
  const [selectWeek, setSelectWeek] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [monthlyMeals, setMonthlyMeals] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [mealPlusSelection, setMealplusSelection] = useState([]);
  const [selectedMealData, setSelectedMealData] = useState(null);
  const [currentMonthIndex, setCurrentMonthIndex] = useState(
    new Date().getMonth()
  );
  const [currentMonthName, setCurrentMonthName] = useState(
    new Date().toLocaleString('en-US', { month: 'long' }).toLowerCase()
  );
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [weeklyData, setWeeklyData] = useState([]);
  const { currentCustomer } = useProfile();
  const [restaurantTiming, setRestaurantTiming] = useState({});

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const handleOpenModal = (startDate, endDate, weekNo) => {
    setSelectWeek(weekNo);
    setOpenModal(true);
    fetchMealPlusData(startDate, endDate);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectWeek(0);
    setMealplusSelection([]);
  };

  const getRestaurantTiming = async () => {
    try {
      const config = {
        method: 'get',
        url: `${TENANT_URL}/api/restaurant-timing`,
      };
      const response = await axios(config);
      setRestaurantTiming(response.data.data[0]);
    } catch (error) {
      setError('Failed to load meal plan details.');
    }
  };

  const fetchCardsData = async () => {
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    const config = {
      method: 'get',
      url: `${TENANT_URL}/customer/plan/detials/${currentCustomer}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios(config);
      if (response.data) {
        const mealsByDate = {};
        response.data.data.monthly_plan.forEach((plan) => {
          const date = new Date(plan.plan_date);
          const dateKey = date.toISOString().split('T')[0];
          mealsByDate[dateKey] = plan;
        });
        setMonthlyMeals(mealsByDate);
      }
    } catch (error) {
      console.error('Error fetching card data:', error);
    }
  };

  const fetchMealPlusData = async (startDate, endDate) => {
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    const config = {
      method: 'get',
      url: `${TENANT_URL}/customer/meal-plus/${currentCustomer}?from=${startDate}&to=${endDate}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios(config);
      setMealplusSelection(response.data.data);
    } catch (error) {
      console.error('Error fetching card data:', error);
    }
  };

  const fetchWeekCardData = async () => {
    // const currentMonthName = new Date()
    //   .toLocaleString('en-US', { month: 'long' })
    //   .toLowerCase();
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;

    const config = {
      method: 'get',
      url: `${TENANT_URL}/customer/meal-plus/calendar-availabilities/${currentCustomer}?month=${currentMonthName}&year=${currentYear}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios(config);

      // Store available weeks and update cache
      const availableWeeks = response.data.data.available_weeks;
      updateCacheWithNewRows(availableWeeks);
    } catch (error) {
      console.error('Error fetching card data:', error);
    }
  };

  const updateCacheWithNewRows = (availableWeeks) => {
    setWeeklyData((prevWeeklyData) => {
      const updatedWeeklyData = prevWeeklyData.map((week, index) => {
        const weekKey = `week_${index + 1}`;
        const isStar = availableWeeks.includes(weekKey);
        return { ...week, isStar };
      });

      return updatedWeeklyData;
    });
  };

  useEffect(() => {
    getRestaurantTiming();
    fetchCardsData();
  }, []);

  useEffect(() => {
    const data = generateMonthWeeks(currentMonthIndex, currentYear);
    setWeeklyData(data);
    fetchWeekCardData();
  }, [currentMonthIndex, currentYear, currentMonthName]);

  const handlePrevMonth = () => {
    setCurrentMonthIndex((prev) => {
      if (prev === 0) {
        setCurrentYear((year) => year - 1);
        return 11;
      }
      return prev - 1;
    });

    const currentDate = new Date();
    const preMonthDate = new Date(
      currentDate.getFullYear(),
      currentMonthIndex - 1
    );
    const preMonthName = preMonthDate
      .toLocaleString('en-US', { month: 'long' })
      .toLowerCase();

    setCurrentMonthName(preMonthName);
  };

  const handleNextMonth = () => {
    setCurrentMonthIndex((prev) => {
      if (prev === 11) {
        setCurrentYear((year) => year + 1);
        return 0;
      }
      return prev + 1;
    });

    const currentDate = new Date();
    const nextMonthDate = new Date(
      currentDate.getFullYear(),
      currentMonthIndex + 1
    );
    const nextMonthName = nextMonthDate
      .toLocaleString('en-US', { month: 'long' })
      .toLowerCase();

    setCurrentMonthName(nextMonthName);
  };

  const generateMonthData = (month, year) => {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDay = new Date(year, month, 1).getDay();

    return {
      days: daysInMonth,
      firstDay: firstDay,
      events: Array.from({ length: daysInMonth }, (_, i) => {
        const currentDate = new Date(year, month, i + 1);
        const dateKey = currentDate.toISOString().split('T')[0];
        const mealData = monthlyMeals[dateKey];

        const dayName = currentDate.toLocaleDateString('en-US', { weekday: 'long' });
        return {
          date: i + 1,
          dayName: dayName,
          meal: mealData ? mealData.food_meal_name.join(', ') : '',
          mealData: mealData,
          status: mealData ? 'completed' : 'pending',
          isOpen: restaurantTiming[dayName.toLowerCase()] || false,
        };
      }),
    };
  };

  const generateMonthWeeks = (month, year, checkDate = true) => {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDayOfMonth = new Date(year, month, 1).getDay();

    const weeks = [];
    let currentWeek = [];

    // Handle leading empty days for the first week
    for (let i = 0; i < firstDayOfMonth; i++) {
      currentWeek.push(null);
    }

    // Fill the weeks with actual days from the month
    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = new Date(year, month, day);
      currentWeek.push({ date: currentDate });

      // If the week is complete (7 days), push it to weeks
      if (currentWeek.length === 7) {
        weeks.push(currentWeek);
        currentWeek = [];
      }
    }

    // Add trailing null placeholders to the last week if necessary
    while (currentWeek.length > 0 && currentWeek.length < 7) {
      currentWeek.push(null);
    }

    if (currentWeek.length > 0) {
      weeks.push(currentWeek);
    }

    // Helper function to increment the date by one day
    const incrementDate = (date) => {
      if (date) {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + 1);
        return newDate;
      }
      return null;
    };

    // Get current date to compare
    const currentDate = new Date();

    return weeks.map((week) => {
      const startDate = week.find((day) => day)?.date;
      const endDate = [...week].reverse().find((day) => day)?.date;

      // Increment start and end dates by 1 day
      const incrementedStartDate = startDate ? incrementDate(startDate) : null;
      const incrementedEndDate = endDate ? incrementDate(endDate) : null;

      // Check if current date is between startDate and endDate (inclusive)
      const isDateInRange =
        checkDate && incrementedStartDate && incrementedEndDate
          ? currentDate >= incrementedStartDate &&
            currentDate <= incrementedEndDate
          : false;

      return {
        startDate: incrementedStartDate
          ? incrementedStartDate.toISOString().split('T')[0]
          : null,
        endDate: incrementedEndDate
          ? incrementedEndDate.toISOString().split('T')[0]
          : null,
        isStar: false,
        days: week,
        isDateInRange, // New field indicating if current date is in range
      };
    });
  };

  const handleViewAll = (date, mealData) => {
    setSelectedDate(date);
    setSelectedMealData(mealData);
    setIsModalOpen(true);
  };

  const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const weekStyle = {
    backgroundColor: 'rgba(255,255,255,0.5)',
    padding: '15px 10px',
    borderRadius: '15px',
    boxShadow: '0px 4px 4px 0px #00000026',
    marginBottom: '15px',
    fontSize: '15px',
    fontWeight: '600',
    textAlign: 'center',
    cursor: 'pointer',
    height: '119px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #F5F6F8',
    transition: 'all ease 0.3s',
    '&:hover': {
      opacity: 0.8,
    },
  };

  const weekStyleActive = {
    backgroundColor: '#F0645B',
    padding: '15px 10px',
    boxShadow: 'none',
    borderColor: '#F0645B',
  };

  const cardStyle = {
    border: '1px solid rgb(235 235 235)',
    borderRadius: '8px',
  };

  const cardHeader = {
    padding: '8px',
    borderBottom: '1px solid rgb(235 235 235)',
  };

  const cardBody = {
    padding: '8px',
  };

  const renderCalendarGrid = () => {
    const monthData = generateMonthData(currentMonthIndex, currentYear);
    const blanks = Array(monthData.firstDay).fill(null);
    const daysArray = monthData.events;

    return (
      <Grid
        item
        sx={{
          display: 'flex',
          gap: '1.5em',
          paddingBottom: '1em', // Add some padding for spacing
          width: '46vw',
        }}
      >
        {/* Left Week */}
        <Grid
          sx={{
            minWidth: '85px',
          }}
        >
          <Grid
            sx={{
              ...weekStyle,
              borderRadius: '10px',
              paddingY: '10px',
              height: 'auto',
              fontWeight: '700',
            }}
          >
            {`${months[currentMonthIndex].slice(0, 3)}`}
          </Grid>

          {weeklyData.map((element, index) =>
            selectWeek === index + 1 ? (
              <Grid
                sx={{ ...weekStyle, ...weekStyleActive }}
                onClick={() => {
                  handleOpenModal(
                    element.startDate,
                    element.endDate,
                    index + 1
                  );
                }}
              >
                {!!element?.isStar && (
                  <img src={InActiveCalIcon} alt="Active Calendar Icon" />
                )}
                <Typography marginBottom="-4px" fontSize={16} color="white">
                  Week
                </Typography>
                <Typography fontSize={24} color="white">
                  {index + 1}
                </Typography>
              </Grid>
            ) : (
              <Grid
                sx={{ ...weekStyle }}
                onClick={() => {
                  handleOpenModal(
                    element.startDate,
                    element.endDate,
                    index + 1
                  );
                }}
              >
                {!!element?.isStar && (
                  <img src={InActiveCalIcon} alt="Active Calendar Icon" />
                )}
                <Typography marginBottom="-4px" fontSize={16} color="#3F2F5B">
                  Week
                </Typography>
                <Typography fontSize={24} color="#3F2F5B">
                  {index + 1}
                </Typography>
              </Grid>
            )
          )}
        </Grid>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(7, 1fr)',
            gap: 0,
            border: '1px solid #EBEBEC',
            borderRadius: '10px',
            padding: '22px 15px',
            '@media (max-width: 1535px)': {
              overflowX: 'scroll',
            },
          }}
        >
          {weekDays.map((day) => (
            <Box
              key={day}
              sx={{
                textAlign: 'center',
                color: '#3F2F5B',
                fontWeight: 500,
                fontSize: '10px',
                fontFamily: 'Poppins',
              }}
            >
              {day}
            </Box>
          ))}

          {blanks.map((_, index) => (
            <Box key={`blank-${index}`} />
          ))}

          {daysArray.map((event, index) => (
            <Box
              key={`day-${index}`}
              sx={{
                p: 1,
                m: 1,
                border: '1px solid',
                borderColor: 'rgb(229, 229, 229)',
                borderRadius: '8px',
                // borderLeft: event.mealData
                //   ? '2px solid rgb(242, 80, 64)'
                //   : '1px solid rgb(229, 229, 229)',

                borderLeft: '2px solid rgb(242, 80, 64)',
                backgroundColor: (event.isOpen) ? '#fff' : "#E8E8E8",
                minHeight: '100px',
                '@media (max-width: 1535px)': {
                  width: '105px',
                },
                '@media (min-width: 1535px) and (max-width:1700px)': {
                  width: '95px',
                },
                '@media (min-width: 1701px) and (max-width:1850px)': {
                  width: '105px',
                },
                '@media (min-width: 1851px) and (max-width:2200px)': {
                  width: '120px',
                  marginTop: '30px',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 600,
                    color: '#3F2F5B',
                    fontFamily: 'Poppins',
                  }}
                >
                  {`${months[currentMonthIndex].substring(0, 3)} ${String(
                    event.date
                  ).padStart(2, '0')}`}
                </Typography>
                {event.mealData && (
                  <Box
                    sx={{
                      width: 20,
                      height: 20,
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Box
                      component="span"
                      sx={{ color: '#fff', fontSize: '0.75rem' }}
                    >
                      {(event.isOpen) ? <img src={RightTick} /> : <></> }
                      
                    </Box>
                  </Box>
                )}
              </Box>
              {(event.isOpen) ? <>
                            
                {event.mealData && (
                <>
                    <Typography
                      sx={{
                        fontSize: '0.75rem',
                        color: 'rgb(84, 88, 95)',
                        mb: 1,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {event.meal}
                    </Typography>
                  
                    <Button
                      onClick={() => handleViewAll(event.date, event.mealData)}
                      sx={{
                        color: '#3F2F5B',
                        textTransform: 'none',
                        p: 0,
                        minWidth: 'auto',
                        fontSize: '10px',
                        fontWeight: 600,
                        fontFamily: 'Poppins',
                        textDecoration: 'underline',
                        height: '0',
                        '&:hover': {
                          backgroundColor: 'transparent',
                          boxShadow: 'none',
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      View All
                    </Button>
                </>
              )}
              
              </> : <><Typography
                      sx={{
                        fontSize: '0.75rem',
                        color: 'rgb(84, 88, 95)',
                        mb: 1,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      Day Off
                    </Typography></>}
            </Box>
          ))}
        </Box>
      </Grid>
    );
  };

  const handleMealSelect = (index, mealId) => {
    setMealplusSelection((prev) => {
      const updated = [...prev];
      const selectedMeal = updated[index].meal_items.find(
        (meal) => meal.id === mealId
      );

      if (selectedMeal) {
        updated[index].selected_items.push({
          original_item_id: mealId,
          original_item_name: selectedMeal.name,
          replacement_item_id: null,
          replacement_item_name: null,
        });
      }
      return updated;
    });
  };

  const handleDelete = (index, originalItemId) => {
    setMealplusSelection((prev) => {
      const updated = [...prev];
      updated[index].selected_items = updated[index].selected_items.filter(
        (item) => item.original_item_id !== originalItemId
      );
      return updated;
    });
  };

  const handleOptionChange = (index, originalItemId, replacementId) => {
    setMealplusSelection((prev) => {
      const updated = [...prev];
      const selectedItem = updated[index].selected_items.find(
        (item) => item.original_item_id === originalItemId
      );

      if (selectedItem) {
        const replacement = updated[index].option_items.find(
          (option) => option.id === replacementId
        );
        selectedItem.replacement_item_id = replacementId;
        selectedItem.replacement_item_name = replacement.name;
      }
      return updated;
    });
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 600,
              color: '#3F2F5B',
              fontFamily: 'Poppins',
            }}
          >
            {`${months[currentMonthIndex]} ${currentYear}`}
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <IconButton
              onClick={handlePrevMonth}
              sx={{
                border: '1px solid #9E9E9E',
                borderRadius: '50%',
                width: 40,
                height: 40,
              }}
            >
              <ChevronLeft size={20} />
            </IconButton>
            <IconButton
              onClick={handleNextMonth}
              sx={{
                border: '1px solid #9E9E9E',
                borderRadius: '50%',
                width: 40,
                height: 40,
              }}
            >
              <ChevronRight size={20} />
            </IconButton>
          </Box>
        </Box>

        {renderCalendarGrid()}

        <Dialog
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: '16px',
              p: 3,
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 3,
            }}
          >
            <Typography
              sx={{
                fontSize: '1.25rem',
                fontWeight: 500,
                color: '#3F2F5B',
              }}
            >
              {selectedDate &&
                `${months[currentMonthIndex]} ${selectedDate}, ${currentYear}`}
            </Typography>
            <IconButton
              onClick={() => setIsModalOpen(false)}
              sx={{ color: 'rgb(242, 80, 64)' }}
            >
              <X size={24} />
            </IconButton>
          </Box>

          {selectedMealData && (
            <>
              <Box sx={{ mb: 3 }}>
                <Typography
                  sx={{
                    fontSize: '1.125rem',
                    fontWeight: 500,
                    color: '#3F2F5B',
                    mb: 1,
                  }}
                >
                  Meal Items
                </Typography>
                <Typography sx={{ color: 'rgb(107, 114, 128)' }}>
                  {selectedMealData.food_meal_name.join(', ')}
                </Typography>
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography
                  sx={{
                    fontSize: '1.125rem',
                    fontWeight: 500,
                    color: '#3F2F5B',
                    mb: 1,
                  }}
                >
                  Addon
                </Typography>
                <Typography sx={{ color: 'rgb(107, 114, 128)' }}>
                  {selectedMealData.addons_name.join(', ')}
                </Typography>
              </Box>

              <Box sx={{ mb: 4 }}>
                <Typography
                  sx={{
                    fontSize: '1.125rem',
                    fontWeight: 500,
                    color: '#3F2F5B',
                    mb: 1,
                  }}
                >
                 Additional Items 
                </Typography>
                <Typography sx={{ color: 'rgb(107, 114, 128)' }}>
                  {/* {[
                    selectedMealData.is_breakfast && 'Breakfast',
                    selectedMealData.is_lunch && 'Lunch',
                    selectedMealData.is_dinner && 'Dinner',
                  ]
                    .filter(Boolean)
                    .join(', ')} */}
                    {selectedMealData.additional_items.join(', ')}
                </Typography>
              </Box>
            </>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => setIsModalOpen(false)}
              sx={{
                color: 'rgb(242, 80, 64)',
                border: '1px solid rgb(242, 80, 64)',
                borderRadius: '20px',
                px: 4,
                py: 1,
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'rgba(242, 80, 64, 0.04)',
                },
              }}
            >
              Close
            </Button>
          </Box>
        </Dialog>
      </Box>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            maxWidth: '1100px',
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: 24,
            padding: '25px 15px',
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <div className="d-flex justify-content-between align-items-end mb-3">
            <Typography
              variant="h6"
              sx={{ fontSize: '18px', fontWeight: '500', color: '#2F2F3B' }}
            >
              Meal+ Selections
            </Typography>
            <img
              src={Cross}
              alt="Close"
              onClick={handleCloseModal}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <Grid container spacing={2}>
            {mealPlusSelection.map((element, index) => (
              <Grid item md={4} key={index}>
                <Box sx={cardStyle}>
                  <Box sx={cardHeader}>
                    <Typography
                      fontSize={14}
                      fontWeight={700}
                      color={'#3F2F5B'}
                    >
                      {new Date(element.date).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                      })}
                    </Typography>
                  </Box>
                  <Box sx={cardBody}>
                    {!!element.is_available ? (
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 2,
                            justifyContent: 'space-between',
                          }}
                        >
                          {element.selected_items && element.selected_items.length <= 0 ? (
                          <Typography
                            fontSize={12}
                            color="gray"
                          >
                            No meals+ items have been selected
                          </Typography>
                        ) : (
                          <>
                            <Typography
                              fontSize={12}
                              fontWeight={400}
                              color="#FF7676"
                            >
                              Previous
                            </Typography>
                            <Typography
                              fontSize={12}
                              fontWeight={400}
                              color="#FF7676"
                            >
                              Selected
                            </Typography>
                          </>
                        )}

                        </Box>

                        <div>
                          {element.selected_items.map((meal, chipIndex) => (
                            <Box
                              key={chipIndex}
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 2,
                                alignItems: 'center',
                                marginBottom:
                                  chipIndex ===
                                  element.selected_items.length - 1
                                    ? 0
                                    : 2,
                                justifyContent: 'space-between',
                              }}
                            >
                              <Typography
                                fontSize={14}
                                fontWeight={500}
                                color={'#3F2F5B'}
                              >
                                {meal.original_item_name}
                              </Typography>
                              <Typography
                                fontSize={14}
                                fontWeight={500}
                                color={'#3F2F5B'}
                              >
                                {meal.replacement_item_name}
                              </Typography>
                            </Box>
                          ))}
                        </div>
                      </>
                    ) : (
                      <Typography fontSize={12} color="gray">
                        No Meal Plus Available
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2,
              marginTop: 4,
            }}
          >
            <Button
              variant="contained"
              color="error"
              sx={{
                fontSize: '14px',
                fontWeight: '500',
                backgroundColor: '#F0645B',
              }}
              onClick={handleCloseModal}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default MealCalendarAdmin;
