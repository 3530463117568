import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { useProfile } from '../../../customHooks/useProfile';
import EditIcon from './../assets/edit.svg';
import Clock from './../assets/clock.svg';
import NoteCard from './NoteCard';

const ProfileCard = ({ setOpen }) => {
  const {
    profileData,
    loading,
    getCustomerById,
    currentCustomer,
    updateCustomerActivation,
    updateCustomerSubscription,
  } = useProfile();
  const [checked, setChecked] = useState(profileData?.is_active);
  const [checkedSub, setCheckedSub] = useState(
    profileData?.subscription_status
  );


  console.log("admin provile");
  console.log("profileData:",profileData.joining_date);

  const hangelSubscriptionStatus = () => {
    const subs = updateCustomerSubscription();
    console.log('subs:', subs);
  };

  const [date, setDate] = useState('');
  useEffect(() => {
    if (profileData) {
      // debugger;
      setDate(moment(profileData?.joining_date).utc().format('MMM DD, YYYY'));
      setChecked(profileData?.is_active);
      setCheckedSub(profileData?.subscription_status);
    }
  }, [date]);
  const handleStatus = (v, e) => {
    // debugger;
    if (e === 'activPause') {
      setChecked(v);
      updateCustomerActivation(v);
    } else {
      setCheckedSub(v);
      updateCustomerSubscription(v);
    }
  };
  return (
    <>
      {loading ? (
        <></>
      ) : (
        <Box
          sx={{
            p: '15px 15px 15px',
            background:
              'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
            borderRadius: '15px',
            boxShadow: '0px 4px 4px 0px #00000026',
            overflow: 'hidden',
            minWidth: '410px',
            maxWidth: '481px',
          }}
        >
          <Box
            sx={{
              mx: 'auto',
              marginBottom: '22px',
            }}
          >
            <Box
              sx={{
                margin: 'auto',
                width: '116px',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  margin: 'auto',
                  width: '116px',
                  borderRadius: '100%',
                  background: 'white',
                  overflow: 'hidden',

                  position: 'relative',
                }}
              >
                <img src={require('../assets/1.png')} width={'100%'} />
              </Box>
              <Button
                sx={{
                  minWidth: '15px',
                  width: '15px',
                  padding: '2px',
                  position: 'absolute',
                  bottom: '-8px',
                  right: '-20px',
                }}
                onClick={() => {
                  getCustomerById(currentCustomer);
                  setOpen(true);
                }}
              >
                <img src={EditIcon} style={{ width: '28px' }} />
              </Button>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '6px',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '20px',
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: '14px', sm: '16px', xl: '17px' },
                  fontWeight: '600',
                  lineHeight: '21px',
                  letterSpacing: '0px',
                  fontFamily: 'Poppins',
                  textAlign: 'center',
                  marginTop: '10px',
                }}
              >
                {profileData?.name}
              </Typography>
              <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
                {profileData?.email ? profileData?.email : ''}
              </Typography>
              <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
                {profileData?.phone ? profileData?.phone : ''}
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: '400',
                  textAlign: 'center',
                  mr: '5px',
                }}
              >
                {profileData?.address
                  ? (profileData?.unit ? `${profileData.unit}, ` : '') +
                    profileData.address
                  : 'Johar Town, Lahore, Pakistan'}
              </Typography>
            </Box>

            <Typography
              variant="h6"
              sx={{
                color: '#3F2F5B',
                fontWeight: 600,
                fontFamily: 'Poppins',
                marginBottom: '8px',
                textTransform: 'uppercase',
                fontSize: { xs: '16px' }, // Adjusts font size for different breakpoints
              }}
            >
              Subscription Detail
            </Typography>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                marginBottom: '15px',
              }}
            >
              <Box
                sx={{
                  backgroundColor: '#F6EDF4',
                  padding: '12px 15px',
                  borderRadius: 2,
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  flexGrow: 1,
                  gap: '10px',
                  boxShadow: '0px 4px 4px 0px #00000026',
                  flexDirection: 'row', // Stacks items vertically on small screens
                }}
              >
                <div>
                  <img src={Clock} />
                </div>
                <div style={{ textAlign: 'left' }}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: { xs: '10px', md: '11px' }, // Adjusts font size for different breakpoints
                      fontWeight: 400,
                      color: '#3F2F5B',
                    }}
                  >
                    Join Date:
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: { xs: '11px', md: '12px' }, // Adjusts font size for different breakpoints
                      fontWeight: 500,
                      color: '#3F2F5B',
                    }}
                  >
                    {profileData.joining_date}
                  </Typography>
                </div>
              </Box>

              <Box
                sx={{
                  backgroundColor: '#F6EDF4',
                  padding: '12px 15px',
                  borderRadius: 2,
                  textAlign: 'center',
                  flexGrow: 1,
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  boxShadow: '0px 4px 4px 0px #00000026',
                  flexDirection: 'row', // Stacks items vertically on small screens
                }}
              >
                <div>
                  <img src={Clock} />
                </div>
                <div style={{ textAlign: 'left' }}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: { xs: '10px', md: '11px' }, // Adjusts font size for different breakpoints
                      fontWeight: 400,
                      color: '#3F2F5B',
                    }}
                  >
                    Renewal Date:
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: { xs: '11px', md: '12px' }, // Adjusts font size for different breakpoints
                      fontWeight: 500,
                      color: '#3F2F5B',
                    }}
                  >
                    {profileData.renewal_date || "-"}
                  </Typography>
                </div>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                gap: 1,
                justifyContent: 'flex-end',
              }}
            >
              {/* <Button
                variant="contained"
                sx={{
                  backgroundColor: '#F0645B',
                  color: '#fff',
                  borderRadius: 100,
                  padding: '10px 16px',
                  boxShadow: 'none',

                  mb: { xs: 1, sm: 0 },
                  height: '41px',
                  '&:hover': {
                    backgroundColor: '#F0645B',
                  },
                  '@media (min-width: 1200px) and (max-width: 1400px)': {
                    padding: '10px 12px',
                    fontSize: '11px',
                  },
                  '@media (min-width: 1535px) and (max-width: 1600px)': {
                    padding: '10px 12px',
                    fontSize: '12px',
                  },
                }}
              >
                Cancel Subscription
              </Button> */}
              <Button
                variant="outlined"
                sx={{
                  color: '#d32f2f',
                  borderColor: '#d32f2f',
                  borderRadius: 100,
                  padding: '10px 16px',
                  boxShadow: 'none',

                  height: '41px',
                  '&:hover': {
                    backgroundColor: '#F0645B',
                    borderColor: '#F0645B',
                    color: '#fff',
                  },
                  '@media (min-width: 1200px) and (max-width: 1400px)': {
                    padding: '10px 12px',
                    fontSize: '11px',
                  },
                  '@media (min-width: 1535px) and (max-width: 1600px)': {
                    padding: '10px 12px',
                    fontSize: '12px',
                  },
                }}
                onClick={hangelSubscriptionStatus}
              >
                {!!profileData?.subscription_status
                  ? 'Pause Subscription'
                  : 'Restart Subscription'}
              </Button>
            </Box>
          </Box>

          <NoteCard />
        </Box>
      )}
    </>
  );
};

export default ProfileCard;
