import { Box, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    border: 'none',
    height: '36px',
    fontSize: '14px',
  },
});

function FormModalInputText({
  label,
  placeholder,
  passedRef,
  type,
  value,
  onChange,
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box sx={{ width: '100%' }}>
        <InputLabel htmlFor="my-input" sx={{ mb: 1, fontWeight: '500' }}>
          {label}
        </InputLabel>
        <StyledTextField
          inputRef={passedRef}
          placeholder={placeholder}
          variant="outlined"
          type={type}
          value={value}
          size="small"
          onChange={onChange}
          sx={{ width: '100%', borderRadius: '10px' }}
        />
      </Box>
    </Box>
  );
}

export default FormModalInputText;
