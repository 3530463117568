import { Grid, Typography, Link } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState, useEffect } from 'react';
import three_plates from './images/three-plates.svg';
import mainLogo from './images/main-logo.svg';
import './Header.css';

import LoginModal from '../../../components/modals/loginmodal';
import SignUpModal from '../../../components/modals/signupmodal';
import BannerModel from '../../../components/modals/bannerModel';

import axios from 'axios';
import { TENANT_URL } from '../../../../../../config';

function Header() {
  const [activeCategory, setActiveCategory] = useState('All');

  const [openModal, setOpenModal] = useState(false);
  const [bannerList, setBannerList] = useState([]);
  const [randomVal, setRandomVal] = useState(0);
  const [logo, setLogo] = useState('');
  const [tokenData, setTokenData] = useState({});
  const [bannerData, setBannerData] = useState('');
  // const storedLogo = localStorage.getItem('app-logo');
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // SingUp Modal
  const [openModal1, setOpenModal1] = useState(false);
  const handleCloseModal1 = () => {
    setOpenModal1(false);
  };
  const handleOpenModal1 = () => {
    setOpenModal1(true);
  };

  const [openModal2, setOpenModal2] = useState(false);
  const handleCloseModal2 = () => {
    setOpenModal2(false);
  };
  const handleOpenModal2 = () => {
    setOpenModal2(true);
  };

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };
  const dishTitleStyle = {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '29px',
    textAlign: 'center',
    color: '#455149',
  };
  const dishSubTitleStyle = {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '29px',
    textAlign: 'center',
    color: '#858585',
  };

  const getLogo = async () => {
    const config = {
      method: 'get',
      url: `${TENANT_URL}/api/restaurant/logo`,
    };

    try {
      const response = await axios(config);
      const logo = response.data.data.logo;
      setLogo(logo);
      localStorage.setItem('app-logo', logo);
      return logo;
    } catch (error) {
      console.error('Error fetching logo:', error);
    }
  };

  const handleBanner = async () => {
    const pathname = window.location.pathname;
    const token = localStorage.getItem('tenantdata');

    if (!token && pathname === '/' && window.self === window.top) {
      const getConfig = {
        method: 'get',
        url: `${TENANT_URL}/api/landing-popup-banner`,
        headers: {
          'Content-Type': 'application/json',
        },
      };

      try {
        const response = await axios(getConfig);
        setBannerData(response.data.data);
        setOpenModal2(true);
      } catch (error) {
        console.error(error);
      }
    } else if (window.self !== window.top) {
      console.log('Page is in an iframe. Skipping API call.');
    } else {
      setTokenData(JSON.parse(token));
      console.log('Token exists. Skipping API call.');
    }
  };

  useEffect(() => {
    // banners();
    getLogo();
    handleBanner();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('tenantdata');
    localStorage.removeItem('loginType');
    localStorage.removeItem('selectedRoute');
    window.location.reload();
  };

  return (
    <>
      <header>
        <div className="left">
          <img src={logo} alt="Logo" className="logo logo3" />
        </div>

        <nav>
          <ul className="nav-list m-0">
            <li>
              <a className="active" href="#">
                Home
              </a>
            </li>
            <li>
              <a href="#">How it works</a>
            </li>
            <li>
              <a href="#">Our Work</a>
            </li>
            <li>
              <a href="#">Pricing</a>
            </li>
            <li>
              <a href="#">Contact Us</a>
            </li>
          </ul>
        </nav>

        <div className="right_btns">
          {tokenData && tokenData.name ? (
            <>
              {localStorage.getItem('loginType') == 'customer' ? (
                <a href="/customer/profile">{tokenData.name}</a>
              ) : (
                <a href="/admin">{tokenData.name}</a>
              )}
              <button onClick={handleLogout}>Logout</button>
            </>
          ) : (
            <>
              <Link onClick={handleOpenModal} href={'javascript:void(0)'}>
                Login
              </Link>
              <button onClick={handleOpenModal1}>Create Account</button>
            </>
          )}
        </div>
      </header>

      <section className="hero_main_section mt-5 position-relative">
        <div className="four_bg_circles"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="hero_sec_left">
                <h1>
                  Discover Heart of Culinary <br />
                  <span>Excellence</span>
                </h1>
                <p>
                  Step into a world where flavors come alive and culinary dreams
                  are realized. We blend tradition with innovation to bring you
                  an unforgettable dining experience.
                </p>
                <button>Order Now</button>
              </div>
            </div>
            <div className="col-md-6">
              <div className="right_banner">
                <img
                  className="w-100"
                  src={three_plates}
                  alt="Culinary Excellence"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <LoginModal open={openModal} onClose={handleCloseModal} />
      <SignUpModal open={openModal1} onClose={handleCloseModal1} />
      <BannerModel
        open={openModal2}
        onClose={handleCloseModal2}
        data={bannerData}
      />
    </>
  );
}

export default Header;
