import { Box, Typography } from "@mui/material";
import React from "react";
import './Footer9.css';


function Footer9() {
  console.log("footer 9")
  const AStyle = {
    cursor: "pointer",
    color: 'var(--White, #FFF)',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '125%',
    textDecoration: "none",
  };

  return (
    <>
     <div className="footer_d">
      <div className="container-fluid">
        <footer>
          <div className="register_now_box container">
            <div className="row">
              <div className="col-4">
                <h6>Register now so you don't miss Any Update</h6>
              </div>
              <div className="col-8">
                <div className="search_subs_now">
                  <input type="text" placeholder="Enter your Email" />
                  <button>Subscribe Now</button>
                </div>
              </div>
            </div>
          </div>
          <div className="ul_menus_social">
            <div className="row">
              <div className="col-6">
                <ul className="d-flex align-items-center">
                  <li>Home</li>
                  <li>Menu</li>
                  <li>About</li>
                  <li>Contact</li>
                </ul>
              </div>
              <div className="col-6 text-end">
                <div className="social_icons">
                  <a href="#" className="me-3"><i className="fab fa-facebook" style={{ color: 'black' }}></i></a>
                  <a href="#" className="me-3"><i className="fab fa-instagram" style={{ color: 'black' }}></i></a>
                  <a href="#" className="me-3"><i className="fab fa-twitter" style={{ color: 'black' }}></i></a>
                  <a href="#"><i className="fab fa-youtube" style={{ color: 'black' }}></i></a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
    </>
  );
}

export default Footer9;
