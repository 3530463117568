import { Grid } from '@mui/material';
import React from 'react';
import Datacard from './cards/dataCard';
import { useProfile } from '../../../customHooks/useProfile';

const DataCards = () => {
  const { cardsData } = useProfile();
  const cardsDataTemplate = [
    {
      message: 'Orders',
      image: 'orders',
      carddata: cardsData?.order_count || '0',
    },
    {
      message: 'Subscription Plan',
      image: 'type',
      carddata: cardsData?.subscription_plan || '',
    },
    {
      message: 'Delivered / Remaining',
      image: 'name',
      carddata: cardsData?.plan_name || '',
    },
    {
      message: 'In Wallet',
      image: 'wallet',
      carddata: `${cardsData?.wallet_amount || '$0'}`,
    },
    {
      message: 'Remaining Meal',
      image: 'renewal',
      carddata: cardsData?.remaining_meal || '0',
    },
    {
      message: 'Subscription Plan',
      image: 'messagesleft',
      carddata: cardsData?.subscription_plan
        ? cardsData.subscription_plan
        : '',
    },
  ];

  return (
    <>
      <Grid container spacing={'15px'}>
        {cardsDataTemplate.map((card, index) => {
          return (
            <Grid item xs={12} sm={12} lg={6} xl={4}>
              <Datacard
                key={index}
                message={card.message}
                image={card.image}
                data={card.carddata}
                index={index}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default DataCards;
