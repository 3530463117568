import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Pagination,
  Paper,
  Box,
} from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { TENANT_URL } from '../../../config';

// Custom Styled Components
const CustomPaper = styled(Paper)(({ theme }) => ({
  // padding: "16px",
  borderRadius: '10px',
  boxShadow: '0px 14px 42px 0px #080F340F',
}));

const CustomPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPaginationItem-root': {
    borderRadius: '50%',
    width: '40px',
    height: '40px',
  },
  '& .Mui-selected': {
    backgroundColor: '#F0645B !important', // Active page background color
    color: '#FFFFFF', // Active page text color
    fontWeight: 'bold', // Optional: Make it bold
  },

}));

const getStatusChip = (status) => {
  const commonStyles = {
    fontSize: '12px',
    width: '105px',
    height: '28px',
    padding: '6px 5px',
    gap: '10px',
    borderRadius: '14px',
    opacity: 1, // Ensuring it's visible
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  switch (status) {
    case 'Delivered':
      return (
        <Chip
          label="Delivered"
          style={{
            ...commonStyles,
            backgroundColor: '#D9FFD9',
            color: '#007F00',
          }}
        />
      );
    case 'Pending':
      return (
        <Chip
          label="Pending"
          style={{
            ...commonStyles,
            backgroundColor: '#FFE0E0',
            color: '#D30000',
          }}
        />
      );
    case 'In Progress':
      return (
        <Chip
          label="In Progress"
          style={{
            ...commonStyles,
            backgroundColor: '#FFF0D4',
            color: '#E49A08',
          }}
        />
      );
    default:
      return null;
  }
};

const PaymentHistory = () => {
  const [data, setData] = useState([]);

  const fetchDataSubscription = async (url) => {
    const endpoint = `${TENANT_URL}${url}`;
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    console.log('$$$$authToken', authToken);
    try {
      const response = await axios.get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      setData(response.data.data);

      console.log('responseeeee----', response);
    } catch (error) {}
  };

  useEffect(() => {
    fetchDataSubscription('/customer/app/transaction/history');
  }, []);
  return (
    <Box
      sx={{
        padding: '20px 2.5em',
        maxWidth: '100%',
        margin: '0 auto',
        height: '120vh',
      }}
    >
      <CustomPaper>
        {/* Table and Pagination Wrapper */}
        <Box>
          {/* Table */}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      fontWeight="500"
                      fontSize={13}
                      textAlign={'start'}
                    >
                      Sr.#
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontWeight="500"
                      fontSize={13}
                      textAlign={'center'}
                    >
                      Payment Method
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontWeight="500"
                      fontSize={13}
                      textAlign={'center'}
                    >
                      Payment Date
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontWeight="500"
                      fontSize={13}
                      textAlign={'center'}
                    >
                      Payment Amount
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell
                      sx={{
                        fontSize: '13px',
                        fontWeight: '400',
                        textAlign: 'start',
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: '13px',
                        fontWeight: '400',
                        textAlign: 'center',
                      }}
                    >
                      {row.payment_type}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: '13px',
                        fontWeight: '400',
                        textAlign: 'center',
                      }}
                    >
                      {row.date}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: '13px',
                        fontWeight: '400',
                        textAlign: 'center',
                      }}
                    >
                      {row.amount}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination */}
        </Box>
      </CustomPaper>
      <Box display="flex" justifyContent="end" marginTop="16px" padding="16px">
        <CustomPagination
          count={10}
          variant="outlined"
          shape="rounded"
          sx={{ justifyContent: 'end' }}
        />
      </Box>
    </Box>
  );
};

export default PaymentHistory;
