import { Box, Typography } from "@mui/material";
import React from "react";
import Dashes from "../../assets/icons/dashes.svg";
import Discfill from "../../assets/icons/discordfill.svg";
import Twitterfill from "../../assets/icons/twitterfill.svg";
import FBfill from "../../assets/icons/fbfill.svg";
import Instafill from "../../assets/icons/instafill.svg";

function Footer2() {
  console.log("footer 2")
  const AStyle = {
    color: "#12110B",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "16px",
    textDecoration: "none",
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding:'30px 0',

        }}
      >
        <Box sx={{marginBottom:'80px'}}>
          <Typography
            sx={{
              color: "#12110B",
              textAlign: "center",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "24px",
            }}
          >
            LOGO
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "80px",
            marginBottom:'56px',
          }}
        >
          <a href="/" style={AStyle}>
            About
          </a>
          <a href="/" style={AStyle}>
            Privacy
          </a>
          <a href="/" style={AStyle}>
            Terms
          </a>
          <a href="/" style={AStyle}>
            Cookies
          </a>
          <a href="/" style={AStyle}>
            Help
          </a>
        </Box>
            
        <Box sx={{marginBottom:'56px',}}>
          <img src={Dashes} alt="" srcset="" />
        </Box>
        <Box sx={{ display: "flex",marginBottom:'56px',gap:'32px' }}>
          <img src={Discfill} alt="" srcset="" />
          <img src={Twitterfill} alt="" srcset="" />
          <img src={FBfill} alt="" srcset="" />
          <img src={Instafill} alt="" srcset="" />
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#475569",
              fontFamily: "Outfit",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "16px",
            }}
          >
            @Copyrights2022. AllRightsReserved
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default Footer2;
