import { ListItemButton, ListItemText } from '@mui/material';
import React, { cloneElement } from 'react';
import { Link } from 'react-router-dom';

import {
  buttonStyles,
  buttonTextStyles,
  selectedButtonStyles,
} from '../config';

const selectedButtonTextStyles = {
  '& .MuiListItemText-primary': {
    color: '#F96C2F',
    paddingLeft: '8px',
  },
};

const SidebarItem = ({ name, path, onClick, src, selectedButton }) => {
  return (
    <Link key={name} to={path} style={{ textDecoration: 'none' }}>
      <ListItemButton
        key={name}
        onClick={onClick}
        sx={selectedButton === name && "showHoverSidebar" ? selectedButtonStyles : buttonStyles}
      >
        {cloneElement(src, {
          style: {
            fill: selectedButton === name ? '#F96C2F' : 'white',
            width: '25px',
            height: '22px',
          },
        })}
        <ListItemText
          key={name}
          sx={
            selectedButton === name
              ? selectedButtonTextStyles
              : buttonTextStyles
          }
          primary={name}
        />
      </ListItemButton>
    </Link>
  );
};

export default SidebarItem;
