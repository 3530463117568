import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Chip,
  IconButton,
  Badge,
  CircularProgress,
  Autocomplete,
} from '@mui/material';
import { styled } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

import dayjs from "dayjs";


import { TENANT_URL } from '../../../config';

/** Custom Styled Accordion */
const StyledAccordion = styled(Accordion)(({ theme }) => ({
  padding: '4px',
  boxShadow: 'none',
  '&:before': { display: 'none' },
  '& .MuiAccordionSummary-root': {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  '& .MuiAccordionSummary-root:last-child': { borderBottom: 'none' },
  '& .MuiChip-root': { backgroundColor: '#F0645B' },
  '& .MuiChip-label': { color: 'white' },
  '& .MuiBadge-badge': { color: 'white', backgroundColor: '#F0645B' },
  '& .MuiAccordionDetails-root': { padding: '5px 16px 16px' },
}));

const AdditionalMenuModal = ({ open, handleClose, isUpdated , date }) => {
    const [additionalMenu, setAdditionalMenu] = useState([]);
    const [loading, setLoading] = useState(false);

    const [mealItems, setMealItems] = useState({
        Everyday: [],
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: [],
    });

    const handleAddMeal = (day, meal) => {
        if (meal) {
        let mealID = 0;

        Object.values(mealItems).forEach((entity) => {
            entity.forEach((element) => {
            if (element.name == meal) {
                mealID = element.id;
            }
            });
        });

        setMealItems((prev) => ({
            ...prev,
            [day]: [...prev[day], meal],
        }));
        }
    };

    const handleUpdateAdditionalTemp = async () => {

        const formattedDate = dayjs(date);
        const month = formattedDate.format("MMMM").toLowerCase(); // "January" -> "january"
        const year = formattedDate.format("YYYY"); // "2025"

        try {
        const response = await axios.post(
            `${TENANT_URL}/api/cart/temp-additional-items`,
            {
                month: month,
                year: year,
                custom_menu: { ...mealItems },
            },
            {
            headers: {
                'Content-Type': 'application/json',
            },
            }
        );

        console.log("response:",response);
        

            console.log("response:",response);
            localStorage.setItem("additinoalitemsarray", JSON.stringify(mealItems))
            localStorage.setItem("additinoalitems", response.data.data.items)
            localStorage.setItem("additinoalitemTotal", response.data.data.total_price)
            handleClose(response);
        } catch (error) {
        console.error(`Error fetching data from :`, error);
        }
    };

    const handleUpdateAddi = () => {
        handleUpdateAdditionalTemp()
    }

  const getAllMealItems = async () => {
    try {
      const response = await axios.get(`${TENANT_URL}/api/meal-items`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log("setAdditionalMenu:",response)
      setAdditionalMenu(response.data)
      setLoading(false);
    } catch (error) {
      console.error(`Error fetching data from ${url}:`, error);
    }
  }

  useEffect(() => {
    getAllMealItems()
    setMealItems(JSON.parse(localStorage.getItem("additinoalitemsarray")) || {Everyday: [],Monday: [],Tuesday: [],Wednesday: [],Thursday: [],Friday: [],Saturday: [],Sunday: [],});
    // console.log("asdasdasdasdASDASD",localStorage.getItem("additinoalitemsarray") || {Everyday: [],Monday: [],Tuesday: [],Wednesday: [],Thursday: [],Friday: [],Saturday: [],Sunday: [],});
  }, []);

  const handleDeleteMeal = (day, meal) => {
    setMealItems((prev) => ({
      ...prev,
      [day]: prev[day].filter((item) => item !== meal),
    }));
  };

  console.log("mealItems:",mealItems);
  
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Additional Menu Items
        <IconButton
          onClick={handleClose}
          style={{ position: 'absolute', right: 16, top: 4 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {/* Individual Days Accordion */}
            {Object.keys(mealItems).map((day, index) => (
              <StyledAccordion key={index}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    sx={{ gap: '15px', display: 'flex', alignItems: 'center' }}
                  >
                    {day == 'Everyday'
                      ? 'Ongoind Items'
                      : day.charAt(0).toUpperCase() + day.slice(1)}{' '}
                    <Badge
                      badgeContent={mealItems[day].length}
                      color="primary"
                      style={{ marginLeft: 8 }}
                    />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
                    {mealItems[day].map((meal, idx) => {
                      const findMeal = additionalMenu.find(
                        (item) => item.id == meal
                      );

                      return (
                        <Chip
                          key={idx}
                          label={findMeal?.name}
                          onDelete={() => handleDeleteMeal(day, meal)}
                          color="secondary"
                        />
                      );
                    })}
                  </div>
                  <Autocomplete
                    fullWidth
                    options={additionalMenu.filter(
                      (item) => !mealItems[day].includes(item.id) // Exclude already selected items for the day
                    )} 
                    getOptionLabel={(option) => option.name || ''} // Show item name
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Item and hit enter"
                        variant="outlined"
                      />
                    )}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && e.target.value) {
                        const selectedOption = additionalMenu.find(
                          (item) => item.name === e.target.value
                        );
                        if (selectedOption && !mealItems[day].includes(selectedOption.id)) {
                          // Check if the item is not already added
                          handleAddMeal(day, selectedOption.id); // Add the selected item by ID
                          e.target.value = ''; // Clear the input field
                        }
                      }
                    }}
                    onChange={(event, newValue) => {
                      if (newValue && !mealItems[day].includes(newValue.id)) {
                        // Check if the item is not already added
                        handleAddMeal(day, newValue.id); // Add the selected item by ID
                      }
                    }}
                    style={{ marginTop: 16 }}
                    sx={{
                      '.MuiInputBase-root': {
                        padding: '5px',
                      },
                      '.MuiButtonBase-root': {
                        height: '30px',
                      },
                    }}
                  />

                </AccordionDetails>
              </StyledAccordion>
            ))}
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ gap: '15px' }}>
        <Button
          onClick={() =>
            setMealItems({
              Everyday: [],
              Monday: [],
              Tuesday: [],
              Wednesday: [],
              Thursday: [],
              Friday: [],
              Saturday: [],
              Sunday: [],
            })
          }
          color="secondary"
          style={{ border: '1px solid #F0645B', color: '#F0645B' }}
        >
          Reset
        </Button>
        <Button
          // onClick={handleClose}
          onClick={handleUpdateAddi}
          variant="contained"
          color="primary"
          style={{ backgroundColor: '#F0645B' }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdditionalMenuModal;
