import { Box, Typography } from '@mui/material';
import React from 'react';
import InputForm from './subscription/inputForm';

const UpgradeSubscription = () => {
  return (
    <>
      <Box
        sx={{
          // display:'none',
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
          p: '25px',
          background:
            'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
          borderRadius: '15px',
          boxShadow: '0px 4px 4px 0px #00000026',

          width: { md: '100%', xs: '98%' },
          height: 'auto',
        }}
      >
        <InputForm />
      </Box>
    </>
  );
};

export default UpgradeSubscription;
