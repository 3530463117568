import React, { useEffect, useState } from 'react';
import bannerRight from './images/banner-right.svg';
import './Header.css';
import LoginModal from '../../../components/modals/loginmodal';
import BannerModel from '../../../components/modals/bannerModel';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import SignUpModal from '../../../components/modals/signupmodal';
import axios from 'axios';
import { TENANT_URL, getLOGO } from './../../../../../../config';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import { ShoppingCart } from 'lucide-react';
import { useParams, useNavigate } from 'react-router-dom';

import { ServiceNavbar } from '../../../serviceNarbar';

const FoodSearch = () => {
  const [items, setItems] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const hidelist = (e) => {
    // setSearchValue('');
    setItems([]);
  };

  const handleSearchApi = async () => {
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/api/search?s=${searchValue}`,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios(getConfig);
      setItems(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const iconsStyle = {
    color: '#F96221',
    position: 'absolute',
    top: 6,
    right: 6,
    cursor: 'pointer',
    zIndex: 2,
  };

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Box
        sx={{
          margin: 'auto',
          borderRadius: '10px',
          border: '1px solid #000000',
          padding: '10px',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Select
            defaultValue="Meals"
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none', // Removes the default border
              },
              '&.MuiOutlinedInput-root': {
                boxShadow: 'none', // Removes shadow if present
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: 'none', // Ensures no border on hover
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none', // Ensures no border on focus
              },
            }}
          >
            <MenuItem value="Meals">Meals</MenuItem>
          </Select>
          <Box
            sx={{
              height: '30px',
              width: '1px',
              backgroundColor: '#000000',
              marginX: 2,
              opacity: 0.2,
            }}
          />
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search"
            sx={{
              '& .MuiOutlinedInput-root': {
                paddingRight: 0,
                '& fieldset': { borderColor: 'transparent' },
                '&:hover fieldset': { borderColor: 'transparent' },
                '&.Mui-focused fieldset': { borderColor: 'transparent' },
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      '&.MuiIconButton-sizeMedium': {
                        padding: '0 18px',
                        '&:hover': { backgroundColor: '#F96221', opacity: 0.7 },
                      },
                    }}
                    onClick={handleSearchApi}
                  >
                    <SearchIcon sx={{ color: '#ffffff' }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => handleSearch(e)}
          />
        </Box>
      </Box>
      {items.length > 0 && searchValue && (
        <List
          style={{
            position: 'absolute',
            width: '100%',
            left: '0',
            border: '1px solid #000000',
            padding: '10px',
            borderRadius: '10px',
            top: 5,
            zIndex: 9,
            backgroundColor: '#ffffff',
            marginTop: '70px',
          }}
        >
          <CancelIcon onClick={hidelist} sx={iconsStyle} />
          {items.map((item) => (
            <ListItem key={item.id} divider>
              <ListItemAvatar>
                <Avatar
                  src={item.image ? item.image : './images/salad.jpg'}
                  alt={item.name}
                />
              </ListItemAvatar>
              <ListItemText
                primary={item.name}
                sx={{
                  '& .MuiTypography-body1': {
                    fontFamily: 'Inter !important',
                  },
                }}
                secondary={
                  <Typography
                    sx={{
                      fontSize: '12px !important',
                      fontFamily: 'Inter !important',
                      paddingBottom: '0 !important',
                      lineHeight: '22px !important',
                    }}
                    color="text.secondary"
                  >
                    {item.description}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

function Header() {
  // Login Modal
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [bannerList, setBannerList] = useState([]);
  const [randomVal, setRandomVal] = useState(0);
  const [logo, setLogo] = useState('');
  const [bannerData, setBannerData] = useState('');
  // const storedLogo = localStorage.getItem('app-logo');
  // const [tokenData, setTokenData] = useState({});

  let tokenData = localStorage.getItem('tenantdata');
  if (tokenData) {
    tokenData = JSON.parse(tokenData);
  }

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // SingUp Modal
  const [openModal1, setOpenModal1] = useState(false);
  const handleCloseModal1 = () => {
    setOpenModal1(false);
  };
  const handleOpenModal1 = () => {
    setOpenModal1(true);
  };

  const [openModal2, setOpenModal2] = useState(false);
  const handleCloseModal2 = () => {
    setOpenModal2(false);
  };
  const handleOpenModal2 = () => {
    setOpenModal2(true);
  };

  const getLogo = async () => {
    const config = {
      method: 'get',
      url: `${TENANT_URL}/api/restaurant/logo`,
    };

    try {
      const response = await axios(config);
      const logo = response.data.data.logo;
      setLogo(logo);
      localStorage.setItem('app-logo', logo);
      return logo;
    } catch (error) {
      console.error('Error fetching logo:', error);
    }
  };

  const handleBanner = async () => {
    const token = localStorage.getItem('userdata');
    const pathname = window.location.pathname;
    if (!token && pathname === '/' && window.self === window.top) {
      const getConfig = {
        method: 'get',
        url: `${TENANT_URL}/api/landing-popup-banner`,
        headers: {
          'Content-Type': 'application/json',
        },
      };

      try {
        const response = await axios(getConfig);
        setBannerData(response.data.data);
        setOpenModal2(true);
      } catch (error) {
        console.error(error);
      }
    } else if (window.self !== window.top) {
      console.log('Page is in an iframe. Skipping API call.');
    } else {
      console.log('Token exists. Skipping API call.');
    }
  };

  useEffect(() => {
    // banners();
    getLogo();
    handleBanner();
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('tenantdata');
    localStorage.removeItem('loginType');
    localStorage.removeItem('selectedRoute');
    window.location.reload();
  };

  return (
    <>
      {/* Header Section */}
      <ServiceNavbar />
      <div className="paBoth">
        <div className="row">
          <div className="col-md-12">
            
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              sx={{
                top: 74,
                '& .MuiPopover-paper': {
                  boxShadow: 'none',
                  borderRadius: '11px',
                  minWidth: '170px',
                  border: '1px solid #f5f5f5',
                },
                '& .MuiList-padding': {
                  paddingTop: 0,
                  paddingBottom: 0,
                  overflow: 'hidden',
                },
                '& .MuiMenuItem-gutters': {
                  padding: '10px 18px',
                  border: '1px solid #f5f5f5',
                  textDecoration: 'none',
                  color: '#000000',
                  '&:hover': {
                    backgroundColor: 'rgba(250,98,33,0.15)',
                  },
                },
              }}
            >
              <MenuItem
                sx={{
                  backgroundColor: 'rgba(250,98,33,0.15)',
                }}
                component="a"
                href={
                  tokenData && !tokenData.tenant_nav_bar_permission
                    ? '/customer/profile'
                    : '/admin'
                }
              >
                Profile
              </MenuItem>
              {tokenData && !tokenData.tenant_nav_bar_permission && (
                <>
                  <MenuItem component="a" href="/customer/subscription">
                    Subscription
                  </MenuItem>
                  <MenuItem component="a" href="/customer/orderhistory">
                    Order History
                  </MenuItem>
                  <MenuItem component="a" href="/customer/paymenthistory">
                    Payment History
                  </MenuItem>
                </>
              )}
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </div>
      </div>

      <section className="hero_main_section">
        <div className="paBoth ">
          <div className="row align-items-center pt-5">
            <div className="col-md-6">
              <div className="hero_sec_left ps-0">
                <h1>
                  Discover Heart of Culinary Excellence
                  <br />
                </h1>
                <p>
                  Step into a world where flavors come alive and culinary dreams
                  are realized. We blend tradition with innovation to bring you
                  an unforgettable dining experience.
                </p>
                {/* <button>Order Now</button> */}
                <FoodSearch />
              </div>
            </div>
            <div className="col-md-6">
              <div className="right_banner">
                <img
                  className="w-100"
                  src={
                    'https://dininglabs.ca/banner/images/Frame 1000001201.png'
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <LoginModal open={openModal} onClose={handleCloseModal} />
      <SignUpModal open={openModal1} onClose={handleCloseModal1} />
      <BannerModel
        open={openModal2}
        onClose={handleCloseModal2}
        data={bannerData}
      />
    </>
  );
}

export default Header;
