import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { TENANT_URL, TENANT_ID } from '../../config';
import { Calculate } from '@mui/icons-material';
import { useSnackbar } from '../../components/utils/snackbarProvider/SnackbarContextProvider';
import lodash from 'lodash';
import dayjs from 'dayjs';

const MenuPlanContext = createContext();

export function useFoodMenuPlan() {
  return useContext(MenuPlanContext);
}
export const FoodMenuPlanProvider = ({ children }) => {
  const [mealItems, setMealItems] = useState([]);
  const [allMealItems, setAllMealItems] = useState([]);
  const [adonsItem, setAdonsItems] = useState([]);
  const [planCategories, setPlanCategories] = useState([]);
  const [restaurantDayOff, setRestaurantDayOff] = useState(null);
  const [selectedCardDate, setSelectedCardDate] = useState('');
  const [selectedCarditem, setSelectedCarditem] = useState('');
  const [planPrice, setPlanPrice] = useState([]);

  const [newPlanPrice, setNewPlanPrice] = useState([]);
  const [planList, setPlanList] = useState([]);
  const [planDropdownList, setPlanDropdownList] = useState([]);
  const [planListView, setPlanListView] = useState([]);
  const [planPriceFinal, setPlanPriceFinal] = useState(null);
  const { show } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const [currentPage, setCurrentPage] = useState(0);
  const [links, setLinks] = useState(null);
  const [meta, setMeta] = useState([]);

  const [plan, setPlan] = useState(0);

  const [imageFile, setImageFile] = useState('');
  const [uplatedPlanName , setUplatedPlanName] = useState('');
  const [uplatedPlanDescription, setUplatedPlanDescription] = useState('');






  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };

  console.log("imageFile outside:",imageFile);
  const [monthly_plan, setDayCardObj] = useState({
    meal_id: 0,
    plan_date: '',
    is_breakfast: false,
    is_lunch: false,
    is_dinner: false,
    menu_plan_id: 0,
    addons_ids: [],
    addons_name: [],
  });
  const [menuPlanObj, setMenuPlanObj] = useState([
    {
      id: 0,
      name: '',
      plan_month: '',
      description: '',
      plan_price: 0,
      plan_category_id: 0,
      monthly_plan: [],
    },
  ]);
  var initialStateNewMenuPlanObj = {
    id: 0,
    name: '',
    description: '',
    plan_month: '',
    plan_price: 0,
    plan_category_id: 0,
    monthly_plan: [],
  };
  const [newMenuPlanObj, setNewMenuPlanObj] = useState(
    initialStateNewMenuPlanObj
  );

  const [initialUpdateMenuPlanObjs, setInitialUpdateMenuPlanObjs] = useState(
    {}
  );
  const [updateMenuPlanObj, setUpdateMenuPlanObj] = useState({});

  const getPlanList = async (month, year) => {
    const url = `/menu/plan/calender?month=${month}&year=${year}`;
    const endpoint = `${TENANT_URL}${url}`;
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    try {
      setLoading(true);

      const response = await axios.get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      setPlanList(response.data.data);
      setLoading(false);

      console.log('setInitialUpdateMenuPlanObjs Updated in get call');
      // debugger;
      const deepClone = lodash.cloneDeep(response.data.data);
      setInitialUpdateMenuPlanObjs(deepClone);
    } catch (error) {
      setLoading(false);
      console.error('Error making GET request ', error);
    }
  };

  useEffect(() => {
    getListViewPlans(1);
    getAllMealItems();
  }, []);
  useEffect(() => {
    if (currentPage != 0) getListViewPlans(currentPage);
  }, [currentPage]);
  const getListViewPlans = async (pageNumber) => {
    const url = `/menu/plan/calenderlistview`;
    const endpoint = `${TENANT_URL}${url}`;
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    try {
      const response = await axios.get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          page: pageNumber,
        },
      });
      setPlanListView(response.data.data);
      setLinks(response.data.links);
      setMeta(response.data.meta);
      console.log('GET request successful ' + name, response.data.data);
    } catch (error) {
      console.error('Error making GET request ' + name, error);
    }
  };

  const fetchData = async (url, setData, name = '') => {
    const endpoint = `${TENANT_URL}${url}`;
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    // setLoading(true);
    try {
      const response = await axios.get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      setData(response.data.data);
      setLoading(false);

      console.log('GET request successful 11' + name, response.data.data);
    } catch (error) {
      console.error('Error making GET request 11' + name, error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!adonsItem.length) {
      fetchData('/active/addons', setAdonsItems);
    }
  }, [adonsItem]);

  // useEffect(() => {
  //   var month = dayjs().month() + 1;
  //   var year = dayjs().year();
  //   getPlanDropdownList(month, year);
  // }, []);

  // const getPlanDropdownList = async (month, year) => {
  //   fetchData(
  //     `/menu/plan/list/dropdown?month=${month}&year=${year}`,
  //     setPlanDropdownList
  //   );
  // };
  const getPlanCategories = async () => {
    if (!planCategories.length) {
      fetchData('/active/plan/cat', setPlanCategories);
    }
  };
  const getMealItems = async (day) => {
    let dayQueryString = day ? `?day=${day}` : '';
    const endpoint = `${TENANT_URL}/select/calendar/meal${dayQueryString}`;
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    try {
      const response = await axios.get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      setMealItems(response.data.data);

      console.log('GET request getMealItems successful', response.data);
    } catch (error) {
      console.error('Error making getMealItems request', error);
    }
  };
  const getAllMealItems = async () => {
    const endpoint = `${TENANT_URL}/select/calendar/meal`;
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    try {
      const response = await axios.get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      setAllMealItems(response.data.data);

      console.log('GET request getAllMealItems successful', response.data);
    } catch (error) {
      console.error('Error making getAllMealItems request', error);
    }
  };
  const CreatNewDayToMenuPlan = (dayCardObj) => {
    setNewMenuPlanObj((prev) => {
      const existingIndex = prev.monthly_plan.findIndex(
        (day) => day.plan_date === dayCardObj.plan_date
      );
      if (existingIndex !== -1) {
        // If there's an existing entry with the same plan_date, update it
        return {
          ...prev,
          monthly_plan: prev.monthly_plan.map((day, index) =>
            index === existingIndex ? dayCardObj : day
          ),
        };
      } else {
        // If no existing entry, add the new dayCardObj
        return {
          ...prev,
          monthly_plan: [...prev.monthly_plan, dayCardObj],
        };
      }
    });
  };
  const AddDayToMenuPlan = (dayCardObj) => {
    setMenuPlanObj((prev) => {
      const planIndex = prev.findIndex(
        (plan) => plan.id === dayCardObj.menu_plan_id
      );

      if (planIndex !== -1) {
        const existingDayCardIndex = prev[planIndex].monthly_plan.findIndex(
          (item) =>
            (dayCardObj.id && item.id === dayCardObj.id) ||
            item.plan_date === dayCardObj.plan_date
        );

        const updatedPlan = { ...prev[planIndex] };

        if (existingDayCardIndex !== -1) {
          // Directly update the existing day card
          updatedPlan.monthly_plan[existingDayCardIndex] = dayCardObj;
        } else {
          // Append the new day card
          updatedPlan.monthly_plan = [...updatedPlan.monthly_plan, dayCardObj];
        }
        const updatedMenuPlanObj = [...prev];
        updatedMenuPlanObj[planIndex] = updatedPlan;
        setPlanList(updatedMenuPlanObj);
        return updatedMenuPlanObj;
      }

      return prev;
    });
  };

  const CreateMenuPlan = async () => {
    const endpoint = `${TENANT_URL}/menu/plan/calender`;
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    newMenuPlanObj.plan_price = planPriceFinal;
  
    // Create a new FormData object
    const formData = new FormData();
    
    // Append non-file data (newMenuPlanObj values) to formData
    formData.append('plan_price', newMenuPlanObj.plan_price);
    formData.append('plan_category_id', newMenuPlanObj.plan_category_id);
    formData.append('description', newMenuPlanObj.description);
    formData.append('name', newMenuPlanObj.name);
    formData.append('plan_month', newMenuPlanObj.plan_month);
  
    // Append the image file if it exists
    if (imageFile) {
      formData.append('image', imageFile);
    }
  
    // If you have other properties in newMenuPlanObj (like monthly_plan), you can append them as well.
    let monthlyPlanData = [];

    newMenuPlanObj.monthly_plan.forEach((day) => {
      monthlyPlanData.push(day);
      // formData.append('monthly_plan[]', JSON.stringify(day));
    });
    formData.append('monthly_plan', JSON.stringify(monthlyPlanData));
  
    try {
      // Send POST request with FormData
      const response = await axios.post(endpoint, formData, {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          // 'Content-Type' is automatically set to 'multipart/form-data' by FormData
        },
      });
  
      // Handle successful response
      show('New Plan Added Successfully!');
      location.href = '/admin/menuplan';
    } catch (error) {
      // Handle error response
      show('Oops there is something wrong!', 'error');
      console.error('Error making POST request', error);
    }
  
    var month = dayjs(selectedDate).month() + 1;
    var year = dayjs(selectedDate).year();
  
    await getPlanList(month, year);
  };

    const UpdateMenuPlan = async (data) => {
      const planId = plan;
      const endpoint = `${TENANT_URL}/menu/plan/calender/${planId}?_method=PUT`;
      const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    
      // Create a new FormData object
      const formData = new FormData();
    
      // Append non-file data (newMenuPlanObj values) to formData
      formData.append('plan_price', data.plan_price);
      formData.append('plan_category_id', data.plan_category_id);
      formData.append('name', uplatedPlanName);
      formData.append('description', uplatedPlanDescription);
      formData.append('plan_month', data.plan_month);
      formData.append('monthly_plan', JSON.stringify(data.monthly_plan));
      formData.append('id', data.id);
    

      // Append the image file if it exists
      if (imageFile) {
        formData.append('image', imageFile);
      }
    
      try {
        // Send PUT request with FormData
        const response = await axios.post(endpoint, formData, {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            // 'Content-Type' is automatically set to 'multipart/form-data' by FormData
          },
        });
    
        // Handle successful response
        show('Plan Updated Successfully!');
        var month = dayjs(selectedDate).month() + 1;
        var year = dayjs(selectedDate).year();
        await getPlanList(month, year);
        await getListViewPlans(1);
      } catch (error) {
        // Handle error response
        show('Oops there is something wrong!', 'error');
        console.error('Error making PUT request', error);
      }
    };

    // const UpdateMenuPlan = async (data) => {
    //   var planId = plan;
    //   const endpoint = `${TENANT_URL}/menu/plan/calender/${planId}`;
    //   const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
  
  
    //   console.log("data:",data);
    // await axios
    //   .put(endpoint, data, {
    //     headers: {
    //       'Content-Type': 'application/json',
    //       Authorization: `Bearer ${authToken}`,
    //     },
    //   })
    //   .then((response) => {
    //     show('Plan Updated Successfully!');
    //   })
    //   .catch((error) => {
    //     show('Oops there is something wrong!', 'error');
    //   });
    // var month = dayjs(selectedDate).month() + 1;
    // var year = dayjs(selectedDate).year();

    // await getPlanList(month, year);
    // await getListViewPlans(1);
  // };
  const deleteMenuPlan = async (id) => {
    // debugger;
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var deleteConfig = {
      method: 'delete',
      url: `${TENANT_URL}/menu/plan/calender/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      setLoading(true);

      const response = await axios(deleteConfig);
      var month = dayjs(selectedDate).month() + 1;
      var year = dayjs(selectedDate).year();

      await getPlanList(month, year);
      await getListViewPlans(currentPage);
      show(response.data.message);
      setLoading(false);
    } catch (error) {
      show(error.response.data.message, 'error');
      setLoading(false);
    }
  };
  useEffect(() => {
    getRestuarantDayOff();

    var month = dayjs().month() + 1;
    var year = dayjs().year();

    getPlanList(month, year);
  }, []);

  const getRestuarantDayOff = async () => {
    if (!restaurantDayOff) {
      fetchData(`/restaurant/timing/${TENANT_ID}`, setRestaurantDayOff);
    }
  };

  useEffect(() => {
    if (planList && planList.length) {
      setMenuPlanObj(planList);
    }
  }, [planList]);

  useEffect(() => {
    if (selectedDate) {
      var month = dayjs(selectedDate).month() + 1;
      var year = dayjs(selectedDate).year();

      setPlan(0);
      setPlanList([]);
      getPlanList(month, year);
    }
  }, [selectedDate]);
  const values = {
    mealItems,
    adonsItem,
    planCategories,
    menuPlanObj,
    setMenuPlanObj,
    setDayCardObj,
    AddDayToMenuPlan,
    CreateMenuPlan,
    UpdateMenuPlan,
    restaurantDayOff,
    selectedCardDate,
    setSelectedCardDate,
    planPrice,
    setPlanPrice,
    newPlanPrice,
    setNewPlanPrice,
    planList,
    setPlanList,
    plan,
    setPlan,
    CreatNewDayToMenuPlan,
    newMenuPlanObj,
    setNewMenuPlanObj,
    setPlanPriceFinal,
    planPriceFinal,
    links,
    meta,
    currentPage,
    loading,
    initialStateNewMenuPlanObj,
    setCurrentPage,
    planListView,
    setPlanListView,
    updateMenuPlanObj,
    setUpdateMenuPlanObj,
    initialUpdateMenuPlanObjs,
    setInitialUpdateMenuPlanObjs,
    getPlanList,

    planDropdownList,
    selectedDate,
    setSelectedDate,
    getMealItems,
    getPlanCategories,
    setMealItems,
    deleteMenuPlan,
    allMealItems,
    setAllMealItems,
    setImageFile,
    imageFile,
    handleFileChange,
    setUplatedPlanName,
    setUplatedPlanDescription,
  };

  return (
    <MenuPlanContext.Provider value={values}>
      {children}
    </MenuPlanContext.Provider>
  );
};
