import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  MenuItem,
  Grid,
  Typography,
  Modal,
  Menu,
  Switch,
  Select,
  InputLabel,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../../components/utils/spinner';
import Cross from '../../../tenant/pages/profile/assets/cross.svg';
import VouchersCards from './components/cards';
import { DataGrid } from '@mui/x-data-grid';
import { ReactComponent as UploadIcon } from '../../../icons/upload.svg';
import FormControlLabel from '@mui/material/FormControlLabel';
import { TENANT_URL } from './../../../config';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import axios from 'axios';
import SearchTextFieldNew from '../../../customer/components/SearchTextFieldNew';
import FormModalInputText from './components/formfied/inputfield';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormTextarea from './components/formfied/texreafiels';

import { useSnackbar } from '../../../components/utils/snackbarProvider/SnackbarContextProvider';
const CustomBox = ({ value, selected, onClick }) => (
  <Box
    sx={{
      width: '33px',
      height: selected ? '36px' : '30px',
      display: 'flex',
      p: 1,
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      backgroundColor: selected ? '#FF7676' : 'transparent',
      color: selected ? 'white' : '#FF7676',
      transition: 'all ease 0.3s',
      '&:hover': {
        color: '#000000',
      },
    }}
    onClick={onClick}
  >
    {value}
  </Box>
);

const Vouchers = ({}) => {
  const { show } = useSnackbar();
  const [rows, setRows] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');

  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [totalRecords, setTotalRecords] = useState(0);
  const [toRecords, setToRecords] = useState(0);
  const [fromRecords, setFromRecords] = useState(0);

  const [editId, setEditID] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedRow, setSelectedRow] = useState(null); // State to store selected row
  const [trialCards, setTrialCards] = useState({}); // State to store selected row

  const [code , setcode ] = useState("");
  const [type , settype ] = useState("0");
  const [value , setvalue ] = useState("");
  const [status, setStatus] = useState("deactive");
  const [expiration_date , setexpiration_date ] = useState("");
  const [usage_limit_per_user , setusage_limit_per_user ] = useState("");
  const [total_usage_limit , settotal_usage_limit ] = useState("");
  const [description , setDescription ] = useState("");
  

  const handleClick = (event, rowData) => {
    setSelectedRow(rowData); // Store the selected row
    setAnchorEl(event.currentTarget); // Set anchor for menu
  };

  const handleResetButton = () => {
    setcode("");
    settype("");
    setvalue("");
    setStatus("");
    setexpiration_date("");
    setusage_limit_per_user("");
    settotal_usage_limit("");
    setDescription("");
  };

  // Function to handle search
  const handleSearchChange = async (event) => {
    setSearchKeyword(event.target.value);
    console.log('searchKeyword:', searchKeyword);
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/voucher?search=${event.target.value}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(getConfig);
      setRows(response.data.data);
      setLastPage(response.data.meta.last_page);
      setCurrentPage(response.data.meta.current_page);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async () => {
    var data = JSON.stringify({
      code: code,
      description: description,
      discount_type: type,
      discount_value: value,
      expiration_date: expiration_date,
      status: (!!status) ? "active" : "inactive",
      usage_limit_per_user: usage_limit_per_user,
      total_usage_limit: total_usage_limit
    });
    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var addConfig = {
        method: editId ? 'put' : 'post',
        url: editId
          ? `${TENANT_URL}/voucher/${editId}`
          : `${TENANT_URL}/voucher`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios({ ...addConfig, data: data });
      show(!!editId ? 'Campaign Update Successfully':'Campaign Added Successfully');
      handleCloseModal();
      getTiralItems(1);
      getTrialFoodCards();
      location.reload();
    } catch (error) {
      show("Error: Couldn't Add Campaign", 'error');
    }
  };


  const deleteVoucher = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    if (!editId) {
      setErrorMessage('Please enter all fields');
      return;
    }

    const addConfig = {
      method: 'delete',
      url: `${TENANT_URL}/voucher/${editId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await axios({ ...addConfig });
      console.log('response:', response);
      if (response.status == '201' || response.status == '200') {
        window.location.reload();
      } else {
        setErrorMessage(
          response.data.message || 'Some error occur. Please try again.'
        );
      }
    } catch (error) {
      console.error(error);
    }

    handleCloseModel();
  };

  // Add Modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setEditID(0);
    setcode("");
    settype("");
    setvalue("");
    setStatus("");
    setexpiration_date("");
    setusage_limit_per_user("");
    settotal_usage_limit("");
    setDescription("");
    
    setOpenModal(false);
  };

  // Edit Modal
  const [openModal1, setOpenModal1] = useState(false);
  const handleOpenModal1 = (voucher) => {
    setEditID(voucher.id)
    setcode(voucher.code);
    settype(voucher.discount_type);
    setvalue(voucher.discount_value);
    setStatus((voucher.status=="active"? true : false));
    setexpiration_date(voucher.expiration_date);
    setusage_limit_per_user(voucher.usage_limit_per_user);
    settotal_usage_limit(voucher.total_usage_limit);
    setDescription(voucher.description);

    setOpenModal(true);
  };
  const handleCloseModel = (id) => {
    setOpenModal(false);
    setOpenModal1(false);
    setOpenModal2(false);
  };

  // Delete Modal
  const [openModal2, setOpenModal2] = useState(false);
  const handleOpenModal2 = (voucher) => {
    setEditID(voucher.id);
    setOpenModal2(true);
  };

  //
  const customerColumns = [
    {
      field: 'code',
      headerName: 'Code',
      flex: 1,
      headerClassName: 'header-cell',
      cellClassName: 'column-cell',
      renderCell: (params) => (
        <div style={{ fontSize: '13px' }}>{params.row.code}</div>
      ),
    },
    {
      field: 'created_at',
      headerName: 'Created on',
      headerClassName: 'header-cell',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'column-cell',
      renderCell: (params) => (
        <div style={{ fontSize: '13px', textAlign: 'left' }}>{params.row.created_at}</div>
      ),
    },
    
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      headerAlign: 'left',
      headerClassName: 'header-cell',
      cellClassName: 'column-cell',
      renderCell: (params) => (
        <div style={{ fontSize: '13px' }}>{params.row.description}</div>
      ),
    },

    {
      field: 'expiration_date',
      headerName: 'Expiration Date',
      headerClassName: 'header-cell',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'column-cell',
      renderCell: (params) => (
        <div style={{ fontSize: '13px', textAlign: 'left' }}>{params.row.expiration_date}</div>
      ),
    },
    {
      field: 'usage_limit_per_user',
      headerName: 'Usage Limit Per User',
      headerClassName: 'header-cell',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'column-cell',
      renderCell: (params) => (
        <div style={{ fontSize: '13px', textAlign: 'left' }}>{params.row.usage_limit_per_user}</div>
      ),
    },
    {
      field: 'total_usage_limit',
      headerName: 'Total Usage Limit',
      headerClassName: 'header-cell',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'column-cell',
      renderCell: (params) => (
        <div style={{ fontSize: '13px', textAlign: 'left' }}>{params.row.total_usage_limit}</div>
      ),
    },
    {
      field: 'type',
      headerName: 'Type',
      headerClassName: 'header-cell',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'column-cell',
      renderCell: (params) => (
        <div style={{ fontSize: '13px', textAlign: 'left' }}>{params.row.discount_type}</div>
      ),
    },
    {
      field: 'value',
      headerName: 'Value',
      headerClassName: 'header-cell',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'column-cell',
      renderCell: (params) => (
        <div style={{ fontSize: '13px', textAlign: 'left' }}>{params.row.discount_value}</div>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 5,
      headerClassName: 'header-cell',
      cellClassName: 'column-cell',
      renderCell: (params) => (
        <>
          <div
            style={{
              backgroundColor: params.row.is_active ? '#54BA4A' : '#FF4D4F', // Green for true, red for false
              width: '15px',
              height: '15px',
              borderRadius: '50%',
              marginRight: '5px',
            }}
          />
          {params.row.is_active ? 'Active' : 'Inactive'}
        </>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 80,
      headerClassName: 'header-cell',
      align: 'center',
      headerAlign: 'center',
      cellClassName: 'column-cell',
      renderCell: (params) => {
        return (
          <div>
            <Button
              id="basic-button"
              aria-controls={anchorEl ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={anchorEl ? 'true' : undefined}
              variant="filled"
              onClick={(event) => handleClick(event, params.row)} // Pass row data
              sx={{
                textTransform: 'capitalize',
                padding: 0,
                minWidth: 88,
                height: '40px',
                borderRadius: 1000,
                fontWeight: 400,
                color: 'white',
                boxShadow: 'none',
                '&:focus': {
                  outline: 0,
                },
              }}
            >
              Actions
            </Button>

            <Box>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={{
                  '& .MuiPaper-root': {
                    minWidth: 100,
                    marginTop: '8px',
                    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.04)',
                    borderRadius: '10px',
                  },
                  '& .MuiMenuItem-root': {
                    padding: '5px 16px',
                    color: '#7E6E9A',
                    fontSize: '12px',
                    fontWeight: 500,
                    width: '100%',
                  },
                }}
              >
                {/* <MenuItem sx={{ width: '100%', my: 0.1, fontSize: '12px' }}>
                  View
                </MenuItem> */}
                <MenuItem
                  onClick={() => handleOpenModal1(selectedRow)} // Use selectedRow
                  sx={{ width: '100%', my: 0.1, fontSize: '12px' }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => handleOpenModal2(selectedRow)} // Use selectedRow
                  sx={{ width: '100%', my: 0.1, fontSize: '12px' }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </Box>
          </div>
        );
      },
    },
  ];

  const getTiralItems = async (pageNo) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/voucher?page=${pageNo}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(getConfig);
      setRows(response.data.data);
      setLastPage(response.data.meta.last_page);
      setCurrentPage(response.data.meta.current_page);
      setTotalRecords(response.data.meta.total);
      setToRecords(response.data.meta.to);
      setFromRecords(response.data.meta.from);
    } catch (error) {
      console.error(error);
    }
  };

  const getTrialFoodCards = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/vouchers/stats`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(getConfig);
      setTrialCards(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getTiralItems(page);
  };

  useEffect(() => {
    getTiralItems(1);
    getTrialFoodCards();
  }, []);

  const menuRef = useRef(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const commonSelect = {
    fontSize: '16px',
    fontWeight: '400',
    fontFamily: 'Outfit',
    background:
      'linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.45))',
    height: '53px',
    border: '1px solid #AC9EC3',
    lineHeight: '20.16px',
    borderRadius: '10px',
    width: '100%',
    backdropFilter: 'blur(20px)',
    '& .MuiSelect-icon': {
      top: 'calc(50% - 12px)',
      right: '10px',
    },
    boxShadow: 'none',
    '.MuiOutlinedInput-notchedOutline': { border: 0 },
    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
  };

  const [secondSelected1, setSecondSelected1] = useState('1');

  return (
    <>
      {false ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </Box>
      ) : (
        <>
          <VouchersCards rows={trialCards} />
          <Box
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.3)',
              overflow: 'hidden',
              width: '100%',
              height: '100%',
              borderRadius: '15px',
              boxShadow: '0px 4px 4px 0px #00000026',
              overflowY: 'auto',
              padding: '15px',
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <SearchTextFieldNew
                newStyle={{
                  backgroundColor: '#ffffff',
                }}
                type="text"
                value={searchKeyword}
                onChange={handleSearchChange} // Update state on input change
              />
              <Button
                variant="filled"
                onClick={handleOpenModal}
                sx={{
                  textTransform: 'capitalize',
                  padding: 0, // Remove any extra padding
                  minWidth: 100, // Fixed width for the circle
                  height: '40px', // Fixed height for the circle
                  borderRadius: 1000, // Circle shape
                  fontWeight: 400,
                  color: 'white',
                  boxShadow: 'none',
                  '&:focus': {
                    outline: 0,
                  },
                }}
              >
                + Add New
              </Button>
            </Box>
            <Grid
              container
              sx={{
                marginTop: '14px',
              }}
            >
              <DataGrid
                sx={{
                  width: '100%',
                  border: 'none',
                  display: 'flex',
                  justifyContent: 'space-between',
                  '& .MuiDataGrid-withBorderColor': {
                    border: 'none',
                  },
                  '& .column-cell': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: '14px',
                    fontWeight: '500',
                    whiteSpace: 'nowrap',
                    width: '100%',
                    borderBottom: '1px solid white',
                  },
                  '& .header-cell': {
                    fontSize: '18px',
                    fontWeight: 'bold',
                    width: '100%',

                    borderBottom: '3px solid white',
                  },
                  '& .css-t89xny-MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'normal',
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-columnHeader:focus': {
                    outline: 'none',
                  },
                  '--unstable_DataGrid-radius': 'none',
                  borderTop: '1px solid white',
                }}
                rows={rows}
                columns={customerColumns}
                hideFooter
                disableColumnFilter
                // checkboxSelection
                disableColumnSelector

                // autoPageSize={true}
              />
            </Grid>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>
                Showing {fromRecords} to {toRecords} of {totalRecords} entries
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CustomBox
                  value={<KeyboardArrowLeftIcon />}
                  onClick={() =>
                    currentPage > 1 && handlePageChange(currentPage - 1)
                  }
                />
                {/* Render pagination numbers */}
                {Array.from({ length: lastPage }, (_, index) => (
                  <CustomBox
                    key={index}
                    value={index + 1}
                    selected={index + 1 === currentPage}
                    onClick={() => handlePageChange(index + 1)}
                  />
                ))}
                <CustomBox
                  value={<KeyboardArrowRightIcon />}
                  onClick={() =>
                    currentPage < lastPage && handlePageChange(currentPage + 1)
                  }
                />
              </Box>
            </Box>
          </Box>
        </>
      )}

      {/* Add Modal */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '767px',
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: 24,
            padding: '25px 15px',
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <div className="d-flex justify-content-between align-items-end mb-4">
            <Typography
              variant="h6"
              sx={{
                marginBottom: '0',
                fontSize: '24px',
                fontFamily: 'Outfit',
                fontWeight: '500',
                color: '#3F2F5B',
              }}
            >
              {!!editId ? "Edit": "Add"} Voucher
            </Typography>
            <img
              src={Cross}
              style={{
                cursor: 'pointer',
              }}
              alt="Close"
              onClick={handleCloseModal}
            />
          </div>

          <Grid
            container
            spacing={2}
            sx={{
              marginBottom: '15px',
            }}
          >
            <Grid item xs={12} sm={4}>
              <FormModalInputText
                label={'Voucher Code'}
                placeholder={'Voucher Code'}
                type={'text'}
                onChange={(e) => setcode(e.target.value)} // Pass the handler
                value={code} // Pass the value
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel htmlFor="my-input" sx={{ mb: 1, fontWeight: '500' }}>
                Type
              </InputLabel>
              <Select
                value={type}
                onChange={(event) => settype(event.target.value)}
                sx={commonSelect}
                IconComponent={ExpandMoreIcon}
              >
                <MenuItem value="0" disabled selected>Select Type...</MenuItem>
                <MenuItem value="percentage">Percentage</MenuItem>
                <MenuItem value="fixed">Fixed</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormModalInputText
                label={'Value'}
                placeholder={'Voucher Value'}
                type={'text'}
                onChange={(e) => setvalue(e.target.value)} // Pass the handler
                value={value} // Pass the value
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{
              marginBottom: '15px',
            }}
          >
            <Grid item xs={12} sm={4}>
              <FormModalInputText
                label={'Expiration Date'}
                placeholder={'Expiration Date'}
                type={'date'}
                onChange={(e) => setexpiration_date(e.target.value)} // Pass the handler
                value={expiration_date} // Pass the value
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormModalInputText
                label={'Limit per user'}
                placeholder={'Limit per user'}
                type={'number'}
                onChange={(e) => setusage_limit_per_user(e.target.value)} // Pass the handler
                value={usage_limit_per_user} // Pass the value
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormModalInputText
                label={'Total usage limit'}
                placeholder={'Total usage limit'}
                type={'number'}
                onChange={(e) => settotal_usage_limit(e.target.value)} // Pass the handler
                value={total_usage_limit} // Pass the value
              />
            </Grid>

          </Grid>
          <FormTextarea
            label={'Description'}
            placeholder={'Enter Short Description'}
            type={'textarea'}
            value={description} // Pass the value
            onChange={(e) => setDescription(e.target.value)} // Pass the value
          />
          <Typography
            variant="h6"
            sx={{
              marginBottom: '0',
              fontSize: '16px',
              fontFamily: 'Outfit',
              fontWeight: '500',
              color: 'red',
              marginTop: '15px',
            }}
          >
            {errorMessage}
          </Typography>

          {/* Footer Buttons */}
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              marginTop: 4,
            }}
          >
            <Box
              sx={{
                marginRight: 'auto',
              }}
            >
              <FormControlLabel
                control={<Switch defaultChecked />}
                label="Status"
                labelPlacement="start"
                sx={{
                  marginLeft: '0px',
                }}
                checked={status}
                onChange={(e) => setStatus(e.target.checked)}
              />
            </Box>
            <Button
              onClick={handleResetButton}
              variant="outlined"
              sx={{
                fontSize: '14px',
                fontWeight: '500',
                borderColor: '#AC9EC3',
                color: '#AC9EC3',
              }}
            >
              Reset
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="error"
              sx={{
                fontSize: '14px',
                fontWeight: '500',
                backgroundColor: '#F0645B',
                color: '#ffffff',
              }}
            >
              {!!editId ? "Update": "Save"}
              
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Delete Modal */}
      <Modal open={openModal2} onClose={handleCloseModel}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '385px',
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: 24,
            padding: '25px 15px',
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <div className="d-flex justify-content-between align-items-end mb-3">
            <Typography
              variant="h6"
              sx={{
                marginBottom: '0',
                fontSize: '18px',
                fontFamily: 'Outfit',
                fontWeight: '500',
                color: '#3F2F5B',
              }}
            >
              Are You Sure?
            </Typography>
            <img src={Cross} alt="Close" onClick={handleCloseModal} />
          </div>

          <Typography
            variant="h6"
            sx={{
              marginBottom: '0',
              fontSize: '14px',
              fontFamily: 'Outfit',
              fontWeight: '400',
              color: '#4F4D55',
            }}
          >
            You want to delete this voucher?
          </Typography>

          {/* Footer Buttons */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2,
              marginTop: 4,
            }}
          >
            <Button
              onClick={handleCloseModel}
              variant="outlined"
              sx={{
                fontSize: '14px',
                fontWeight: '500',
                borderColor: '#F0645B',
              }}
            >
              No
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{
                fontSize: '14px',
                fontWeight: '500',
                backgroundColor: '#F0645B',
              }}
              onClick={deleteVoucher}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Vouchers;
