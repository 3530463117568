import {
  Box,
  Button,
  Container,
  Grid,
  ThemeProvider,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Menu5 from '../../../styles/MenuStyles/Menu5';
import ContactUsForm from '../../../components/contactUsForm/contactUsForm';

import { createTheme } from '@mui/material/styles';

import './../../../../../../styles/fonts/newFonts.css';

import Navbar1 from '../../../styles/NavbarStyles/Navbar1';

import Footer1 from '../../../styles/FooterStyles/Footer1';
import Footer2 from '../../../styles/FooterStyles/Footer2';
import Footer3 from '../../../styles/FooterStyles/Footer3';
import Footer4 from '../../../styles/FooterStyles/Footer4';
import Footer5 from '../../../styles/FooterStyles/Footer5';
import Header1 from './../../../styles/HeaderStyles/Header1/Header';
import Header2 from './../../../styles/HeaderStyles/Header2/Header';
import Header3 from './../../../styles/HeaderStyles/Header3/Header';
import HeroSection1 from './../../../styles/HeaderStyles/HeroSection1/Header';
import { useStateContext } from '../../../api/stateContext';

import Layout1 from '../../../styles/cardLayouts/layout1/layout1';
import Layout2 from '../../../styles/cardLayouts/layout2/layout2';
import Layout3 from '../../../styles/cardLayouts/layout3/layout3';
import Layout4 from '../../../styles/cardLayouts/layout4/layout4';
import Layout5 from '../../../styles/cardLayouts/layout5/layout5';
import axios from 'axios';
import Footer6 from '../../../styles/FooterStyles/Footer6/Footer6';
import Footer7 from '../../../styles/FooterStyles/Footer7/Footer7';
import Footer8 from '../../../styles/FooterStyles/Footer8/Footer8';
import Footer9 from '../../../styles/FooterStyles/Footer9/Footer9';
import HeroSection2 from '../../../styles/LandingPages/HeroSection2/HeroSection2';
import HeroSection3 from '../../../styles/LandingPages/HeroSection3/HeroSection3';
import HeroSection4 from '../../../styles/LandingPages/HeroSection4/HeroSection4';
import TrialComponent from '../../../components/trialcomponent/trialcomponent';

import Slider_Img1 from '../../../assets/images/slider1.jpg';
import Slider_Img2 from '../../../assets/images/slider2.jpg';
import Slider_Img3 from '../../../assets/images/slider3.jpg';
import Contact_Img from '../../../assets/images/contact.png';
// Slider
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Card, CardContent, CardMedia, IconButton } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import StarIcon from '@mui/icons-material/Star';
import { TENANT_URL } from '../../../../../../config';

// Sample Card Component
const FoodCard = ({
  title,
  description,
  buttonLabel,
  image,
  activeTab,
  slug,
}) => {
  return (
    <Grid item xs={12} sm={6} lg={4} xl={3} md={4}>
      <div className="food-card">
        <div className="image-container">
          <img src={image || 'https://via.placeholder.com/150'} alt={title} />
        </div>
        <Typography
          variant="h3"
          sx={{
            fontSize: '25px',
            fontWeight: 600,
            color: '#1E1E1E',
            fontFamily: 'Inter, sans-serif !important',
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: '14px !important',
            color: '#4A4A4A',
            fontFamily: 'Inter, sans-serif !important',
            textAlign: 'center',
          }}
        >
          {description}
        </Typography>
        {activeTab == 'Menu Plan' ? (
          <a
            href={`/mealdetails/${slug}`}
            style={{
              marginTop: 'auto',
            }}
          >
            <Button
              variant="outlined"
              endIcon={
                <Icon icon="uil:arrow-right" color="#F96221" fontSize={20} />
              }
              sx={{
                textTransform: 'capitalize',
                fontWeight: 600,
                fontSize: '16px',
              }}
            >
              {buttonLabel}
            </Button>
          </a>
        ) : (
          <></>
        )}
      </div>
    </Grid>
  );
};

export default function CustomizationServiceLandingPage() {
  const { state, dispatch } = useStateContext();
  const [menuItems, setMenuItems] = useState([]);
  const [meals, setMeals] = useState([]);
  const [addons, setAddons] = useState([]);
  const [mealPlans, setMealPlans] = useState([]);
  const [randomMeals, setRandomMeals] = useState([]);

  const [activeTab, setActiveTab] = useState('Menu Plan');

  const getMenuItems = async () => {
    var config = {
      method: 'get',
      url: `${TENANT_URL}/api/meal-items`,
    };
    try {
      const response = await axios(config);
      setMenuItems(response.data);
    } catch (error) {
      console.log(error);
      return {};
    }
  };
  const getMealItems = async () => {
    var config = {
      method: 'get',
      url: `${TENANT_URL}/api/meals`,
    };
    try {
      const response = await axios(config);
      setMeals(response.data.data);
    } catch (error) {
      console.log(error);
      return {};
    }
  };
  const getAddonsItems = async () => {
    var config = {
      method: 'get',
      url: `${TENANT_URL}/api/addons`,
    };
    try {
      const response = await axios(config);
      setAddons(response.data.data);
    } catch (error) {
      console.log(error);
      return {};
    }
  };
  const getMealPlans = async () => {
    var config = {
      method: 'get',
      url: `${TENANT_URL}/api/menu-plans`,
    };
    try {
      const response = await axios(config);
      setMealPlans(response.data.data);
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  const selectRandomMeals = () => {
    if (meals.length < 3) {
      setRandomMeals(meals); // If there are fewer than 3 meals, use them all
      return;
    }

    const shuffled = [...meals].sort(() => 0.5 - Math.random()); // Shuffle the meals
    const selected = shuffled.slice(0, 3); // Take the first 3
    setRandomMeals(selected);
  };

  useEffect(() => {
    getMenuItems();
    getMealItems();
    getAddonsItems();
    getMealPlans();
  }, []);

  useEffect(() => {
    if (meals.length > 0) {
      selectRandomMeals();
    }
  }, [meals]);

  var fontFamily = {
    fontFamily: `"${state.activeFont}",sans-serif !important`,
  };
  const theme = createTheme({
    typography: {
      fontFamily: `"${state.activeFont}",sans-serif !important`,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: `"${state.activeFont}",sans-serif !important`,
          },
        },
      },
    },
  });

  var selectedNavFooter = state.activeNavFooter;
  const navbarComponents = {
    1: <Navbar1 />,
  };
  var renderNavbar = () => navbarComponents[selectedNavFooter] || null;

  const footerComponents = {
    1: <Footer1 />,
    2: <Footer2 />,
    3: <Footer3 />,
    4: <Footer4 />,
    5: <Footer5 />,
    6: <Footer6 />,
    7: <Footer7 />,
    8: <Footer8 />,
    9: <Footer9 />,
  };
  var renderFooter = () => footerComponents[selectedNavFooter] || null;

  var selectedHeader = state.activeLandingPage;
  const headerComponents = {
    1: <Header1 />,
    2: <Header2 />,
    3: <Header3 />,
    4: <HeroSection4 />,
    5: <HeroSection2 />,
    6: <HeroSection3 />,
    7: <HeroSection1 />,
  };
  var renderHeader = () => headerComponents[selectedHeader] || null;

  var selectedCardLayoutComponentMenu = state.activeCardLayoutMenu;
  var selectedCardLayoutComponentOurSpecial = state.activeCardLayoutOurSpecial;

  const cardLayoutComponents = {
    Menu: {
      1: <Layout1 cardStyle={state.activeCardStyleMenu} />,
      2: <Layout2 cardStyle={state.activeCardStyleMenu} />,
      3: <Layout3 cardStyle={state.activeCardStyleMenu} />,
      4: <Layout4 cardStyle={state.activeCardStyleMenu} />,
      5: <Layout5 cardStyle={state.activeCardStyleMenu} />,
    },
    OurSpecial: {
      1: <Layout1 cardStyle={state.activeCardStyleOurSpecial} />,
      2: <Layout2 cardStyle={state.activeCardStyleOurSpecial} />,
      3: <Layout3 cardStyle={state.activeCardStyleOurSpecial} />,
      4: <Layout4 cardStyle={state.activeCardStyleOurSpecial} />,
      5: <Layout5 cardStyle={state.activeCardStyleOurSpecial} />,
    },
  };
  var renderCardLayoutMenu = () =>
    cardLayoutComponents['Menu'][selectedCardLayoutComponentMenu] || null;

  var renderCardLayoutOurSpecial = () =>
    cardLayoutComponents['OurSpecial'][selectedCardLayoutComponentOurSpecial] ||
    null;

  // Custom Arrow Components
  const NextArrow = ({ onClick }) => (
    <div
      className="hoverBtn"
      style={{
        position: 'absolute',
        right: 0,
        top: '-78px',
        zIndex: 2,
        cursor: 'pointer',
        backgroundColor: '#EFEFEF',
        minWidth: '50px',
        height: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 100,
        gap: '10px',
      }}
      onClick={onClick}
    >
      <Icon icon="ic:round-chevron-right" color="#6F6E6E" fontSize={30} />
    </div>
  );

  const PrevArrow = ({ onClick }) => (
    <div
      className="hoverBtn"
      style={{
        position: 'absolute',
        right: '60px',
        top: '-78px',
        zIndex: 2,
        cursor: 'pointer',
        backgroundColor: '#EFEFEF',
        minWidth: '50px',
        height: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 100,
      }}
      onClick={onClick}
    >
      <Icon icon="ic:round-chevron-left" color="#6F6E6E" fontSize={30} />
    </div>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Default number of slides to show
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024, // Screens smaller than 1024px
        settings: {
          slidesToShow: 3, // Show 3 slides on medium screens
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768, // Screens smaller than 768px
        settings: {
          slidesToShow: 2, // Show 2 slides on small tablets
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480, // Screens smaller than 480px
        settings: {
          slidesToShow: 1, // Show 1 slide on mobile
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  // Sample data
  const items = [
    { id: 1, title: 'Item 1' },
    { id: 2, title: 'Item 2' },
    { id: 3, title: 'Item 3' },
    // Add or remove items here to test
  ];

  const settings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: items.length === 1 ? 1 : 3, // Ensure correct logic for slidesToShow
    slidesToScroll: 1, // Always scroll one slide
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1920, // Large screens
        settings: {
          slidesToShow: 4, // Show 3 slides
        },
      },
      {
        breakpoint: 1400, // Medium screens
        settings: {
          slidesToShow: 3, // Adjust to 2 slides
        },
      },
      {
        breakpoint: 1024, // Medium screens
        settings: {
          slidesToShow: 2, // Adjust to 2 slides
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768, // Small screens
        settings: {
          slidesToShow: 1, // Show only 1 slide
        },
      },
      {
        breakpoint: 767, // Small screens
        settings: {
          slidesToShow: 1, // Show only 1 slide
        },
      },
    ],
  };

  const cardsData = [
    {
      title: 'Fried Manchurian',
      description:
        'Lorem ipsum dolor sit, consectetur adipiscing elit, sed do eiusmod tempor',
      buttonLabel: 'Order Now',
      image: 'https://via.placeholder.com/100',
    },
    {
      title: 'Fried Manchurian',
      description:
        'Lorem ipsum dolor sit, consectetur adipiscing elit, sed do eiusmod tempor',
      buttonLabel: 'Order Now',
      image: 'https://via.placeholder.com/100',
    },
  ];

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const filteredCards = activeTab === 'All' ? cardsData : cardsData.slice(0, 1);

  console.log('mealPlans:', mealPlans);
  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ background: 'white', ...fontFamily, position: 'relative' }}>
          {/* Navbar Render */}
          {/* {renderNavbar()}
          {renderHeader()} */}
          {<HeroSection1 />}
          <Box
            maxWidth="xlz"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '40px',
            }}
          >
            <Grid
              container
              sx={{
                padding: '3em 40px 2em',
                '@media (max-width:767px)': {
                  padding: '3em 15px 2em',
                },
              }}
              marginBottom={7}
            >
              <Grid item md={12}>
                <Box marginBottom={4}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: '36px',
                      fontWeight: 700,
                      color: '#000000',
                      fontFamily: 'Inter !important',
                    }}
                  >
                    Exclusive Deals
                  </Typography>
                </Box>

                <Box className="slider-container">
                  <Slider {...settings}>
                    {randomMeals.map((element, index) => (
                      <Box className="sliderBox" key={index}>
                        <img
                          src={element.image_url ?? Slider_Img1}
                          className="img-fluid sliderImg"
                          alt=""
                        />
                      </Box>
                    ))}
                  </Slider>
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              sx={{
                padding: '10px 70px',
                justifyContent: 'center',
                '@media (max-width:1280px)': {
                  padding: '10px 0px',
                },
              }}
            >
              <Grid item md={12} xl={11}>
                <Box marginBottom={4}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: '20px',
                      fontWeight: 600,
                      color: '#F96221',
                      fontFamily: 'Inter !important',
                      textAlign: 'center',
                      textTransform: 'uppercase',
                      letterSpacing: '4px',
                      marginBottom: '20px',
                    }}
                  >
                    Customer Favorites
                  </Typography>

                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: '37px',
                      fontWeight: 'bold',
                      color: '#000000',
                      fontFamily: 'Inter !important',
                      textAlign: 'center',
                      marginBottom: '30px',
                    }}
                  >
                    Signature Dishes
                  </Typography>
                </Box>

                <Box marginBottom={6} className="customTbas">
                  <div className="category-tabs">
                    <div className="category-tabsFlex">
                      {[
                        'All',
                        'Menu Items',
                        'Meals',
                        'Add-Ons',
                        'Menu Plan',
                      ].map((tab) => (
                        <button
                          key={tab}
                          className={`tab-button ${
                            activeTab === tab ? 'active' : ''
                          }`}
                          onClick={() => setActiveTab(tab)}
                        >
                          {tab}
                        </button>
                      ))}
                    </div>
                  </div>

                  <div className="cards-container px-4 px-md-4 px-lg-0">
                    {[
                      { type: 'Menu Items', data: menuItems },
                      { type: 'Meals', data: meals },
                      { type: 'Add-Ons', data: addons },
                      { type: 'Menu Plan', data: mealPlans },
                    ].map(({ type, data }) =>
                      activeTab === type ? (
                        <Grid container spacing={4} key={type}>
                          {data.map((card, index) => (
                            <FoodCard
                              key={index}
                              title={card.name}
                              description={card.description}
                              buttonLabel="View Plan"
                              image={card.image_url}
                              activeTab={activeTab}
                              slug={card.slug || ''}
                            />
                          ))}
                        </Grid>
                      ) : null
                    )}

                    {activeTab === 'All' && (
                      <Grid container spacing={4}>
                        {[...menuItems, ...meals, ...addons].map(
                          (card, index) => (
                            <FoodCard
                              key={index}
                              title={card.name}
                              description={card.description}
                              buttonLabel="View Plan"
                              image={card.image_url}
                              activeTab={activeTab}
                              slug={card.slug || ''}
                            />
                          )
                        )}
                        {[...mealPlans].map((card, index) => (
                          <FoodCard
                            key={index}
                            title={card.name}
                            description={card.description}
                            buttonLabel="View Plan"
                            image={card.image_url}
                            activeTab={'Menu Plan'}
                            slug={card.slug || ''}
                          />
                        ))}
                      </Grid>
                    )}
                  </div>
                </Box>

                <Box
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  <Button
                    variant="contained"
                    className="commonBtn"
                    sx={{
                      fontFamily: 'Inter !important',
                    }}
                  >
                    View All
                    <Icon
                      icon="uil:arrow-right"
                      color="#ffffff"
                      fontSize={20}
                    />
                  </Button>
                </Box>
              </Grid>
            </Grid>

            <Box
              sx={{
                marginX: 'auto', // Center horizontally with auto margins
                width: '100%',
                display: 'flex', // Use flex to center content
                justifyContent: 'center', // Center the content horizontally
                alignItems: 'center', // Center the content vertically
                flexDirection: 'column', // Stack elements vertically
                padding: '2em 5em', // Add some padding for spacing
                '@media (max-width:767px)': {
                  padding: '2em 1em',
                },
              }}
            >
              <Box
                sx={{
                  background:
                    'linear-gradient(180deg, rgba(255,96,48,1) 3%, rgba(255,93,42,1) 5%, rgba(255,94,41,1) 96%, rgba(255,96,48,1) 97%)',
                  width: '100%',
                  padding: '1.2em 3em',
                  borderRadius: '30px', // Optional: rounded corners for aesthetics
                  boxSizing: 'border-box', // Ensure padding does not affect the width
                  '@media (max-width:767px)': {
                    padding: '1.2em 1em',
                  },
                }}
              >
                <TrialComponent />
              </Box>
            </Box>

            <Grid
              container
              sx={{
                padding: '7em 9em',
                backgroundColor: '#FFF5EC',
                px: { sm: 4, lg: 12 },
                '@media (max-width:767px)': {
                  padding: '4em 1em',
                },
              }}
            >
              <Grid item md={12}>
                <Box marginBottom={4}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: '19px',
                      fontWeight: 700,
                      color: '#F96221',
                      fontFamily: 'Inter !important',
                      textTransform: 'uppercase',
                      letterSpacing: '2px',
                    }}
                  >
                    Our specials
                  </Typography>
                </Box>
                <Box marginBottom={4}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: '36px',
                      fontWeight: 700,
                      color: '#000000',
                      fontFamily: 'Inter !important',
                    }}
                  ></Typography>
                </Box>

                <Box className="slider-container1 toppicks_slider">
                  <Slider {...settings1}>
                    {meals.map((salad, index) => (
                      <Card
                        key={index}
                        className="sliderBox"
                        sx={{
                          borderRadius: '40px',
                          boxShadow: '0 0 6px 0 rgba(0, 0, 0,0.07)',
                          position: 'relative',
                          padding: '20px 15px',
                          overflow: 'hidden',
                        }}
                      >
                        {/* Favorite Icon */}
                        <IconButton
                          sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            backgroundColor: '#FACD2D',
                            zIndex: 2,
                            borderRadius: 0,
                            width: '70px',
                            borderBottomLeftRadius: '35px',
                            '&:hover ': {
                              backgroundColor: '#F96221',
                            },
                          }}
                        >
                          <FavoriteBorderIcon sx={{ color: '#ffffff' }} />
                        </IconButton>

                        {/* Image */}
                        <CardMedia
                          component="img"
                          height="180"
                          image={salad.image_url || salad.image_url}
                          alt={salad.name}
                          sx={{
                            borderRadius: '50%',
                            width: 150,
                            height: 150,
                            margin: 'auto',
                            mt: 2,
                          }}
                        />

                        {/* Content */}
                        <CardContent>
                          <Typography
                            variant="h6"
                            fontWeight="bold"
                            fontFamily="Inter !important"
                            marginBottom={1}
                            fontSize={25}
                          >
                            {salad.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            fontSize={17}
                            color="#4A4A4A"
                            fontFamily="Inter !important"
                            sx={{ marginBottom: 2 }}
                          >
                            {salad.description}
                          </Typography>

                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography
                              variant="h6"
                              fontFamily="Inter !important"
                              color="error"
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                              }}
                            >
                              $
                              <Typography
                                variant="h6"
                                fontFamily="Inter !important"
                                color="#000000"
                              >
                                {salad.price.toFixed(2)}
                              </Typography>
                            </Typography>
                            <Box display="flex" alignItems="center" gap={0.5}>
                              <StarIcon
                                sx={{ color: '#FFE605', fontSize: 20 }}
                              />
                              <Typography
                                fontFamily="Inter !important"
                                variant="body2"
                                fontWeight="bold"
                                color="#454545"
                              >
                                {'4.2'}
                              </Typography>
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    ))}
                  </Slider>
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={6}
              sx={{
                padding: '4em 2em 6em',
                backgroundColor: '#FCFCFC',
                justifyContent: 'center', // Centers items horizontally
                alignItems: 'center', // Centers items vertically
                [theme.breakpoints.down('sm')]: {
                  padding: '15px',
                },
                [theme.breakpoints.down('xs')]: {
                  padding: '15px',
                },
              }}
            >
              <Grid item sm={12} md={7} lg={5}>
                <Box>
                  <Menu5 />
                </Box>
              </Grid>
              <Grid item sm={12} md={5} lg={5}>
                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                    color: '#F96221',
                    fontFamily: 'Inter !important',
                    textTransform: 'uppercase',
                    letterSpacing: '2px',
                    marginBottom: '20px',
                  }}
                >
                  opening hours
                </Typography>

                <Typography
                  sx={{
                    fontSize: '37px',
                    fontWeight: 'bold',
                    color: '#000000',
                    fontFamily: 'Inter !important',
                  }}
                >
                  When to Join Us for a Memorable Dining Experience
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Grid
            container
            py={12}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
              width: '100%',
              bgcolor: '#FFF5EC',
              px: { xs: 1, sm: 8, md: 8, lg: 16 },
            }}
          >
            <Grid item xs={12} sm={12} md={6}>
              <img src={Contact_Img} alt="" className="conImg" />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <ContactUsForm />
            </Grid>
          </Grid>
          {/* <Box>{renderFooter()}</Box> */}
          <Box>{<Footer6 />}</Box>
        </Box>
      </ThemeProvider>
    </>
  );
}
