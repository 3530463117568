import React, { useState } from 'react';
import { Box, Button, Typography, Modal, Link } from '@mui/material';
import { ReactComponent as CrossIcon } from '../cross.svg';
import { ReactComponent as Logo } from '../logo.svg';
import { Formik, Form, Field } from 'formik';
import {TENANT_URL} from './../../../../../../config'
import axios from 'axios';
import { Token } from '@mui/icons-material';

const SignUpModal = ({ open, onClose, signup,logo }) => {
  const [errorMessage , setErrorMessage] = useState("");
  
  const modalCss = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '700px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: 24,
    padding: '25px 39px',
    maxHeight: '90vh',
    overflowY: 'auto',
  };

  // Initial values for Formik
  const initialValues = {
    fullName: '',
    userName: '',
    password: '',
  };

  const handleSubmit = async (values) => {
    // Client-side validation
    if (!values.fullName || !values.userName || !values.password) {
      setErrorMessage('Please enter all required fields.')
      return;
    }
  
    const addConfig = {
      method: 'post',
      url: `${TENANT_URL}/customer/register`,
      headers: {
        'Content-Type': 'application/json',
      },
    };
  
    const data = {
      name: values.fullName,
      email: values.userName,
      password: values.password,
    };
  
    try {
      const response = await axios({ ...addConfig, data });
      if (response.data.email == values.userName) {
        setErrorMessage(response.data.message || 'Register successfully.')
        let data = {
          "name": response.data.name,
          "token": response.data.token
        }
        localStorage.setItem('tenantdata', JSON.stringify(data));
        localStorage.setItem('loginType', "customer");
        window.location.pathname = '/customer/profile';
      } else {
        setErrorMessage(response.data.message || 'Registration failed. Please try again.')
        // alert(response.data.message || 'Login failed. Please try again.');
      }
    } catch (error) {
      setErrorMessage(error.response.data.data.email || error.response.data.data.name || error.response.data.data.password)
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalCss}>
        <div className="d-flex justify-content-end align-items-end mb-3">
          {/* Close icon */}
          <CrossIcon
            onClick={onClose}
            style={{
              cursor: 'pointer',
            }}
          />
        </div>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          {/* <Logo /> */}
          <img 
              src={logo}
              alt="logo" 
              style={{ width: '300px', height: 'auto' }} 
            />
          <Typography
            variant="h6"
            sx={{
              marginBottom: '15px',
              fontSize: '34px',
              fontFamily: 'Outfit',
              fontWeight: '700',
              color: '#3F2F5B',
              marginTop: '20px',
              marginBottom: '30px',
              fontFamily: 'Outfit !important',
            }}
          >
            Create New Account
          </Typography>

          {/* Formik Form */}
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values, handleChange, handleBlur }) => (
              <Form
                style={{
                  width: '100%',
                }}
              >
                {/* Fullname Field */}
                <Field
                  name="fullName"
                  disabled={false} // Update with appropriate logic
                >
                  {({ field, meta }) => (
                    <div>
                      <input
                        {...field}
                        type="text"
                        placeholder="Full Name"
                        style={{
                          backgroundColor: 'white',
                          borderRadius: '10px',
                          height: '51px',
                          width: '100%',
                          marginBottom: '15px',
                          paddingLeft: '20px',
                          borderWidth: 1,
                          outline: 'none',
                          boxShadow: 'none',
                          fontFamily: 'Outfit',
                        }}
                      />
                      {meta.touched && meta.error && (
                        <div style={{ color: 'red' }}>{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>

                {/* UserName Field */}
                <Field
                  name="userName"
                  disabled={false} // Update with appropriate logic
                >
                  {({ field, meta }) => (
                    <div>
                      <input
                        {...field}
                        type="email"
                        placeholder="Email"
                        style={{
                          backgroundColor: 'white',
                          borderRadius: '10px',
                          height: '51px',
                          width: '100%',
                          marginBottom: '15px',
                          paddingLeft: '20px',
                          borderWidth: 1,
                          outline: 'none',
                          boxShadow: 'none',
                          fontFamily: 'Outfit',
                        }}
                      />
                      {meta.touched && meta.error && (
                        <div style={{ color: 'red' }}>{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>

                {/* Password Field */}
                <Field
                  name="password"
                  disabled={false} // Update with appropriate logic
                >
                  {({ field, meta }) => (
                    <div>
                      <input
                        {...field}
                        type="password"
                        placeholder="Password"
                        style={{
                          backgroundColor: 'white',
                          borderRadius: '10px',
                          height: '51px',
                          width: '100%',
                          marginBottom: '15px',
                          paddingLeft: '20px',
                          borderWidth: 1,
                          outline: 'none',
                          boxShadow: 'none',
                          fontFamily: 'Outfit',
                        }}
                      />
                      {meta.touched && meta.error && (
                        <div style={{ color: 'red' }}>{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>
                <Box
                  sx={{
                    marginTop: '20px',
                  }}
                >
                  {errorMessage && (
                    <Typography
                      sx={{
                        color: 'red',
                        fontSize: '14px',
                        marginTop: '10px',
                        textAlign: 'center',
                        fontFamily: 'Outfit !important',
                      }}
                    >
                      {errorMessage}
                    </Typography>
                  )}
                </Box>

                {/* Login Button */}
                <Box
                  sx={{
                    textAlign: 'center',
                    marginTop: '20px',
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="error"
                    sx={{
                      fontSize: '19px',
                      fontWeight: '500',
                      backgroundColor: '#F0645B',
                      minWidth: '188px',
                      height: '51px',
                      borderRadius: '100px',
                      boxShadow: 'none',
                      fontFamily: 'Outfit !important',
                      textTransform: 'capitalize',
                    }}
                  >
                    Sign Up
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>

          {/* SignUp Link */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '3px',
              marginTop: '20px',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: '17px',
                fontFamily: 'Outfit',
                fontWeight: '400',
                color: '#3F2F5B',
                fontFamily: 'Outfit !important',
              }}
            >
              Already have an account?
            </Typography>
            <Link
              sx={{
                fontSize: '17px',
                fontFamily: 'Outfit',
                fontWeight: '600',
                color: '#3F2F5B',
                fontFamily: 'Outfit !important',
                transition: 'all ease 0.3s',
                '&:hover ': {
                  color: '#F0645B',
                },
                cursor:'pointer'
              }}
              onClick={signup}
            >
              SignIn
            </Link>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default SignUpModal;
