import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { TENANT_URL } from '../../../../../../config';
import axios from 'axios';
import { useSnackbar } from '../../../../../../components/utils/snackbarProvider/SnackbarContextProvider';

// Set your Mapbox access token here
mapboxgl.accessToken =
  'pk.eyJ1IjoiZGxmb3JrIiwiYSI6ImNsbGVnZGpqdDBpb28zZGw2ZWcxaTkwOWIifQ.pUuljyS1m29GQZ3FYeFe6Q';
const MapDisplay = ({ startPoint, endPoint }) => {
  const [route, setRoute] = useState(null);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: 'map', // container id
      style: 'mapbox://styles/mapbox/streets-v11', // style URL
      center: getCenter(startPoint, startPoint), // center between two points
      zoom: 15, // starting zoom
    });

    map.on('load', () => {
      // Add navigation control to the map
      map.addControl(new mapboxgl.NavigationControl(), 'top-right');

      // Add markers for start and end points
      if (startPoint[0] !== 0 && startPoint[1] !== 0)
        new mapboxgl.Marker().setLngLat(startPoint).addTo(map);
      // new mapboxgl.Marker().setLngLat(endPoint).addTo(map);

      // Fetch route data from Mapbox Directions API
      // fetchRouteData(map);
    });
  }, [startPoint]);
  // }, [startPoint, endPoint]);

  // Function to calculate the center between two points
  const getCenter = (point1, point2) => {
    return [(point1[0] + point2[0]) / 2, (point1[1] + point2[1]) / 2];
  };

  // Fetch route data from Mapbox Directions API
  const fetchRouteData = async (map) => {
    const accessToken = mapboxgl.accessToken; // Use the access token you've already set
    // const apiUrl = `https://api.mapbox.com/directions/v5/mapbox/driving/${startPoint[0]},${startPoint[1]};?geometries=geojson&access_token=${accessToken}`;
    const apiUrl = `https://api.mapbox.com/directions/v5/mapbox/driving/${startPoint[0]},${startPoint[1]};${endPoint[0]},${endPoint[1]}?geometries=geojson&access_token=${accessToken}`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      // Check if the API response contains any routes
      if (data.routes && data.routes.length > 0) {
        // Draw the route on the map
        map.addLayer({
          id: 'route',
          type: 'line',
          source: {
            type: 'geojson',
            data: {
              type: 'Feature',
              properties: {},
              geometry: data.routes[0].geometry,
            },
          },
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#3887be',
            'line-width': 5,
          },
        });

        // Set the route data in the state for future reference
        setRoute(data);
      } else {
        console.error('No routes found in API response:', data);
      }
    } catch (error) {
      console.error('Error fetching route data:', error);
    }
  };

  return (
    <div>
      <div
        id="map"
        style={{ width: '100%', height: '400px', marginTop: '10px' }}
      ></div>
    </div>
  );
};

function FormModalInputSelectPlan({ age, list, handleChange }) {
  const { show } = useSnackbar();
  const [riderCoordinates, setRiderCoordinates] = useState([]);
  const [loading, setLoading] = useState(false);

  const token = JSON.parse(localStorage.getItem('tenantdata')).token;
  useEffect(() => {
    if (age)
      getRiderDetails(age)
  }, [age]);
  useEffect(() => {
    let intervalId = 0
    if (age) {
      intervalId = setInterval(() => {
        getRiderDetails(age);
      }, 10000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [age]);

  const getRiderDetails = async (age) => {
    setLoading(true);
    const webconfig = {
      method: 'get',
      url: `${TENANT_URL}/get/rider/coordinate/${age}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const webresponse = await axios(webconfig);
      if (!webresponse.data)
        show("no data founded", "error")
        setRiderCoordinates(webresponse.data);
      
      setLoading(false);

    } catch (error) {
      show("Something went wrong!", "error")
      setLoading(false);
      console.log(error);
    }
  };
  const startPoint = [
    riderCoordinates.longitude ? riderCoordinates.longitude : 0,
    riderCoordinates.latitude ? riderCoordinates.latitude : 0,
  ]; // Badshahi Mosque
  // const startPoint = [74.3589, 31.5336];
  const endPoint = [
    riderCoordinates.longitude ? riderCoordinates.longitude : 0,
    riderCoordinates.latitude ? riderCoordinates.latitude : 0,
  ];

  return (
    <>
      <FormControl fullWidth>
        <Typography>Select Route:</Typography>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          label="Route"
          onChange={handleChange}
          sx={{
            borderRadius: '10px',
          }}
        >
          {list?.map((listItem, index) => {
            return (
              <MenuItem key={index} value={listItem?.id}>
                {listItem?.route_name}
              </MenuItem>
            );
          })}
        </Select>
        <Box sx={{ position: 'relative' }}>

          {loading ?
            <Box sx={{ position: "absolute", zIndex: "9999999", top: "12%", left: "45%" }}>
              Loading...
            </Box>
            : null}
          {/* <MapDisplay startPoint={startPoint} /> */}
          <MapDisplay startPoint={startPoint} endPoint={endPoint} />
        </Box>
      </FormControl>
    </>
  );
}

export default FormModalInputSelectPlan;
