import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Dialog, Select, MenuItem, Button, Chip, Grid } from '@mui/material';
import { X } from 'lucide-react';
import Cross from '../assets/cross.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { TENANT_URL } from '../../../../config';

const ProfileMealPlusModal = ({ open, onClose, weekData }) => {
  const [mealPlusSelection, setMealplusSelection] = useState([]);



  const commonSelect = {
    fontSize: '16px',
    fontWeight: '400',
    fontFamily: 'Outfit',
    background: 'linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.45))',
    height: '45px',
    border: '1px solid #dedede',
    lineHeight: '20.16px',
    borderRadius: '10px',
    width: '100%',
    backdropFilter: 'blur(20px)',
    marginY: '15px',
    marginTop: '10px',
    '& .MuiSelect-icon': {
      top: 'calc(50% - 12px)',
      right: '10px',
    },
    boxShadow: 'none',
    '.MuiOutlinedInput-notchedOutline': { border: 0 },
    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
  };

  const fetchMealPlusData = async (startDate, endDate) => {
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    const config = {
      method: 'get',
      url: `${TENANT_URL}/customer/app/meal-plus?from=${startDate}&to=${endDate}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios(config);
      setMealplusSelection(response.data.data);
    } catch (error) {
      console.error('Error fetching card data:', error);
    }
  };

  const handleMealSelect = (index, mealId) => {
    setMealplusSelection((prev) => {
      const updated = [...prev];
      console.log("mealId:",mealId);
      console.log("updated:",updated);
      const selectedMeal = updated[index].meal_items.find(
        (meal) => meal.id === mealId
      );
      console.log("selectedMeal:",selectedMeal);

      if (selectedMeal) {
        updated[index].selected_items.push({
          original_item_id: mealId,
          original_item_name: selectedMeal.name,
          replacement_item_id: null,
          replacement_item_name: null,
        });
      }
      return updated;
    });
  };

  const handleDelete = (index, originalItemId) => {
    setMealplusSelection((prev) => {
      const updated = [...prev];
      updated[index].selected_items = updated[index].selected_items.filter(
        (item) => item.original_item_id !== originalItemId
      );
      return updated;
    });
  };

  const handleOptionChange = (index, originalItemId, replacementId) => {
    setMealplusSelection((prev) => {
      const updated = [...prev];
      const selectedItem = updated[index].selected_items.find(
        (item) => item.original_item_id === originalItemId
      );

      console.log("selectedItem:",selectedItem);

      if (selectedItem) {
        const replacement = updated[index].option_items.find(
          (option) => option.id === replacementId
        );
        selectedItem.replacement_item_id = replacementId;
        selectedItem.replacement_item_name = replacement.name;
      }
      return updated;
    });
  };

  useEffect(() => {
    setMealplusSelection(weekData)
  }, [weekData])

  const handleSave = async () => {
    const transformedData = {
      items: {},
    };

    mealPlusSelection.forEach((meal) => {
      if (meal.selected_items.length > 0) {
        transformedData.items[meal.date] = meal.selected_items.map((item) => ({
          original_item_id: item.original_item_id,
          replacement_item_id: item.replacement_item_id,
        }));
      }
    });

    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    const addConfig = {
      method: 'put',
      url: `${TENANT_URL}/customer/app/meal-plus`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      data: transformedData,
    };

    try {
      await axios(addConfig);
      onClose();
      window.location.reload();
    } catch (error) {
      console.error('Error saving meal plus options:', error);
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '8px',
          p: 3,
          maxWidth: '1100px'
        }
      }}
    >
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h6" sx={{ fontSize: '18px', fontWeight: '500', color: '#2F2F3B' }}>
            Meal+ Selections
          </Typography>
          <IconButton onClick={onClose}>
            <img src={Cross} alt="Close" />
          </IconButton>
        </Box>

        <Grid container spacing={2}>
          {weekData.map((element, index) => (
            <Grid item md={4} key={index}>
              <Typography fontSize={14} fontWeight={700} color="#3F2F5B">
                {new Date(element.date).toLocaleDateString('en-GB', {
                  day: '2-digit',
                  month: 'short',
                  year: 'numeric',
                })}
              </Typography>

              {element.is_available ? (
                <div>
                  <Select
                    value="0"
                    onChange={(event) => handleMealSelect(index, event.target.value)}
                    sx={commonSelect}
                    IconComponent={ExpandMoreIcon}
                  >
                    <MenuItem value="0" disabled>
                      Select Meal+ Item
                    </MenuItem>
                    {element.meal_items.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>

                  {element.selected_items.map((meal, chipIndex) => (
                    <Box
                      key={chipIndex}
                      sx={{
                        display: 'flex',
                        gap: 2,
                        alignItems: 'center',
                        mb: 2,
                      }}
                    >
                      <Chip
                        label={meal.original_item_name}
                        onDelete={() => handleDelete(index, meal.original_item_id)}
                        color="primary"
                        sx={{
                          color: '#ffffff',
                          '& .MuiChip-deleteIcon': { color: '#ffffff' },
                          width: '48%',
                          justifyContent: 'space-between',
                        }}
                      />
                      <Select
                        value={meal.replacement_item_id || '0'}
                        onChange={(event) =>
                          handleOptionChange(
                            index,
                            meal.original_item_id,
                            event.target.value
                          )
                        }
                        sx={{
                          ...commonSelect,
                          mt: 0,
                          my: 0,
                          width: '48%',
                        }}
                        IconComponent={ExpandMoreIcon}
                      >
                        <MenuItem value="0" disabled>
                          Select options
                        </MenuItem>
                        {element.option_items.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  ))}
                </div>
              ) : (
                <Typography fontSize={12} color="gray" sx={{ mt: 2 }}>
                  No Meal Plus Available
                </Typography>
              )}
            </Grid>
          ))}
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 4 }}>
          <Button
            onClick={onClose}
            variant="outlined"
            sx={{
              fontSize: '14px',
              fontWeight: '500',
              borderColor: '#F0645B',
            }}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            sx={{
              fontSize: '14px',
              fontWeight: '500',
              backgroundColor: '#F0645B',
              '&:hover': {
                backgroundColor: '#d32f2f',
              }
            }}
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ProfileMealPlusModal;