import React from 'react';
import { Box, Typography } from '@mui/material';

const SubscriptionComponent = ({planDetail,planFree,addon,additionalDetail,additionalFee}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: '15px',
        background: '#FFFFFF1A',
        borderRadius: '8px',
        border: 'solid 1px white',
        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.1)',
        rowGap: '8px',
      }}
    >
      {/* Plan Type and Total */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mb: '10px',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Outfit',
            fontSize: '16px',
            fontWeight: '500',
            lineHeight: '23px',
            textAlign: 'left',
          }}
        >
          Running Plan
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ml: 'auto',
            gap: '3px',
          }}
        >
          <Typography
            sx={{
              fontSize: '13px',
              fontWeight: '500',
              color: '#3F2F5B',
              opacity: 0.6,
            }}
          >
            Total:
          </Typography>
          <Typography
            sx={{
              fontSize: '15px',
              fontWeight: '700',
              color: '#ff6b00',
            }}
          >
            ${(planFree || 0) + (additionalFee || 0)}
          </Typography>
        </Box>
      </Box>

      {/* Current Plan Details */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          sx={{ fontSize: '12px', color: '#000000', fontWeight: '600' }}
        >
          Current Plan Details
        </Typography>
        <Typography
          sx={{ fontSize: '12px', color: '#000000', fontWeight: '600' }}
        >
          ${planFree || 0}
        </Typography>
      </Box>
      <Typography sx={{ fontSize: '13px', color: '#52526CCC' }}>
        {planDetail}
      </Typography>

      {/* Addon */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '5px',
        }}
      >
        <Typography
          sx={{ fontSize: '12px', color: '#000000', fontWeight: '600' }}
        >
          Addon
        </Typography>
        <Typography
          sx={{ fontSize: '12px', color: '#000000', fontWeight: '600' }}
        ></Typography>
      </Box>
      <Typography sx={{ fontSize: '13px', color: '#52526CCC' }}>
        {addon}
      </Typography>

      {/* Additional Items */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '5px',
        }}
      >
        <Typography
          sx={{ fontSize: '12px', color: '#000000', fontWeight: '600' }}
        >
          Additional Items
        </Typography>
        <Typography
          sx={{ fontSize: '12px', color: '#000000', fontWeight: '600' }}
        >
          ${additionalFee || 0}
        </Typography>
      </Box>
      <Typography sx={{ fontSize: '13px', color: '#52526CCC' }}>
        {additionalDetail}
      </Typography>
    </Box>
  );
};

export default SubscriptionComponent;
