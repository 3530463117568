import { Box, Button, styled, TextField, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useUtility } from '../useUtility';
import CustomerBulkAction from '../../customers/customerBulkAction';
import { useRoute } from '../../../customHooks/useRoute';
import ConfirmationModal from './components/modals/confirmationModal/confirmationModal';
import AddNewRouteDialogeBox from './components/modals/addNewRoute';
import SearchTextField from '../../../components/SearchTextField';

export const CustomTextField = styled(TextField)`
  fieldset {
    border-radius: 50px;
  }
`;

const RouteListGridHeaderButton = ({ children, onClick }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box sx={{ width: !isMdUp ? '100%' : 'auto' }}>
      <Button
        onClick={onClick}
        variant="filled"
        sx={{
          mx: isMdUp ? 1 : 0,
          my: !isMdUp ? 0.5 : 0,

          width: !isMdUp ? '100%' : 'auto',
          borderRadius: '20px',
          textTransform: 'capitalize',
          px: { md: 3, lg: 3, sm: 2, xs: 1 },
          fontWeight: 400,
          color: 'white',
          boxShadow: 'none',
          marginRight: '10px',
        }}
      >
        {children}
      </Button>
    </Box>
  );
};

const RouteListDisplayHeader = ({
  keyword,
  setKeyword,
  bulkAction,
  setBulkAction,
  bulkId,
  permissions,
}) => {
  const {
    itemIdForUpdate,
    setItemIdForUpdate,
    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
    isBulkConfirmationModalOpen,
    setIsBulkConfirmationModalOpen,
    setIsEditModalOpen,
    isEditModalOpen,
  } = useUtility();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const { deleteRoute, deleteBulkRoutes } = useRoute();

  const handClickApply = () => {
    if (bulkAction === 'Delete') {
      setIsBulkConfirmationModalOpen(true);
    }
  };

  const BootstrapInput = {
    'label + &': {
      marginTop: '10px',
    },
    '& .MuiInputBase-input': {
      position: 'relative',
      background: 'transparent',
      fontSize: 16,
      padding: '15px',
      height: '38px',
      boxSizing: 'border-box',
      borderRadius: '100px',
      border: '1px solid #B0B0B0',
      width: '100%',
      '&:focus': {},
    },
    '& .MuiInputBase-root': {
      borderRadius: '100px',
    },
  };

  const SPGridHeaderButton = ({ children, onClick, sx }) => {
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

    return (
      <Button
        onClick={onClick}
        variant="filled"
        sx={{
          width: 'inherit',
          borderRadius: '25px',
          textTransform: 'capitalize',

          py: 1.4,
          fontWeight: 400,
          color: 'white',

          boxShadow: 'none',
          marginLeft: '10px',
          ...sx,
        }}
        type="submit"
      >
        {children}
      </Button>
    );
  };

  return (
    <>
      <ConfirmationModal
        isOpen={isBulkConfirmationModalOpen}
        setIsOpen={setIsBulkConfirmationModalOpen}
        title={'Are you sure?'}
        text={`Are you sure you want to delete it? This action cannot be undone. Deleting this item will permanently remove it along with its associated data from our records. Please confirm if you wish to proceed.`}
        successColor={'error'}
        cancelButtonText={'Cancel'}
        successButtonText={'Delete Permanently'}
        confirmationFunction={() => deleteBulkRoutes(bulkId)}
      />
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        title={'Are you sure?'}
        text={`Are you sure you want to delete it? This action cannot be undone. Deleting this item will permanently remove it along with its associated data from our records. Please confirm if you wish to proceed.`}
        successColor={'error'}
        cancelButtonText={'Cancel'}
        successButtonText={'Delete Permanently'}
        confirmationFunction={() => deleteRoute(itemIdForUpdate)}
      />

      <Box
        sx={{
          display: isMdUp ? 'flex' : 'block',
          width: '100%',
          px: '10.4px',
          py: 2,
        }}
      >
        <Box sx={{ width: !isMdUp ? '100%' : '30%' }}>
          <SearchTextField keyword={keyword} setKeyword={setKeyword} />
        </Box>

        <Box
          sx={{
            ml: 'auto',
            display: isMdUp ? 'flex' : 'block',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            {/* <Box
              sx={{
                position: 'relative',
                minWidth: '200px',
              }}
            >
              <TextField
                placeholder={'Standard Route Charges'}
                sx={BootstrapInput}
                variant="outlined"
                InputProps={{
                  disableUnderline: true,
                }}
                style={{
                  width: '280px',
                  borderRadius: '100px',
                }}
              />
              <SPGridHeaderButton
                variant="filled"
                text={'Apply'}
                sx={{
                  borderRadius: '100px',
                  minWidth: '60px',
                  position: 'absolute',
                  right: 0,
                  height: '38px',
                }}
              >
                Save
              </SPGridHeaderButton>
            </Box> */}
            <AddNewRouteDialogeBox
              Open={isEditModalOpen}
              setOpen={setIsEditModalOpen}
              permission={permissions}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RouteListDisplayHeader;
