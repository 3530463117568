import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import LineSvg from '../../assets/icons/Line.svg';
// import restaurantIcon from '../../icons/mingcute_time-line.svg';
import restaurantIcon from '../../../../../icons/mingcute_time-line.svg';
import { Padding } from '@mui/icons-material';

import { TENANT_URL } from '../../../../../config';

import axios from 'axios';
function Menu5() {

  const [restaurantTiming , setRestaurantTiming] = useState([]);

  const getRestaurantTiming = async () => {
    var config = {
      method: 'get',
      url: `${TENANT_URL}/api/restaurant-timing`,
    };
    try {
      const response = await axios(config);
      setRestaurantTiming(response.data.data);
    } catch (error) {
      console.log(error);
      return {};
    }
  }

  useEffect(() => {
    getRestaurantTiming();
  }, [])

  const typStyle = {
    fontSize: '16px',
    fontWeight: 400,
    color: '#000000',
    fontFamily: 'Inter !important',
  };
  const timeStyle = {
    color: '#4A4A4A',
    fontFamily: 'Inter !important',
    fontSize: '16px',
    fontWeight: 400,
  };

  const boxStyle = {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    justifyContent: 'space-between',
    padding: '12px',
    backgroundColor: '#ffffff',
    marginBottom: '10px',
    borderRadius: '15px',
  };

  return (
    <>
    {(restaurantTiming.length>0)?
    <Box
      sx={{
        background: '#FCFCFC',
        boxShadow: '0 0px 6px rgba(0, 0, 0, 0.1)',
        borderRadius: '15px',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          gap: '30px',
          backgroundColor: '#FCFCFC',
          borderRadius: '15px',
          width: '100%',
        }}
      >

        <Box sx={boxStyle}>
          <Typography sx={typStyle}><img src={restaurantIcon} alt="icn"/> Monday</Typography>
          <Box sx={timeStyle}>
              {!!restaurantTiming[0].monday ? <>{restaurantTiming[0].monday_opening} - {restaurantTiming[0].monday_closing}</> : "Closed" }
            </Box>
        </Box>
        <Box sx={boxStyle}>
          <Typography sx={typStyle}><img src={restaurantIcon} alt="icn"/> Tuesday</Typography>
          <Box sx={timeStyle}>
            {!!restaurantTiming[0].tuesday ? <>{restaurantTiming[0].tuesday_opening} - {restaurantTiming[0].tuesday_closing}</> : "Closed" }</Box>
        </Box>
        <Box sx={boxStyle}>
          <Typography sx={typStyle}><img src={restaurantIcon} alt="icn"/> Wednesday</Typography>
          <Box sx={timeStyle}>
            {!!restaurantTiming[0].wednesday ? <>{restaurantTiming[0].wednesday_opening} - {restaurantTiming[0].wednesday_closing}</> : "Closed" }</Box>
        </Box>
        <Box sx={boxStyle}>
          <Typography sx={typStyle}><img src={restaurantIcon} alt="icn"/> Thursday</Typography>
          <Box sx={timeStyle}>
            {!!restaurantTiming[0].thursday ? <>{restaurantTiming[0].thursday_opening} - {restaurantTiming[0].thursday_closing}</> : "Closed" }</Box>
        </Box>
        <Box sx={boxStyle}>
          <Typography sx={typStyle}><img src={restaurantIcon} alt="icn"/> Friday</Typography>
          <Box sx={timeStyle}>
            {!!restaurantTiming[0].friday ? <>{restaurantTiming[0].friday_opening} - {restaurantTiming[0].friday_closing}</> : "Closed" }</Box>
        </Box>
        <Box sx={boxStyle}>
          <Typography sx={typStyle}><img src={restaurantIcon} alt="icn"/> Saturday</Typography>
          <Box sx={timeStyle}>
            {!!restaurantTiming[0].saturday ? <>{restaurantTiming[0].saturday_opening} - {restaurantTiming[0].saturday_closing}</> : "Closed" }</Box>
        </Box>
        <Box sx={{ ...boxStyle, marginBottom: 0 }}>
          <Typography sx={typStyle}><img src={restaurantIcon} alt="icn"/> Sunday</Typography>
          {!!restaurantTiming[0].sunday ? <>{restaurantTiming[0].sunday_opening} - {restaurantTiming[0].sunday_closing}</> : "Closed" }
        </Box>
      </Box>
    </Box>
    :<></> }
    </>
  );
}

export default Menu5;
