import React, { useState, useEffect } from 'react';
import './styles/HeaderStyles/HeroSection3/Header.css';

import LoginModal from './components/modals/loginmodal';
import SignUpModal from './components/modals/signupmodal';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { ShoppingCart } from 'lucide-react';
import { useParams, useNavigate } from 'react-router-dom';

import { TENANT_URL } from '../../../config';

import axios from 'axios';
import { Button } from 'react-day-picker';
export const ServiceNavbar = () => {
  const navigate = useNavigate();
  const [activeCategory, setActiveCategory] = useState('All');

  const [openModal, setOpenModal] = useState(false);
  const [bannerList, setBannerList] = useState([]);
  const [randomVal, setRandomVal] = useState(0);
  const [logo, setLogo] = useState('');
  const [bannerData, setBannerData] = useState('');

  let tokenData = localStorage.getItem('tenantdata');
  if (tokenData) {
    tokenData = JSON.parse(tokenData);
  }

  // const storedLogo = localStorage.getItem('app-logo');
  const handleOpenModal = () => {
    setOpenModal(true);
    setOpenModal1(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setOpenModal1(false);
  };

  // SingUp Modal
  const [openModal1, setOpenModal1] = useState(false);
  const handleCloseModal1 = () => {
    setOpenModal1(false);
    setOpenModal(false);
  };
  const handleOpenModal1 = () => {
    setOpenModal1(true);
    setOpenModal(false);
  };

  const getLogo = async () => {
    const config = {
      method: 'get',
      url: `${TENANT_URL}/api/restaurant/logo`,
    };

    try {
      const response = await axios(config);
      const logo = response.data.data.logo;
      setLogo(logo);
      localStorage.setItem('app-logo', logo);
      return logo;
    } catch (error) {
      console.error('Error fetching logo:', error);
    }
  };

  useEffect(() => {
    getLogo();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('tenantdata');
    localStorage.removeItem('loginType');
    localStorage.removeItem('selectedRoute');
    window.location.reload();
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <>
      {/* Header Mask Wrapper */}
      <div className="paBoth">
        <div className="row">
          <div className="col-md-12">
            <header className="container-fluid">
              <nav className="navbar navbar-expand-xl navbar-light">
                <div className="container-fluid">
                  {/* Logo Section */}
                  <a
                    className="navbar-brand d-flex align-items-center"
                    href="javascript:void(0)"
                    onClick={() => navigate('/')}
                  >
                    <img
                      src={logo}
                      alt="logo"
                      style={{
                        width: '100px',
                        height: '100px',
                        cursor: 'pointer',
                      }}
                    />
                  </a>

                  {/* Right Section */}
                  <div className="d-flex align-items-center right_btns showMobile_dev">
                    <Link
                      to=""
                      className="me-3"
                      style={{ cursor: 'pointer' }}
                      onClick={() => navigate('/cart')}
                    >
                      <ShoppingCart color="#F96221" />
                    </Link>
                    {tokenData ? (
                      <button
                        onClick={handleClick}
                        className="btn btn-outline-primary"
                      >
                        My Account
                      </button>
                    ) : (
                      <>
                        <Link
                          href="#"
                          onClick={handleOpenModal}
                          className="btn btn-primary me-2"
                        >
                          Login
                        </Link>
                        <button
                          onClick={handleOpenModal1}
                          className="btn btn-primary"
                        >
                          Create Account
                        </button>
                      </>
                    )}
                  </div>

                  {/* Toggle Button */}
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleNavbar}
                    aria-expanded={!isCollapsed}
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>

                  {/* Collapsible Navigation */}
                  <div
                    className={`collapse navbar-collapse ${
                      !isCollapsed ? 'show' : ''
                    }`}
                    id="navbarNav"
                  >
                    <ul className="navbar-nav m-auto mt-0 mb-lg-0">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          aria-current="page"
                          href="/"
                        >
                          Home
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          How it works
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Our Work
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Pricing
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Contact Us
                        </a>
                      </li>
                    </ul>

                    {/* Right Section */}
                    <div className="d-flex align-items-center right_btns hideMobile_dev">
                      <Link
                        to=""
                        className="me-3"
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate('/cart')}
                      >
                        <ShoppingCart color="#F96221" />
                      </Link>
                      {tokenData ? (
                        <button
                          onClick={handleClick}
                          className="btn btn-outline-primary"
                        >
                          My Account
                        </button>
                      ) : (
                        <>
                          <Link
                            href="#"
                            onClick={handleOpenModal}
                            className="btn btn-primary me-2"
                          >
                            Login
                          </Link>
                          <button
                            onClick={handleOpenModal1}
                            className="btn btn-primary"
                          >
                            Create Account
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </nav>
            </header>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              sx={{
                top: 74,
                '& .MuiPopover-paper': {
                  boxShadow: 'none',
                  borderRadius: '11px',
                  minWidth: '170px',
                  border: '1px solid #f5f5f5',
                },
                '& .MuiList-padding': {
                  paddingTop: 0,
                  paddingBottom: 0,
                  overflow: 'hidden',
                },
                '& .MuiMenuItem-gutters': {
                  padding: '10px 18px',
                  border: '1px solid #f5f5f5',
                },
              }}
            >
              <MenuItem
                component="a"
                href={
                  tokenData && !tokenData.tenant_nav_bar_permission
                    ? '/customer/profile'
                    : '/admin'
                }
              >
                Profile
              </MenuItem>
              {tokenData && !tokenData.tenant_nav_bar_permission && (
                <>
                  <MenuItem
                    component="a"
                    href="/customer/subscription"
                    onClick={() =>
                      localStorage.setItem('selectedRoute', 'Subscriptions')
                    }
                  >
                    Subscription
                  </MenuItem>
                  <MenuItem
                    component="a"
                    href="/customer/orderhistory"
                    onClick={() =>
                      localStorage.setItem('selectedRoute', 'Order History')
                    }
                  >
                    Order History
                  </MenuItem>
                  <MenuItem
                    component="a"
                    href="/customer/paymenthistory"
                    onClick={() =>
                      localStorage.setItem('selectedRoute', 'Payment History')
                    }
                  >
                    Payment History
                  </MenuItem>
                </>
              )}
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </div>
      </div>
      <LoginModal
        open={openModal}
        onClose={handleCloseModal}
        signup={handleOpenModal1}
        logo={logo}
      />
      <SignUpModal
        open={openModal1}
        onClose={handleCloseModal1}
        signup={handleOpenModal}
        logo={logo}
      />
    </>
  );
};
