import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './assets/customerProfileScrollBar/profileScrollBar.css';
import UpdateProfileCardModal from '../../../tenant/pages/profile/components/updateProfileCard/updateProfileCardModal';
import { useProfile } from '../../../tenant/customHooks/useProfile';
import UpgradeSubscription from '../../../tenant/pages/profile/components/upgradeSubscription';
import DynamicCalendar from '../../../tenant/pages/profile/components/dynamicMonthlyCalendar';
import Notifications from '../../../tenant/pages/profile/components/notifications';
import OrderHistory from '../../../tenant/pages/profile/components/orderHistory';
import PaymentHistory from '../../../tenant/pages/profile/components/paymentHistory';
import Spinner from '../../../components/utils/spinner';
import ProfileCard from './ProfileCard';
import DataCards from './DataCard';

const CustomerSectionProfile = () => {
  const { loading, currentCustomerData } = useProfile();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  useEffect(() => {
    if (currentCustomerData && Object.keys(currentCustomerData).length > 0) {
      setOpen(true);
    }
  }, [currentCustomerData]);

  return (
    <div style={{ backgroundColor: 'white', padding: '30px' }}>
      {loading ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </Box>
      ) : (
        <>
          <UpdateProfileCardModal setOpen={setOpen} open={open} />
          <Box>
            <Box
              sx={{
                fontFamily: "'Roboto', sans-serif",
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                mx: 'auto',
                backgroundColor:
                  'linear-gradient(106deg, rgb(237 16 16 / 37%) 0%, rgba(228, 245, 255, 0) 100%)',
              }}
            >
              {/* <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  gap: '30px',
                }}
              > */}

              <Grid container spacing={2}>
                <Grid item xs={12} lg={7} xl={8} xxl={9}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      gap: '30px',
                    }}
                  >
                    <DataCards />
                    <DynamicCalendar />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={5} xl={4} xxl={3}>
                  <Box
                    sx={
                      {
                        // display: 'flex',
                        // flexDirection: 'column',
                        // justifyContent: 'space-between',
                        // maxWidth: '410px',
                      }
                    }
                  >
                    <Grid container rowSpacing={'30px'}>
                      <Grid item xs={12}>
                        <ProfileCard setOpen={setOpen} />
                      </Grid>
                      <Grid item xs={12}>
                        {/* <PaymentHistory /> */}
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>

              {/* </Box> */}
            </Box>
          </Box>
        </>
      )}
    </div>
  );
};

export default CustomerSectionProfile;
