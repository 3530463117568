import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Chip,
  IconButton,
  Badge,
  Autocomplete,
  TextField,
} from '@mui/material';
import { styled } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { useProfile } from '../../../customHooks/useProfile';

/** Custom Styled Accordion */
const StyledAccordion = styled(Accordion)(({ theme }) => ({
  padding: '4px',
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },
  '& .MuiAccordionSummary-root': {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  '& .MuiAccordionSummary-root:last-child': {
    borderBottom: 'none',
  },
  '& .MuiBadge-badge': {
    color: 'white',
    height: '28px',
    width: '32px',
    borderRadius: '30px',
    backgroundColor: '#F0645B',
  },
  '& .MuiChip-root': {
    backgroundColor: '#F0645B',
  },
  '& .MuiChip-label': {
    color: 'white',
  },
}));

const AdditionalMenuNew = ({ open, handleClose }) => {
  const {
    updateAdditionalItems,
    updateAdditionalItemsTemp,
    setTempAdditionalPlan,
    allMealItems,
    getAdditionalMenuItems,
    getAdditionalItems,
    isNewPlanStatus,
    mealItemsIds,
    setMealItemsIds,
  } = useProfile();
  const [currentMenuItemsData, setCurrentMenuItemsData] = useState({});

  const [mealItems, setMealItems] = useState({
    Everyday: [],
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });

  

  const handleAddMeal = (day, meal) => {
    if (meal) {
      let mealID = 0;

      Object.values(allMealItems).forEach((entity) => {
        entity.forEach((element) => {
          if (element.name == meal) {
            mealID = element.id;
          }
        });
      });

      setMealItems((prev) => ({
        ...prev,
        [day]: [...prev[day], meal],
      }));

      setMealItemsIds((prev) => ({
        ...prev,
        [day]: [...prev[day], mealID],
      }));
    }
  };

  const handleDeleteMeal = (day, meal) => {
    if (meal) {
      let mealID = 0;
      Object.values(allMealItems).forEach((entity) => {
        entity.forEach((element) => {
          if (element.name == meal) {
            mealID = element.id;
          }
        });
      });

      setMealItems((prev) => ({
        ...prev,
        [day]: prev[day].filter((item) => item !== meal),
      }));

      setMealItemsIds((prev) => ({
        ...prev,
        [day]: prev[day].filter((item) => item !== mealID),
      }));
    }
  };

  const getTotalItems = () => {
    return Object.values(mealItems).reduce(
      (total, dayItems) => total + dayItems.length,
      0
    );
  };

  const getAllItems = () => {
    return Object.entries(mealItems).flatMap(([day, items]) =>
      items.map((item) => ({ day, item }))
    );
  };

  const allUniqueMeals = Array.from(
    new Set(getAllItems().map((entry) => entry.item))
  );

  const additionalItems = () => {
    handleClose();

    if(isNewPlanStatus == false) {
      let updateResponse = updateAdditionalItems(mealItemsIds);
    } else {
      let updateResponse = updateAdditionalItemsTemp(mealItemsIds);
      console.log("updateResponse:",updateResponse);
      setTempAdditionalPlan(updateResponse);
    }
  };


  useEffect(() => {
    setMealItems({
      Everyday: [],
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: [],
      Sunday: [],
    });
    setMealItemsIds({
      Everyday: [],
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: [],
      Sunday: [],
    });
  }, [isNewPlanStatus])

  const transformCustomMenu = (menu, allMealItems) => {
    const result = {};
    const resultIds = {};

    // Predefined order of days
    const days = [
      'Everyday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];

    // Validate that allMealItems is an object
    if (typeof allMealItems !== 'object' || allMealItems === null) {
      throw new Error('allMealItems must be an object of objects');
    }

    days.forEach((day) => {
      day = day.toLowerCase();
      console.log("day:",day);
      if (menu[day] && Array.isArray(menu[day].items)) {
        // Transform items by finding their names and IDs in allMealItems
        const names = [];
        const ids = [];

        menu[day].items.forEach((itemId) => {
          console.log("itemId:",itemId)
          Object.values(allMealItems).forEach((entity) => {
            entity.forEach((element) => {
              if (element.id === itemId) {
                names.push(element.name); // Collect the name
                ids.push(element.id); // Collect the ID
              }
            });
          });
        });

        result[day] = names; // Add names for the day
        resultIds[day] = ids; // Add IDs for the day
      } else {
        result[day] = [];
        resultIds[day] = [];
      }
    });
    return { result, resultIds };
  };

  useEffect(() => {
    getAdditionalMenuItems().then(({ data }) => {
      setCurrentMenuItemsData(data.data);
    });
    getAdditionalItems().then(({ data }) => {
      const { result, resultIds } = transformCustomMenu(data, allMealItems);

      console.log("data:",data);
      console.log("result:",result);
      console.log("resultIds:",resultIds);
      setMealItems(result);
      setMealItemsIds(resultIds);
    });
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Additional Menu Items
        <IconButton
          onClick={handleClose}
          style={{ position: 'absolute', right: 16, top: 16, width: '40px', height: '40px' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {/* Ongoing Items Accordion */}
        {/* <StyledAccordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              Ongoing Items{' '}
              <Badge
                badgeContent={getTotalItems()}
                style={{
                  marginLeft: 20,
                }}
                color="primary"
              />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
              {getAllItems().map((entry, index) => (
                <Chip
                  key={index}
                  label={`${entry.day}: ${entry.item}`}
                  color="primary"
                />
              ))}
            </div>
          </AccordionDetails>
        </StyledAccordion> */}

        {/* Individual Days Accordion */}
        {Object.keys(mealItems).map((day, index) => (
          <StyledAccordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
              {(day == "Everyday" ? "Ongoing Items" : day.charAt(0).toUpperCase() + day.slice(1))}{' '}
                <Badge
                  badgeContent={mealItems[day].length}
                  color="primary"
                  style={{ marginLeft: 20 }}
                />
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
                {mealItems[day].map((meal, idx) => (
                  <Chip
                    key={idx}
                    label={meal}
                    onDelete={() => handleDeleteMeal(day, meal)}
                    color="secondary"
                  />
                ))}
              </div>
              <Autocomplete
                options={Object.values(allMealItems)
                  .flatMap((group) => group.map((item) => item.name))
                  .filter((meal) => !mealItems[day].includes(meal))} // Exclude selected items
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Item" />
                )}
                onChange={(e, value) => {
                  if (value) handleAddMeal(day, value); // Handle addition
                }}
                style={{ marginTop: 16 }}
                sx={{
                  '.MuiInputBase-root': {
                    padding: '5px',
                  },
                  '.MuiButtonBase-root': {
                    height: '30px',
                  },
                }}
              />
            </AccordionDetails>
          </StyledAccordion>
        ))}
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          gap: '12px',
        }}
      >
        <Button
          onClick={() => {
            setMealItems({
              Everyday: [],
              Monday: [],
              Tuesday: [],
              Wednesday: [],
              Thursday: [],
              Friday: [],
              Saturday: [],
              Sunday: [],
            });
            setMealItemsIds({
              Everyday: [],
              Monday: [],
              Tuesday: [],
              Wednesday: [],
              Thursday: [],
              Friday: [],
              Saturday: [],
              Sunday: [],
            });
          }}
          color="secondary"
          sx={{
            border: '1px solid #F0645B',
            color: '#F0645B',
          }}
        >
          Reset
        </Button>
        <Button
          onClick={additionalItems}
          variant="contained"
          color="primary"
          sx={{ backgroundColor: '#F0645B' }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdditionalMenuNew;
