import { Box } from "@mui/material";
import React, { useState } from "react";
import CustomButton from "./CustomRadioButton";
import { ButtonComponents, OutlineComponents } from "./ButtonImports";
import { useStateContext } from "../../../api/stateContext";

export default function ButtonStylesComponents() {
  const [selectedButton, setSelectedButton] = useState(null);
  const [activeButtonColor, setActiveButtonColor] = useState("#E4BA67");
  const { state, dispatch } = useStateContext();

  const handleActiveButton = (index) => {
    dispatch({ type: "SET_ACTIVE_BUTTONS", payload: index });
    setSelectedButton(index);
  };
  const handleActiveColor = (color) => {
    dispatch({ type: "SET_ACTIVE_BUTTON_COLOR", payload: color });
    setActiveButtonColor(color);
  };
  const handleActiveColorChange = (event) => {
    const inputValue = event.target.value;
    dispatch({ type: "SET_ACTIVE_BUTTON_COLOR", payload: `#${inputValue}` });
  };
  const renderButtons = (components, startIndex, endIndex, prefix) => {
    const buttonKey = "activeButton";
    const buttonBackground = "backgroundColor";

    const buttonStyles = {
      borderRadius: "10px",
      padding: "10px",
    };

    return (
      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexWrap: "wrap",
          alignItems: "center",
          px: 3,
          boxSizing: "border-box",
          width: "100%",
        }}
      >
        {components.map((Component, index) => {
          const buttonIndex = prefix + (index + 1);
          const isActive = state[buttonKey] === buttonIndex;

          return (
            <Box
              key={buttonIndex}
              onClick={() => handleActiveButton(buttonIndex)}
              sx={{
                ...buttonStyles,
                [buttonBackground]: isActive ? "#FE7A5299" : "",
              }}
            >
              <Component backgroundColor={state.activeButtonColor}>
                {buttonIndex}
              </Component>
            </Box>
          );
        })}
      </Box>
    );
  };

  // console.log("sasm:", state.activeButtonColor, activeButtonColor);

  const colors = ["#E4BA67", "#45BD43", "#6074E1", "#C55D5D", "#DC6FD8"];

  const renderColorSelectionButtons = (count) => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          px: 3,
        }}
      >
        <Box
          className="ColorSelection"
          sx={{
            display: "flex",
            gap: "24px",
            justifyContent: "center",
            alignContent: "center",
            flexWrap: "wrap",
          }}
        >
          {colors.map((color, index) => (
            <CustomButton
              isChecked={state.activeButtonColor === color}
              onCheckChange={() => handleActiveColor(color)}
              color={color}
            />
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            gap: "18px",
          }}
        >
          <span
            style={{
              alignSelf: "center",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "18.75px",
            }}
          >
            Hex
          </span>
          <input
            id="colorTexfield"
            type="text"
            value={activeButtonColor.replace("#", "")}
            onChange={(event) => handleActiveColorChange(event)}
            style={{
              color: "#FF7676",
              width: "159px",
              height: "47px",
              padding: "14px 12px",
              borderRadius: "6px",
              border: "1px solid",
              gap: "10px",
              background: "transparent",
              textAlign: "center",
              outline:'none'
            }}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "25px",
      }}
    >
      {/* Filled Buttons */}
      {renderButtons(ButtonComponents, 9, 16, "Button")}
      {/* Outlined Buttons */}
      {renderButtons(OutlineComponents, 0, 8, "Outline")}

      {/* Color Selection Buttons */}
      {renderColorSelectionButtons(5)}
    </Box>
  );
}
