import React, { useEffect, useState } from 'react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

import { Button, Typography } from '@mui/material';
import SliderImg from '../../assets/images/slider.png';
import CircleImg from '../../assets/images/circle.png';
import { TENANT_URL } from '../../../../../config';
import axios from 'axios';

export default function TrialComponent() {
  const [itemsImage, setItemImage] = useState([]);

  const getItemsImage = async () => {
    try {
      const response = await axios.get(`${TENANT_URL}/api/items-images`);
      const images = response.data.data || []; // Default to an empty array
      console.log('Fetched images:', images);
      setItemImage(images);
    } catch (error) {
      console.error('Error fetching images:', error);
      setItemImage([]); // Fallback to an empty array
    }
  };

  useEffect(() => {
    getItemsImage();
  }, []);

  console.log('itemsImage:', itemsImage);
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      slidesPerView={1}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
      pagination={{ clickable: true }}
      className="swNews"
    >
      {Object.entries(itemsImage).map(([key, value], i) => (
        <SwiperSlide key={i}>
          <div className="row align-items-center position-relative">
            {/* <div className="col-md-5">
                <Typography
                  variant="h4"
                  lineHeight="40px"
                  sx={{
                    fontSize: '32px',
                    fontWeight: 700,
                    letterSpacing: '2%',
                    color: '#ffffff',
                    mb: 2,
                    fontFamily: 'Outfit !important',
                  }}
                >
                  Enjoy Free Meals On An Exclusive Trial
                </Typography>
                <Typography
                  variant="h4"
                  lineHeight="28px"
                  sx={{
                    fontSize: '17px',
                    fontWeight: 500,
                    color: '#ffffff',
                    mb: 5,
                    fontFamily: 'Outfit !important',
                  }}
                >
                  Lorem ipsum dolor sit amet consectetur. Viverra amet sed est
                  dis. Malesuada consectetur est dui tempor diam id.
                </Typography>

                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#FACD2D',
                    borderRadius: 100,
                    textTransform: 'capitalize',
                    boxShadow: 'none',
                    color: '#000000',
                    height: 'auto',
                    padding: '13px 26px',
                    fontFamily: 'Outfit !important',
                    '&:hover': {
                      backgroundColor: '#FACD2D',
                      boxShadow: 'none',
                      opacity: 0.5,
                    },
                  }}
                >
                  Register Now
                </Button>
              </div> */}
            <div className="col-md-12 text-center">
              <img
                src={value}
                alt="Slider Image"
                style={{
                  width: '450px',
                  objectFit: 'contain',
                  height: '450px',
                }}
              />
            </div>
            <img
              src={CircleImg}
              alt="Decorative Circle"
              style={{
                width: '60px',
                objectFit: 'contain',
                height: '60px',
                position: 'absolute',
                right: '15px',
                top: 0,
              }}
            />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
