import { Switch, Typography } from '@mui/material';
import WebCloseTime from '../webAddForm/webCloseTime';
import WebStartTime from '../webAddForm/webStartTime';
import WebSwitch from '../webAddForm/webSwitch/webSwitch';
const label = { inputProps: { 'aria-label': 'Switch demo' } };

const RouteColumns = (setRenderList) => [
  {
    field: 'days',
    headerName: 'Days',
    flex: 1,
    minWwidth: 220,
    headerClassName: 'action-cell',
    cellClassName: 'column-cell',
  },
  {
    field: 'closing_time',
    headerName: 'Timing',
    flex: 1,
    headerClassName: 'action-cell',
    cellClassName: 'column-cell',
    minWidth: 190,
    renderCell: (params) => (
      <Typography>{params.row.closing_time}</Typography>
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    headerClassName: 'action-cell',
    cellClassName: 'column-cell',
    alignItem: 'center',
    minWidth: 80,
    renderCell: (params) => (
      <Switch
        {...label}
        checked={params.row.status}
        onChange={(e) => {
          const updatedStatus = e.target.checked;
          setRenderList((prevList) =>
            prevList.map((item) =>
              item.id === params.row.id
                ? { ...item, status: updatedStatus }
                : item
            )
          );
        }}
        defaultChecked
      />
    ),
  },
];

export default RouteColumns;