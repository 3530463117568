import React, { useState } from 'react';
import { Box, Button, Typography, Modal, Link } from '@mui/material';
import { ReactComponent as CrossIcon } from '../cross.svg';
import { ReactComponent as Logo } from '../logo.svg';
import { Formik, Form, Field } from 'formik';
import axios from 'axios';
import {TENANT_URL} from './../../../../../../config'


const BannerModel = ({ open, onClose , data}) => {
  const [errorMessage , setErrorMessage] = useState("");

  const modalCss = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '700px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: 24,
    padding: '25px 39px',
    maxHeight: '90vh',
    overflowY: 'auto',
  };

  // Initial values for Formik
  const initialValues = {
    userName: '',
    password: '',
  };

 
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalCss}>
        <div className="d-flex justify-content-end align-items-end mb-3">
          {/* Close icon */}
          <CrossIcon
            onClick={onClose}
            style={{
              cursor: 'pointer',
            }}
          />
        </div>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <img src={data}  style={{width: '100%', objectFit:'cover' }}/>
          
        </Box>
      </Box>
    </Modal>
  );
};

export default BannerModel;
