import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  Avatar,
  MenuItem,
  Select,
  Popover,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  format,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  addDays,
  addMonths,
} from 'date-fns';

import { ServiceFooter } from '../serviceLandingPage/servicefooter';
import { ServiceNavbar } from '../serviceLandingPage/serviceNarbar';

import CustomizationLandingPageLayout from '../serviceLandingPage/pages/CustomizationLandingPage/components/CustomizationLandingPageLayout';
import './style.css';
import axios from 'axios';
import { TENANT_URL } from '../../../config';
import { Image } from '@mui/icons-material';

const MealDetails = () => {
  const navigate = useNavigate();

  const { slug } = useParams();
  const isValidSlug = /^[a-zA-Z0-9-]+$/.test(slug);
  const [mealPlanDetails, setMealPlanDetails] = useState({});
  const [monthList, setMonthList] = useState([]);
  const [selectedMonthList, setSelectedMonthList] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState(0);
  const [weeklyData, setWeeklyData] = useState([]);
  const [restaurantTiming, setRestaurantTiming] = useState({});

  const [error, setError] = useState('');

  if (!isValidSlug) {
    navigate('/');
  }

  useEffect(() => {
    document.body.classList.add('customPage');
    return () => {
      document.body.classList.remove('customPage');
    };
  }, []);

  const getNext12Months = () => {
    const now = new Date();
    const startDate = new Date(now.getFullYear(), now.getMonth()); // Current month and year
    const months = Array.from({ length: 12 }).map((_, index) => {
      const date = addMonths(startDate, index);
      return format(date, 'MMMM yyyy');
    });
    return months;
  };

  const getCartDetails = async () => {
    let cartDetails = localStorage.getItem('cartID');
    if (!cartDetails) {
      try {
        // Fetch meal plan details
        const config = {
          method: 'post',
          url: `${TENANT_URL}/api/cart/create`,
        };
        const response = await axios(config);
        localStorage.setItem('cartID', response.data.cart_id);
        localStorage.setItem('deliveryMethod', 'home_delivery');
      } catch (error) {
        setError('Failed to load meal plan details.');
      }
    }
  };


  const fetchRestaurantTiming = async () => {
    try {
      const response = await axios.get(`${TENANT_URL}/api/restaurant-timing`);
      setRestaurantTiming(response.data.data[0] || {});
    } catch (error) {
      console.error('Failed to fetch restaurant timing:', error);
    }
  };

  useEffect(() => {
    const months = getNext12Months();
    setMonthList(months);
    setSelectedMonthList(months[0]);
    getCartDetails();
    fetchRestaurantTiming();
  }, [slug]);

  const addToCart = async () => {
    let cartID = localStorage.getItem('cartID');
    if (!cartID) {
      getCartDetails();
    }
    const data = {
      cart_id: localStorage.getItem('cartID'),
      menu_plan_id: mealPlanDetails.id,
      quantity: 1,
    };
    const getConfig = {
      method: 'post',
      url: `${TENANT_URL}/api/cart/add-item`,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await axios({ ...getConfig, data: data });
      console.log('response response', response.data);
      navigate('/cart');
    } catch (error) {
      console.log('asdasdasd');
    }
  };
  // useEffect(() => {
  //   const fetchAndMergeData = async () => {
  //     if (!selectedMonthList) return;

  //     const [monthName, year] = selectedMonthList.split(' ');
  //     const yearNumber = parseInt(year, 10);

  //     // Calculate monthIndex from monthName
  //     const monthIndex = new Date(`${monthName} 1, ${yearNumber}`).getMonth();
  //     const weeks = getMonthDatesWeekwise(monthName, yearNumber);

  //     try {
  //       // Fetch meal plan details
  //       const config = {
  //         method: 'get',
  //         url: `${TENANT_URL}/api/plan/${slug}?month=${monthName}&year=${year}`,
  //       };
  //       const response = await axios(config);
  //       setMealPlanDetails(response.data.data || {});
  //       const { monthly_plan } = response.data.data || {};
  //       if (monthly_plan) {

  //         const mergedWeeks = weeks.map((week) =>
  //           week.map((day) => {
  //             const dayName = new Date(
  //               `${yearNumber}-${monthIndex + 1}-${day.date}`
  //             )
  //               .toLocaleDateString('en-US', { weekday: 'long' })
  //               .toLowerCase();
  
  //             if (!restaurantTiming[dayName]) {
  //               // If the restaurant is closed on this day
  //               return { ...day, is_day_off: true, meal_name: 'Day Off' };
  //             }
  
  //             const dayNumber = day.date.padStart(2, '0');
  //             const planDate = monthly_plan.find((plan) => {
  //               const planDateFormatted = plan.plan_date.split('T')[0]; // Extract only the date part
  //               return planDateFormatted === day.full_date;
  //             });
  //             return planDate
  //               ? {
  //                   ...day,
  //                   is_breakfast: planDate.is_breakfast,
  //                   is_lunch: planDate.is_lunch,
  //                   is_dinner: planDate.is_dinner,
  //                   meal_name: planDate.meal_name,
  //                   description: planDate.meal?.description || '',
  //                   image: planDate.meal?.image_url || '',
  //                   addons_name: planDate.addons_name || [],
  //                   is_day_off: false,
  //                 }
  //               : day;
  //           })
  //         );

  //         setWeeklyData(mergedWeeks);
  //       } else {
  //         setWeeklyData(weeks);
  //       }
  //     } catch (error) {
  //       setError('Failed to load meal plan details.');
  //     }
  //   };

  //   fetchAndMergeData();
  // }, [selectedMonthList,restaurantTiming]);

  useEffect(() => {
    const fetchAndMergeData = async () => {
      if (!selectedMonthList) return;

      const [monthName, year] = selectedMonthList.split(' ');
      const yearNumber = parseInt(year, 10);

      // Calculate monthIndex from monthName
      const monthIndex = new Date(`${monthName} 1, ${yearNumber}`).getMonth();
      const weeks = getMonthDatesWeekwise(monthName, yearNumber);

      try {
        // Fetch meal plan details
        const config = {
          method: 'get',
          url: `${TENANT_URL}/api/plan/${slug}?month=${monthName}&year=${year}`,
        };
        const response = await axios(config);
        setMealPlanDetails(response.data.data || {});
        const { monthly_plan } = response.data.data || {};

        if (monthly_plan) {
          const mergedWeeks = weeks.map((week) =>
            week.map((day) => {
              // If it's already marked as a day off from restaurant timing, keep it as is
              if (day.is_day_off) {
                return day;
              }

              const planDate = monthly_plan.find((plan) => {
                const planDateFormatted = plan.plan_date.split('T')[0];
                return planDateFormatted === day.full_date;
              });

              return planDate
                ? {
                    ...day,
                    is_breakfast: planDate.is_breakfast,
                    is_lunch: planDate.is_lunch,
                    is_dinner: planDate.is_dinner,
                    meal_name: planDate.meal_name,
                    description: planDate.meal?.description || '',
                    image: planDate.meal?.image_url || '',
                    addons_name: planDate.addons_name || [],
                  }
                : day;
            })
          );

          setWeeklyData(mergedWeeks);
        } else {
          setWeeklyData(weeks);
        }
      } catch (error) {
        setError('Failed to load meal plan details.');
      }
    };

    fetchAndMergeData();
  }, [selectedMonthList, restaurantTiming]);

  console.log('setWeeklyData:', weeklyData);

  // const getMonthDatesWeekwise = (monthName, year, weekStartsOn = 0) => {
  //   const monthIndex = new Date(`${monthName} 1, ${year}`).getMonth();
  //   const firstDayOfMonth = startOfMonth(new Date(year, monthIndex));
  //   const lastDayOfMonth = endOfMonth(new Date(year, monthIndex));
  //   const firstWeekStart = startOfWeek(firstDayOfMonth, { weekStartsOn });
  //   const lastWeekEnd = endOfWeek(lastDayOfMonth, { weekStartsOn });

  //   const weeks = [];
  //   let currentWeekStart = firstWeekStart;

  //   while (currentWeekStart <= lastWeekEnd) {
  //     const week = Array.from({ length: 7 }).map((_, dayOffset) => {
  //       const date = addDays(currentWeekStart, dayOffset);

  //       return {
  //         date: format(date, 'dd'), // Day of the month as a two-digit number
  //         full_date: format(date, 'yyyy-MM-dd'), // Day of the month as a two-digit number
  //         is_breakfast: false,
  //         is_lunch: false,
  //         is_dinner: false,
  //         meal_name: '',
  //         description: '',
  //       };
  //     });

  //     weeks.push(week);
  //     currentWeekStart = addDays(currentWeekStart, 7); // Move to the next week
  //   }

  //   return weeks;
  // };

  const getMonthDatesWeekwise = (monthName, year, weekStartsOn = 0) => {
    const monthIndex = new Date(`${monthName} 1, ${year}`).getMonth();
    const firstDayOfMonth = startOfMonth(new Date(year, monthIndex));
    const lastDayOfMonth = endOfMonth(new Date(year, monthIndex));
    const firstWeekStart = startOfWeek(firstDayOfMonth, { weekStartsOn });
    const lastWeekEnd = endOfWeek(lastDayOfMonth, { weekStartsOn });

    const weeks = [];
    let currentWeekStart = firstWeekStart;

    while (currentWeekStart <= lastWeekEnd) {
      const week = Array.from({ length: 7 }).map((_, dayOffset) => {
        const date = addDays(currentWeekStart, dayOffset);
        const dayName = format(date, 'EEEE').toLowerCase(); // Get day name in lowercase

        return {
          date: format(date, 'dd'),
          full_date: format(date, 'yyyy-MM-dd'),
          is_breakfast: false,
          is_lunch: false,
          is_dinner: false,
          meal_name: '',
          description: '',
          is_day_off: !restaurantTiming[dayName], // Set day off based on restaurant timing
          day_name: dayName,
          is_past_date: date < new Date() // Check if date is in the past
        };
      });

      weeks.push(week);
      currentWeekStart = addDays(currentWeekStart, 7);
    }

    return weeks;
  };

  // Popover
  const [anchorEl, setAnchorEl] = React.useState(null);

  // const handlePopoverOpen = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const [popoverState, setPopoverState] = useState({ anchorEl: null, day: null });

// Handle opening the popover dynamically
const handlePopoverOpen = (event, day) => {
  setPopoverState({ anchorEl: event.currentTarget, day });
};

const handlePopoverClose = () => {
  setPopoverState({ anchorEl: null, day: null });
};
const isPopoverOpen = Boolean(popoverState.anchorEl);

  // const handlePopoverClose = () => {
  //   setAnchorEl(null);
  // };

  const open = Boolean(anchorEl);


  console.log("weeklyData:",weeklyData);
  return (
    <>
      <ServiceNavbar />
      <CustomizationLandingPageLayout>
        <div className="checkout-page pd15">
          <Grid
            container
            sx={{
              padding: '2em 5em',
              '@media (max-width:767px)': {
                padding: '0',
              },
            }}
            marginBottom={7}
          >
            <Grid item md={12}>
              <Card
                sx={{
                  padding: 3,
                  borderRadius: 2,
                  backgroundColor: '#F5F5F5',
                  boxShadow: 'none',
                  marginBottom: '20px',
                }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item alignSelf="flex-start" className='mealavatar'>
                    <Avatar
                      src={mealPlanDetails.image_url}
                      alt={mealPlanDetails.name}
                      sx={{
                        width: 80,
                        height: 80,
                        // backgroundColor: '#FFD700',
                      }}
                    />
                  </Grid>
                  <Grid item md={5}>
                    <CardContent sx={{ paddingTop: 0 }}>
                      <Typography variant="h5" component="div" gutterBottom>
                        {mealPlanDetails.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        paragraph
                      >
                        {mealPlanDetails.description || ''}
                      </Typography>
                      {/* <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                      <Typography variant="body2">🔴 Lorem ipsum</Typography>
                      <Typography variant="body2">🔴 Lorem ipsum</Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      paragraph
                    >
                      Lorem ipsum dolor sit amet.
                    </Typography> */}
                      <Select
                        value={selectedMonthList}
                        onChange={(e) => setSelectedMonthList(e.target.value)}
                        size="small"
                        sx={{ marginTop: 1 }}
                      >
                        {monthList.map((element, index) => (
                          <MenuItem key={index} value={element}>
                            {element}
                          </MenuItem>
                        ))}
                      </Select>
                    </CardContent>
                  </Grid>
                  <Grid item sx={{ marginLeft: 'auto' }}>
                    <Button
                      variant="contained"
                      size="large"
                      sx={{
                        borderRadius: '100px',
                        fontWeight: 'bold',
                        backgroundColor: '#F0645B',
                        boxShadow: 'none',
                        height: '50px',
                        minWidth: '300px !important',
                      }}
                      onClick={addToCart}
                    >
                      Add to Cart - ${mealPlanDetails.plan_price}
                    </Button>
                  </Grid>
                </Grid>
              </Card>

              {error && (
                <Typography variant="body2" color="error" marginTop={2}>
                  {error}
                </Typography>
              )}

              <Box marginBottom={2}>
                <Typography
                  variant="h4"
                  fontFamily="Inter !important"
                  fontSize={18}
                  color="#000000"
                  fontWeight="bold"
                >
                  Calendar View of Meal Plan
                </Typography>
              </Box>

              <Grid container>
                <Grid item md={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      flexDirection: 'row',
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        border: '1px solid rgb(238, 238, 238)',
                        flexBasis: '14.285714%',
                        padding: 2,
                        textAlign: 'center',
                      }}
                    >
                      <Typography
                        variant="body2"
                        fontSize={14}
                        color="#000000"
                        fontWeight="bold"
                      >
                        Sun
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        border: '1px solid rgb(238, 238, 238)',
                        flexBasis: '14.285714%',
                        padding: 2,
                        textAlign: 'center',
                      }}
                    >
                      <Typography
                        variant="body2"
                        fontSize={14}
                        color="#000000"
                        fontWeight="bold"
                      >
                        Mon
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        border: '1px solid rgb(238, 238, 238)',
                        flexBasis: '14.285714%',
                        padding: 2,
                        textAlign: 'center',
                      }}
                    >
                      <Typography
                        variant="body2"
                        fontSize={14}
                        color="#000000"
                        fontWeight="bold"
                      >
                        Tue
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        border: '1px solid rgb(238, 238, 238)',
                        flexBasis: '14.285714%',
                        padding: 2,
                        textAlign: 'center',
                      }}
                    >
                      <Typography
                        variant="body2"
                        fontSize={14}
                        color="#000000"
                        fontWeight="bold"
                      >
                        Wed
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        border: '1px solid rgb(238, 238, 238)',
                        flexBasis: '14.285714%',
                        padding: 2,
                        textAlign: 'center',
                      }}
                    >
                      <Typography
                        variant="body2"
                        fontSize={14}
                        color="#000000"
                        fontWeight="bold"
                      >
                        Thu
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        border: '1px solid rgb(238, 238, 238)',
                        flexBasis: '14.285714%',
                        padding: 2,
                        textAlign: 'center',
                      }}
                    >
                      <Typography
                        variant="body2"
                        fontSize={14}
                        color="#000000"
                        fontWeight="bold"
                      >
                        Fri
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        border: '1px solid rgb(238, 238, 238)',
                        flexBasis: '14.285714%',
                        padding: 2,
                        textAlign: 'center',
                      }}
                    >
                      <Typography
                        variant="body2"
                        fontSize={14}
                        color="#000000"
                        fontWeight="bold"
                      >
                        Sat
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                {weeklyData.map((week, rowIndex) => (
          <React.Fragment key={rowIndex}>
            {week.map((day, colIndex) => (
              <Grid item xs={12 / 7} key={colIndex}>
                <Card
                  style={{
                    backgroundColor: '#ffffff',
                    boxShadow: 'none',
                    borderRadius: '0',
                    border: '1px solid #eeeeee',
                    opacity: day.is_past_date ? 0.7 : 1,
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                      >
                        {day.is_day_off ? '' : 
                          `${day.is_breakfast ? 'Breakfast, ' : ''}
                          ${day.is_lunch ? 'Lunch, ' : ''}
                          ${day.is_dinner ? 'Dinner' : ''}`
                        }
                      </Typography>
                      <Typography variant="h6" color="textPrimary">
                        {day.date}
                      </Typography>
                    </Box>

                    {day.is_day_off ? (
                      <Typography
                        variant="body2"
                        style={{
                          backgroundColor: '#f5f5f5',
                          borderRadius: '5px',
                          padding: '7px',
                          textAlign: 'left',
                          marginTop: '10px',
                          border: '1px solid #e0e0e0',
                          color: '#666666'
                        }}
                      >
                        Day Off
                      </Typography>
                    ) : (
                      <Typography
                        variant="body2"
                        color="error"
                        style={{
                          backgroundColor: '#FBDAD8',
                          borderRadius: '5px',
                          padding: '7px',
                          textAlign: 'left',
                          marginTop: '10px',
                          border: '1px solid #F59A95',
                        }}
                        onMouseEnter={(event) => handlePopoverOpen(event, day)}
                        onMouseLeave={handlePopoverClose}
                      >
                        {day.meal_name || '--'}
                      </Typography>
                    )}

                    {/* Popover remains the same but only shows for non-day-off days */}
                    <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: 'none',
                  '& .MuiPaper-elevation': {
                    boxShadow: '0 0 1px 0 rgba(0,0,0,0.01)',
                    borderRadius: '10px',
                    maxWidth: '420px',
                  },
                }}
                open={isPopoverOpen}
                anchorEl={popoverState.anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                {popoverState.day && (
                  <Box sx={{ padding: '15px' }}>
                    <Typography variant="h6">{popoverState.day.meal_name || 'No Meal'}</Typography>
                    <Typography variant="body2">{popoverState.day.description || 'No description available'}</Typography>
                  </Box>
                )}
              </Popover>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </React.Fragment>
        ))}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </CustomizationLandingPageLayout>
      <ServiceFooter />
    </>
  );
};

export default MealDetails;
