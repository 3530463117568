import { TextField } from '@mui/material';
import React from 'react';

function SearchTextFieldNew({
  value,
  onChange,
  newStyle,
  width = '240px',
  type,
}) {
  return (
    <TextField
      placeholder="Search here..."
      type={type}
      size="small"
      value={value}
      onChange={onChange}
      sx={{
        width: width,
        background: 'transparent',
        borderRadius: '30px',
        '& .MuiInputBase-input::placeholder': {
          color: '#3F2F5B',
          opacity: 1,
        },
        '@media (max-width: 873px)': {
          width: '100%',
        },
        ...newStyle,
      }}
      InputProps={{
        sx: {
          background: 'transparent',
          boxSizing: 'border-box',
          height: '40px',

          borderRadius: '30px',
          fontSize: '14px',
          lineHeight: '18.14px',
          '& fieldset': {
            borderRadius: '30px',
          },
          '&:hover': {
            '& fieldset': {
              borderRadius: '30px',
              border: '1px solid #3F2F5B !important',
            },
          },
        },
      }}
      id="outlined-search"
    />
  );
}

export default SearchTextFieldNew;
