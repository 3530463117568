import { Grid } from '@mui/material';
import React from 'react';
import Wallet from './Wallet';

const WalletsComponent = ({walletAmount}) => {
  const walletData = [
    {
      title: 'Wallet Amount',
      amount: walletAmount,
      image: 'walletamount',
    },
  ];

  return (
    <Grid container spacing={'20px'}>
      {walletData.map((data, index) => (
        <Grid key={index} item lg={12} xs={12}>
          <Wallet title={data.title} amount={data.amount} image={data.image} />
        </Grid>
      ))}
    </Grid>
  );
};

export default WalletsComponent;
