import { Box, Typography } from "@mui/material";
import React from "react";
import AppleSvg from "../../assets/icons/apple.svg";
import Gplay from "../../assets/icons/gplay.svg";
import FB3 from "../../assets/icons/FB3.svg";
import Twitter3 from "../../assets/icons/Twitter3.svg";
import In3 from "../../assets/icons/In3.svg";
import YT3 from "../../assets/icons/YT3.svg";
import Insta3 from "../../assets/icons/Insta3.svg";

function Footer5() {
  console.log("footer 5")
  const AStyle = {
    cursor: "pointer",
    color: 'var(--White, #FFF)',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '125%',
    textDecoration: "none",
  };

  return (
    <>
    <Box >
      <Box
        sx={{
          
          width: "100%",
          background: "#13151A",
          display:'flex',
          justifyContent: "center",
          alignItems:'center',
          flexDirection:'column',
          padding:"50px 0px",
          gap:'40px',
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "27px",
          }}
        >
          <a href="/" style={AStyle}>
            How It Works
          </a>
          <a href="/" style={AStyle}>
            Create
          </a>
          <a href="/" style={AStyle}>
            Collaborate
          </a>
          <a href="/" style={AStyle}>
            Digital Signatures
          </a>
          <a href="/" style={AStyle}>
            Organize
          </a>
          <a href="/" style={AStyle}>
            Automation
          </a>
        </Box>

        <Box
            style={{
              width: "70%",
              height: "0.589px",
              flexShrink: 0,
              border: "0.899px solid #404040",
            //   opacity: 0.06,
              background: "#475467",
            }}
          ></Box>

       <Box sx={{display:'flex',justifyContent:'space-between',width:'100%',padding:'0 15% 0 15%'}}>
       <Box
          sx={{
            display: "flex",
            gap: "17px",
            
            
          }}
        >
          <img src={FB3} alt="" srcset="" />
          <img src={Twitter3} alt="" srcset="" />
          <img src={In3} alt="" srcset="" />
          <img src={YT3} alt="" srcset="" />
          <img src={Insta3} alt="" srcset="" />
        </Box>
        <Box
              sx={{
                display: "flex",
                gap: "14px",
              }}
            >
              <Box
                sx={{
                  width: "113.958px",
                  height: "35.327px",
                  background: "var(--Primary-Primary-900, #0B2835)",
                  borderRadius: "6px",
                  display: "flex",
                  justifyContent: "center",
                  cursor:'pointer',
                }}
              >
                <img src={AppleSvg} alt="" srcset="" />
                <ul
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "0",
                    padding: "7px",
                  }}
                >
                  <li
                    style={{
                      color: "var(--Text-White, #FFF)",
                      fontSize: "7.977px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "10.256px",
                      listStyleType: "none",
                    }}
                  >
                    Download on the
                  </li>
                  <li
                    style={{
                      color: "#FFF",
                      fontSize: "13.675px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "13.675px",
                      listStyleType: "none",
                    }}
                  >
                    App Store
                  </li>
                </ul>
              </Box>
              <Box
                sx={{
                  width: "113.958px",
                  height: "35.327px",
                  background: "#FFFFFF",
                  borderRadius: "6px",
                  border: "1px solid #D9DBE9",
                  display: "flex",
                  justifyContent: "center",
                  cursor:'pointer',

                }}
              >
                <img src={Gplay} alt="" srcset="" />
                <ul
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "0",
                    padding: "3px",
                  }}
                >
                  <li
                    style={{
                      color: "#2F2F2F",
                      fontSize: "7.977px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "10.256px",
                      listStyleType: "none",
                    }}
                  >
                    Get it on
                  </li>
                  <li
                    style={{
                      color: "#2F2F2F",
                      fontSize: "13.675px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "13.675px",
                      listStyleType: "none",
                    }}
                  >
                    Google Play
                  </li>
                </ul>
              </Box>
            </Box>

       </Box>
      </Box>
            
            <Box
          sx={{
            width: "100%",
            background: "#171A22",
            display: "flex",
            justifyContent: "space-between",
            padding:"0 15% 0 15% ",
          }}
        >
          <Typography
            sx={{
              color: "var(--White, #FFF)",
              fontSize: "9px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "12.535px",
              paddingTop: "15px",
            }}
          >
            Copyrights © 2023 All Rights Reserved by LOGO
          </Typography>
          <ul style={{ color: "white", display: "flex", gap: "10px" }}>
            <li
              style={{
                color: "var(--White, #FFF)",
                fontSize: "9px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "12.535px",
                borderRight: "1px solid white",
                listStyleType: "none",
                paddingRight: "10px",
              }}
            >
              Data Policy
            </li>
            <li
              style={{
                color: "var(--White, #FFF)",
                fontSize: "9px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "12.535px",
                borderRight: "1px solid white",
                listStyleType: "none",
                paddingRight: "10px",
              }}
            >
              Privacy Policy
            </li>
            <li
              style={{
                listStyleType: "none",
                paddingRight: "10px",
                color: "var(--White, #FFF)",
                fontSize: "9px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "12.535px",
              }}
            >
              Cookie Policy
            </li>
          </ul>
        </Box>
            
      </Box>
    </>
  );
}

export default Footer5;
