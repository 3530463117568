import React from 'react';
import { Box, Typography } from '@mui/material';

const SubscriptionNewPlanData = ({
  planCategoryName,
  type,
  menuPlan,
  planData,
  planItems,
  planPrice,
  addons,
  additionalItems,
  additionalItemsPrice,
}) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          // height: '99px',
          flexDirection: 'column',
          p: '15px',
          background: ' #FFFFFF1A',
          borderRadius: '8px',
          border: 'solid 1px white',
          boxShadow: '0px 4px 4px 0px #00000026',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Outfit',
              fontSize: '18px',
              fontWeight: '500',
              lineHeight: '23px',
              letterSpacing: '0px',
              textAlign: 'left',
              mb: '10px',
            }}
          >
            {type ? type : 'N/A'}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              ml: 'auto',
              gap: '3px',
            }}
          >
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: '500',
                color: '#3F2F5B',
                opacity: 0.6,
              }}
            >
              Total:
            </Typography>
            <Typography
              sx={{
                fontSize: '15px',
                fontWeight: '700',
                color: '#ff6b00',
              }}
            >
              ${((planPrice || 0) + (additionalItemsPrice || 0)).toFixed(2)}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            sx={{ fontSize: '12px', color: '#000000', fontWeight: '600' }}
          >
            Current Plan Details
          </Typography>
          <Typography
            sx={{ fontSize: '12px', color: '#000000', fontWeight: '600' }}
          >
            ${planPrice.toFixed(2)}
          </Typography>
        </Box>
        <Typography sx={{ fontSize: '13px', color: '#52526CCC' }}>
          {planItems ? planItems : 'N/A'}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '5px',
          }}
        >
          <Typography
            sx={{ fontSize: '12px', color: '#000000', fontWeight: '600' }}
          >
            Addon
          </Typography>
          <Typography
            sx={{ fontSize: '12px', color: '#000000', fontWeight: '600' }}
          ></Typography>
        </Box>
        <Typography sx={{ fontSize: '13px', color: '#52526CCC' }}>
          {addons ? addons : 'N/A'}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '5px',
          }}
        >
          <Typography
            sx={{ fontSize: '12px', color: '#000000', fontWeight: '600' }}
          >
            Additional Items
          </Typography>
          <Typography
            sx={{ fontSize: '12px', color: '#000000', fontWeight: '600' }}
          >
            ${additionalItemsPrice.toFixed(2)}
          </Typography>
        </Box>
        <Typography sx={{ fontSize: '13px', color: '#52526CCC' }}>
          {additionalItems ? additionalItems : 'N/A'}
        </Typography>
      </Box>
    </>
  );
};

export default SubscriptionNewPlanData;
