import React, { useEffect, useState } from 'react';
import { Box, ThemeProvider } from '@mui/material';

import { createTheme } from '@mui/material/styles';

import Footer1 from './styles/FooterStyles/Footer1';
import Footer2 from './styles/FooterStyles/Footer2';
import Footer3 from './styles/FooterStyles/Footer3';
import Footer4 from './styles/FooterStyles/Footer4';
import Footer5 from './styles/FooterStyles/Footer5';

import Footer6 from './styles/FooterStyles/Footer6/Footer6';
import Footer7 from './styles/FooterStyles/Footer7/Footer7';
import Footer8 from './styles/FooterStyles/Footer8/Footer8';
import Footer9 from './styles/FooterStyles/Footer9/Footer9';

import { useStateContext } from './api/stateContext';

export const ServiceFooter = () => {
  const { state, dispatch } = useStateContext();
  const theme = createTheme({
    typography: {
      fontFamily: `"${state.activeFont}",sans-serif !important`,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: `"${state.activeFont}",sans-serif !important`,
          },
        },
      },
    },
  });

  useEffect(() => {
    // Add the unique class to the body
    document.body.classList.add('customPage');

    // Remove the class when the component unmounts
    return () => {
      document.body.classList.remove('customPage');
    };
  }, []);
  var selectedNavFooter = state.activeNavFooter;

  const footerComponents = {
    1: <Footer1 />,
    2: <Footer2 />,
    3: <Footer3 />,
    4: <Footer4 />,
    5: <Footer5 />,
    6: <Footer6 />,
    7: <Footer7 />,
    8: <Footer8 />,
    9: <Footer9 />,
  };
  var renderFooter = () => footerComponents[selectedNavFooter] || '';

  console.log('selectedNavFooter:', selectedNavFooter);
  return <>{renderFooter()}</>;
};
